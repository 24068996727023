/* This example requires Tailwind CSS v2.0+ */
import { InformationCircleIcon } from "@heroicons/react/solid";

export default function NotificationBar({ text, icon, color, linkText, showArrow, onClick }) {
    let Icon = icon ? icon : InformationCircleIcon;
    let _color = color ? color : "dania";
    let _linkTekst = linkText ? linkText : "Details";
    let _showArrow = showArrow ?? true;

    function replaceColor(color, classes) {
        return color ? classes.replaceAll("dania", color) : classes;
    }

    return (
        <div className={replaceColor(_color, "rounded-md bg-dania-50 p-4 w-full")}>
            <div className="flex">
                <div className="flex-shrink-0">
                    <Icon className={replaceColor(_color, "h-5 w-5 text-dania-400")} aria-hidden="true" />
                </div>
                <div className="ml-3 flex-1 md:flex md:justify-between">
                    <p className={replaceColor(_color, "text-sm font-medium text-dania-700")}>{text}</p>
                    <p className="mt-3 text-sm md:mt-0 md:ml-6">
                        {!onClick ? null : (
                            <button onClick={() => { if (onClick) onClick() }} className={replaceColor(_color, "whitespace-nowrap cursor-pointer font-medium text-dania-700 hover:text-dania-600")}>
                                {_linkTekst} {_showArrow ? <span aria-hidden="true">&rarr;</span> : null}
                            </button>
                        )}
                    </p>
                </div>
            </div>
        </div>
    );
}