/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { useState } from "react";
import PhoneNumber from "../Input/PhoneNumber";
import InfoHeader from "./InfoHeader";

export default function AccountInfo(props) {
    const [userInfo] = useState(props.userInfo);

    function ChangeHandler(e, propName) {
        userInfo[propName] = e.target.value;
    }

    function TeamNameChangeHandler(e) {
        props.teamInfo.name = e.target.value;
    }

    return (
        <>
            <InfoHeader
                header="Complete your account"
                text="Fill out the form below to complete your account."
            />
            <div className="mt-5 md:mt-0 md:col-span-2">
                <div className="py-5 bg-white">
                    <div className="grid grid-cols-2 gap-6">

                        <TextBox label="First name" fieldName="first-name" autoComplete="given-name" propName="firstName" />

                        <TextBox label="Last name" fieldName="last-name" autoComplete="family-name" propName="lastName" />

                        <ReadonlyTextBox label="Work email address" fieldName="email-address" autoComplete="email" propName="email" />

                        {props.teamInfoReadOnly ?
                            <ReadonlyTextBox label="Company / Organisation" fieldName="organization" autoComplete="organization" value={props.teamInfo.name} />
                            :
                            <TextBox label="Company / Organisation" fieldName="organization" autoComplete="organization" customDefaultValue={props.teamInfo.name} customOnChange={TeamNameChangeHandler} />
                        }

                        <TextBox label="Position / Title" fieldName="organization-title" autoComplete="organization-title" propName="title" />

                        <div className="col-span-2 sm:col-span-1">
                            <label htmlFor="postal-code" className="block text-sm font-medium text-gray-700">
                                Phone number
                            </label>
                            <PhoneNumber defaultValue={userInfo.phone} isRequired="true" onChange={(e) => ChangeHandler(e, "phone")} />
                        </div>

                    </div>
                </div>
            </div>
        </>
    )

    function TextBox(props) {
        return (
            <div className="col-span-2 sm:col-span-1">
                <label htmlFor={props.fieldName} className="block text-sm font-medium text-gray-700">
                    {props.label}
                </label>
                <input
                    required
                    type="text"
                    name={props.fieldName}
                    id={props.fieldName}
                    autoComplete={props.autoComplete}
                    className="mt-1 focus:ring-dania-500 focus:border-dania-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    defaultValue={props.customDefaultValue ?? userInfo[props.propName]}
                    onChange={(e) => { props.customOnChange ? props.customOnChange(e) : ChangeHandler(e, props.propName) }}
                />
            </div>
        );
    }

    function ReadonlyTextBox(props) {
        return (
            <div className="col-span-2 sm:col-span-1">
                <label htmlFor={props.fieldName} className="block text-sm font-medium text-gray-700">
                    {props.label}
                </label>
                <input
                    type="text"
                    name={props.fieldName}
                    id={props.fieldName}
                    autoComplete={props.autoComplete}
                    className="mt-1 focus:ring-dania-500 focus:border-dania-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md bg-gray-200"
                    value={props.value ?? userInfo[props.propName]}
                    readOnly
                />
            </div>
        );
    }

    function OptionalTextBox(props) {

        var describeId = props.fieldName + "-optional"

        return (
            <div className="col-span-2 sm:col-span-1">
                <Optional id={describeId}>
                    <label htmlFor={props.fieldName} className="block text-sm font-medium text-gray-700">
                        {props.label}
                    </label>
                </Optional>
                <input
                    type="text"
                    name={props.fieldName}
                    id={props.fieldName}
                    autoComplete={props.autoComplete}
                    className="mt-1 focus:ring-dania-500 focus:border-dania-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    aria-describedby={describeId}
                    defaultValue={userInfo[props.propName]}
                    onChange={(e) => { ChangeHandler(e, props.propName) }}
                />
            </div>
        );
    }

    function Optional(props) {
        return (
            <div className="flex flex-row justify-between">
                {props.children}
                <div className="text-sm text-gray-400" id={props.id}>
                    Optional
                </div>
            </div>
        );
    }
}