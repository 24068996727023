function prepData(columns, data) {
    var row = [];
    for (const prop of columns) {
        if (prop.Cell == null) {
            row.push(<div key={prop.Accessor} className="text-sm text-gray-500">{data[prop.Accessor]}</div>);
        } else {
            row.push(prop.Cell(data));
        }
    }
    return row;
}

export default function EncantedTable({ columns, data }) {
    
    var isLoading = data.length == 0;
    if (isLoading)
        data = [{}];

    return (
        <div className="flex flex-col ml-1">
            <div className="-my-2 overflow-x-auto -mx-6">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className="bg-gray-100">
                                <tr>
                                    {columns.map((column) => (
                                        <th key={"table-header-" + columns.findIndex((x) => x === column)} scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-900 uppercase tracking-wider">
                                            {column.Header}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {data.map((d) => {
                                    var cells = prepData(columns, d);
                                    return (
                                        <tr key={"table-row-" + data.findIndex((x) => x === d)} className={isLoading ? "animate-pulse" : ""}>
                                            {cells.map((cell) => (
                                                <td key={"table-data-" + cells.findIndex((x) => x === cell)} className="px-6 py-4 whitespace-nowrap">
                                                    <div className="flex items-center">
                                                        {cell}
                                                    </div>
                                                </td>
                                            ))}
                                        </tr>)
                                    })}
                           </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}