/* This example requires Tailwind CSS v2.0+ */

import { useState, useEffect } from "react";
import PageHeader from "../Components/PageHeader";
import ProductList from "../Components/Products/ProductList";
import { GetProducts } from "../ApiClients/ProductsClient";
import { PlayIcon, FolderIcon, MailIcon, GlobeIcon } from "@heroicons/react/solid";
import { MailIcon as MailIconOutline, EyeIcon } from '@heroicons/react/outline'
import ProductListLoading from "../Components/Products/ProductlistLoading";
import { GetNewTrial } from "../ApiClients/AssistClient";
import { CreateNewSignatureTrialLicense } from "../ApiClients/OutlookSignatureCloudClient";
import NotificationBar from "../Components/NotificationBar";

export default function Products(product) {
    const [data, setData] = useState({});
    const [ready, setReady] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        MapProducts().then((response) => {
            if (response !== undefined) {
                setData(response);
            } else {
                setError("Unable to fetch products. Try again later, or try and reload the page.");
            }
            setReady(true);
        }).catch((x) => {
            setError("Unable to fetch products. Try again later, or try and reload the page.");
            setReady(true);
        });

    }, []);

    return (
        <div>
            {!ready ? (
                <div>
                    {/* Show placeholder while loading*/}
                    <PageHeader headLine="Licensed software" />
                    <ProductListLoading />
                </div>
            ) : (
                <div className="mr-5">
                    {/* Show licensed software section */}
                    {data.licensedProducts !== undefined && data.licensedProducts.length > 0 ? (
                        <div className="mb-8">
                            <PageHeader headLine="Licensed software" />
                            <ProductList products={data.licensedProducts}></ProductList>
                        </div>
                    ) : null}

                    {/* Show trial software section */}
                    {data.trialProducts !== undefined && data.trialProducts.length > 0 ? (
                        <div>
                            <PageHeader headLine="Software trials" />
                            <ProductList products={data.trialProducts}></ProductList>
                        </div>
                    ) : null}
                </div>
            )}

            {(error && ready) ? <div>
                <PageHeader headLine="Licensed software" />
                <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4 mr-5">
                    <div className="col-span-2">
                        <NotificationBar color="red" text={error} onClick={() => { window.location.reload(); }} linkText="Reload page" showArrow={false} />
                    </div>
                </div>
            </div> : null}
        </div>
    );
}

export const MapProducts = async () => {

    return GetProducts().then((response) => {
        if (response.error === undefined) {
            var trialProducts = response.filter((x) => x.trialStatus !== 0).map((p) => { return MapTrialProduct(p); });
            return { licensedProducts: GetLicensedProducts(response), trialProducts: trialProducts };
        }
        else
            return undefined;
    })
}

const MapTrialProduct = (product) => {

    let dialog = null;
    let teaserIcon = null;

    switch (product.teaserIcon) {
        case 0:
            teaserIcon = MailIconOutline;
            break;
        case 1:
            teaserIcon = EyeIcon;
            break;
        default:
            break;
    }

    if (product.showPrivacyPolicy) {
        dialog = {
            iconColor: "dania",
            title: "Privacy policy",
            text: `Your privacy is important to us. It is Dania Software A/S' policy to respect your privacy and comply with any applicable law and regulation regarding any personal information we may collect about you, including across our website, <a href="https://www.daniasoftware.com" target="_blank" class="text-dania-500 hover:text-dania-700">https://www.daniasoftware.com</a>, and other sites we own and operate.<br /><br />
                    Information we collect includes both information you knowingly and actively provide us when using or participating in any of our services and promotions, and any information automatically sent by your devices in the course of accessing our products and services.<br /><br />
                    In order to use our services you must accept our <a href="https://www.daniasoftware.com/privacy-policy" target="_blank" class="text-dania-500 hover:text-dania-700">privacy policy</a>.`,
            actionButtonColor: "dania",
            actionButtonText: "Accept",
            show: false
        };
    }

    switch (product.trialStatus) {
        case 1:
            return {
                title: product.name + product.nameExtension,
                subTitle: product.description,
                yellowBadgeText: "Trial available",
                buttonText: "Start trial",
                icon: product.trialStatus === 1 ? PlayIcon : MailIcon,
                navigationUrl: "/products/" + product.name.replace(/\s/g, ""),
                customAction: GetCustomAction(product),
                dialog: dialog,
                teaserName: product.teaserName ? product.teaserName : product.name,
                teaserDescription: product.teaserDescription ? product.teaserDescription : product.description,
                teaserIcon: teaserIcon
            };

        case 2:
            return {
                title: product.name + product.nameExtension,
                subTitle: product.description,
                redBadgeText: product.trialStatus === 2 ? "Trial expired" : "",
                buttonText: "Contact us",
                icon: product.trialStatus === 1 ? PlayIcon : MailIcon,
                navigationUrl: "/help",
            };
        case 3:
            return {
                title: product.name + product.nameExtension,
                subTitle: product.description,
                greenBadgeText: product.trialStatus === 3 ? "Trial started" : "",
                buttonText: "Browse",
                icon: FolderIcon,
                navigationUrl: "/products/" + product.name.replace(/\s/g, ""),
            };

        default: {
        }
    }
};

const GetCustomAction = (product) => {
    console.log("GetCustomAction " + product.name);
    if (product.name === "Assist") {
        return async () => { await GetNewTrial() }
    }
    if (product.name === "SignatureCloud") {
        return async () => { await CreateNewSignatureTrialLicense() }
    }
}

const GetLicensedProducts = (input) => {
    return input
        .filter((x) => x.trialStatus === 0)
        .map((p) => {
            return {
                title: p.name,
                subTitle: p.description,
                blueBadgeText: "Software",
                buttonText: p.externalLink ? "Access" : "Browse",
                icon: p.externalLink ? GlobeIcon : FolderIcon,
                navigationUrl: p.externalLink ? p.externalLink : "/products/" + p.name.replace(/\s/g, "").toLowerCase(),
                externalLink: p.externalLink
            };
        });
};