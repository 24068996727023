/* This component requires Tailwind CSS v2.0+ */

import "./RangeSlider.css";
import { useState } from "react";

export default function RangeSlider({ id, label, value, min, max, disabled, className, rangeClassName, labelClassName, onValueChange }) {

    const [showValueField, setShowValueField] = useState(false);
    const [sliderValue, setSliderValue] = useState(value);

    if (label === undefined || label === "")
        label = "Range";

    if (id === undefined || id === "")
        id = "rangeSlider";

    if (className === undefined || className === "")
        className = "relative pt-1";

    if (rangeClassName === undefined || rangeClassName === "")
        rangeClassName = "appearance-none bg-transparent rangeSliderInput"; // appearance-none accent-gray-500 w-full h-2 rounded-md p-0 bg-dania-200 cursor-grab border-grey-500 focus:outline-none focus:ring-0 focus:shadow-none";

    if (labelClassName === undefined || labelClassName === "")
        labelClassName = "form-label";

    if (min === undefined || min === "")
        min = 0;

    if (max === undefined || max === "")
        max = 100;

    if (disabled === undefined || disabled === "")
        disabled = false;

    function toggleValue() {
        if (onValueChange) {
            setShowValueField(!showValueField);
        }
    }

    function valueChanged(e) {
        setSliderValue(e.target.value);
        if (onValueChange) {
            onValueChange(e.target.value);
        }
    }

    return (
        <div className={className}>
            <div>
                <label htmlFor={id} className={labelClassName}>{label}</label>
                <input
                    type="range"
                    className={rangeClassName}
                    min={min}
                    max={max}
                    id={id}
                    onChange={(e) => valueChanged(e)}
                    value={sliderValue}
                />
            </div>
            <div>
                {/* ---- Width (including edit) start ---- */}
                {showValueField ? null :
                    <div onClick={toggleValue} className="cursor-pointer">
                        <div>{sliderValue}px</div>
                    </div>
                }
                {onValueChange !== undefined && showValueField ?
                    <div className="grid grid-cols-4 gap-1 w-full align-text-bottom">
                        <div className="w-full">
                            <input
                                type="text"
                                name={label + "-name"}
                                id={label + "-id"}
                                className="mt-1 focus:ring-dania-500 focus:border-dania-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                defaultValue={sliderValue}
                                autoFocus
                                onChange={(e) => { valueChanged(e); }}
                                onBlur={toggleValue}
                            />
                        </div>
                        <div className="colspan-3 align-text-bottom pt-2"> px </div>
                    </div>
                    : null
                }
                {/* ---- Width (including edit) end ---- */}

            </div>
        </div>
    );
};