/* This example requires Tailwind CSS v2.0+ */
import { useState } from 'react'
import { Transition } from '@headlessui/react'
import AccountInfoMenu from './AccountInfoMenu';
import TeamInfo from './TeamInfo';
import AccountInfo from './AccountInfo';
import Invite from './Invite';

export default function AccountInfoLayout(props) {
  const [inviteState] = useState({})

  var steps = [
    { name: 'Create account' },
    { name: 'Account information', contentComponent: <AccountInfo userInfo={props.userInfo} teamInfo={props.teamInfo} teamInfoReadOnly={!props.firstUser} /> },
  ]
  if (props.firstUser)
  {
    steps.push({ name: 'Create team', contentComponent: <TeamInfo teamInfo={props.teamInfo} /> });
    steps.push({ name: 'Invite', contentComponent: <Invite inviteState={inviteState} />, hasForm: true });
  }
    
  const [activeComponent, setActiveComponent] = useState(steps[1].contentComponent);
  const [lastNavigation, setLastNavigation] = useState("");
  
  var index = steps.findIndex((x) => x.contentComponent?.type === activeComponent?.type);
  
  function Back() {
      // Move to previous step
      setLastNavigation("Back");
      setActiveComponent(steps[index-1].contentComponent)
  }

  function Next(e) {
    e.preventDefault();

    if (index === steps.length-1)
      // Final step -> save + close
      props.onClose();
    else
    {
      // Move to next step
      setLastNavigation("Next");
      setActiveComponent(steps[index+1].contentComponent)
    }
  }

  function Container() {
    return (
      <Transition appear={true} show={true}
          enter="transform transition duration-500" 
          enterFrom={"opacity-0 " + (lastNavigation === "Back" ? "-translate-x-80" : "translate-x-80")}
          enterTo="opacity-100 translate-x-0" 
          leave="transform transition duration-150" 
          leaveFrom="opacity-100" 
          leaveTo="opacity-0"
      >
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
          <div className="border-b border-gray-200">
            <AccountInfoMenu steps={steps} activeComponent={activeComponent} />
          </div>

          {steps[index].hasForm ? 
            <ContentContainer />
          : null}

          <form onSubmit={(e) => {Next(e)}}>
          
            {!steps[index].hasForm ? 
              <ContentContainer />
            : null }

            <div className="bg-gray-50 px-4 py-3 sm:px-6 flex flex-col-reverse sm:flex-row sm:justify-end">
              {index > 1 ?
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dania-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={Back}
                >
                  Back
                </button>
              : null}
              
              <button
                type="submit"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-dania-600 text-base font-normal text-white hover:bg-dania-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dania-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                {index === steps.length-1 ? "Get started" : "Continue"}
              </button>
            </div>
          </form>
        </div>
      </Transition>
    );
  }

  function ContentContainer() {
    return (
      <div className="bg-white px-4 pt-5 pb-4 sm:px-12 sm:pb-4">
        {activeComponent}
      </div>
    );
  }

  return (
    <Container />
  )
}