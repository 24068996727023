import { useEffect, useState } from "react";

import NotificationBar from "../../../Components/NotificationBar";
import PageHeader from "../../../Components/PageHeader";
import ActionGrid from "../../../Components/Grids/ActionGrid";
import CardWithList from "../../../Components/CardWithList";
import Button from "../../../Components/Buttons/Button";
import DownloadButton from "../../../Components/Buttons/DownloadButton";
import { GetLatestStableInstallFile } from "../../../ApiClients/DynamicTemplateClient";
import authService from "../../../api-authorization/AuthorizeService";

import { SupportIcon, CodeIcon, DownloadIcon, NewspaperIcon, FlagIcon, UserAddIcon } from "@heroicons/react/outline";

import Help from "./Help";
import Documentation from "./Documentation";
import Downloads from "./InstallFiles";
import ReleaseNotes from "./ReleaseNotes";
import Roadmap from "./RoadMap";
import { ProxyUrl } from "../../../Helpers/DaniaProxy"

export default function GetStarted(props) {
    const [steps, setSteps] = useState(undefined);

    useEffect(() => {
        const fetchData = async () => {
            var installFile = await GetLatestStableInstallFile();

            authService.isAccountOwner().then((result) => {

                var localSteps = [];

                if (result) {
                    localSteps.push(CreateRow(
                        "Invite team members",
                        "Invite your colleagues to your team. If you do not have permission to download and install software, remember to invite IT.",
                        <Button icon={UserAddIcon} iconClasses="" text="Invite user" textColor="text-dania-500" bgColor="bg-dania-100" hoverColor="bg-dania-200" onNavigation={() => (window.location.href = "/team?tab=members")} />
                    ));
                }

                localSteps.push(CreateRow("Download", "Download the latest stable version of DynamicTemplate directly.", <DownloadButton downloadUrl={installFile.downloadUrl} fileName={installFile.filename} />))
                localSteps.push(CreateRow("Install", "Run the DynamicTemplate Web installer and input the path to your local DynamicTemplate Web folderset."))
                localSteps.push(<NotificationBar icon={SupportIcon} text={"If you’re having issues, remember to whitelist the following URL: " + ProxyUrl + ". If your issues persist, then please refer to our help section."} />);

                setSteps(localSteps);
            })
        };

        var rowIndex = 0;
        function CreateRow(title, text, button) {
            rowIndex++;
            return (
                <div className="flex flex-row">
                    <div className="grid place-items-center min-h-full">
                        <div className="rounded-md h-10 w-10 bg-dania-50 p-2 text-dania-500 font-medium text-lg mr-5 pt-1 text-center border">{(rowIndex < 10 ? "0" : "") + rowIndex}</div>
                    </div>
                    <div className="flex flex-col flex-1">
                        <div className="text-lg font-medium">{title}</div>
                        <div className="text-sm">{text}</div>
                    </div>
                    <div className="flex items-center">{button}</div>
                </div>
            );
        }

        fetchData();
    }, []);

    const actions = [
        {
            title: "Help",
            text: "Having issues? Visit our help section to get your issues solved.",
            onClick: () => props.onNavigation(<Help />),
            icon: SupportIcon,
            iconForeground: "text-green-700",
            iconBackground: "bg-green-50",
        },
        {
            title: "Documentation",
            text: "Read the documentation for DynamicTemplate Web, to better understand its features.",
            onClick: () => props.onNavigation(<Documentation />),
            icon: CodeIcon,
            iconForeground: "text-purple-700",
            iconBackground: "bg-purple-50",
        },
        {
            title: "Downloads",
            text: "Visit the download section to download older versions of DynamicTemplate Web.",
            linkText: "Software",
            onClick: () => props.onNavigation(<Downloads />),
            icon: DownloadIcon,
            iconForeground: "text-dania-700",
            iconBackground: "bg-dania-50",
        },
        {
            title: "Release notes",
            text: "Read the latest release notes, to see what's new.",
            onClick: () => props.onNavigation(<ReleaseNotes />),
            icon: NewspaperIcon,
            iconForeground: "text-red-700",
            iconBackground: "bg-red-50",
        },
        {
            title: "Roadmap",
            text: "Check out our roadmap to see upcoming features.",
            onClick: () => props.onNavigation(<Roadmap />),
            icon: FlagIcon,
            iconForeground: "text-blue-700",
            iconBackground: "bg-blue-50",
        },
    ];

    return (
        <div>
            <PageHeader headLine="Get started with DynamicTemplate Web" />
            <CardWithList items={steps} headLine="Your quick start guide" />
            <ActionGrid actions={actions} />
        </div>
    );
}
