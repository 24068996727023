export default function ShowCode(props) {
	return (
		<div {...props}>
			<div className="rounded-md bg-gray-700 p-4">
				<div className="flex">
					<div className="flex-shrink-0"></div>
					<div className="ml-3 text-white">{props.children}</div>
					<div className="ml-auto pl-3"></div>
				</div>
			</div>
		</div>
	);
}
