import { XCircleIcon } from "@heroicons/react/outline";
import ReactPlayer from "react-player";
import Overlay from "../Components/Overlays/Overlay";

export default function Player(props) {
    var result = props.url ? (
        <Overlay disableCenterTrick show={true}>
            <div className="h-screen w-screen">
                <div className="grid place-items-center h-screen">
                    <div className="w-3/5">
                        <div className="relative mb-8">
                            <div className="absolute inset-y-0 right-0 h-8 w-8">
                                <div className="cursor-pointer text-dania-700 hover:text-dania-900" onClick={() => props.OnCloseClick()}>
                                    <XCircleIcon className="h-8 w-8 " />
                                </div>
                            </div>
                        </div>

                        <div className="player-wrapper">
                            <ReactPlayer url={props.url} className="react-player" playing width="100%" height="100%" controls={true} />
                        </div>
                    </div>
                </div>
            </div>
        </Overlay>
    ) : null;

    return result;
}