import PageHeader from "../../../Components/PageHeader";
import React from "react";
import NotificationBar from "../../../Components/NotificationBar";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import DownloadList from "../../../Components/Lists/DownloadList";
import { GetTools } from "../../../ApiClients/OutlookSignatureClient";
import { useState, useEffect } from "react";
import HelpNotificationBar from "../../../Components/HelpNotificationBar";
import Help from "./Help";

export default function Tools(props) {
    const [data, setData] = useState([]);
    const [error, setError] = useState();

    useEffect(() => {
        try {
            //Fetch install files from API
            GetTools().then((response) => {
                if (response.error === undefined) {
                    setData(response);
                } else {
                    setError(response.error.message);
                }
            });
        } catch {
            setError("Unable to fetch tools data");
        }
    }, []);

    const mapItem = (files) => {
        return files.map((x) => {
            //Map object
            return {
                name: x.name,
                downloadUrl: x.downloadUrl,
                description: x.description,
                archived: false,
                fileName: x.filename,
            };
        });
    };

    return (
        <div className="w-full">
            {
                <div>
                    <PageHeader headLine="Tools for OutlookSignature"></PageHeader>
                    <div className="pt-1 pb-6">
                        <HelpNotificationBar onNavigation={() => props.onNavigation(<Help />)} />
                    </div>
                    {error ? (
                        <div className="pt-1 pb-6">
                            <NotificationBar color="red" text={error} icon={ExclamationCircleIcon} />
                        </div>
                    ) : (
                        <div>
                            {data.length > 0 ? (
                                data.map((x, index) => {
                                    return <DownloadList key={x.groupName + index} headLine={x.groupName} applications={mapItem(x.files)} showMoreText="View older versions"></DownloadList>;
                                })
                            ) : (
                                <div>
                                    <DownloadList headLine={"Tools "} applications={[]} showMoreText="View older versions"></DownloadList>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            }
        </div>
    );
}