/* This example requires Tailwind CSS v2.0+ */

import { ArrowNarrowRightIcon } from "@heroicons/react/solid";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export default function Submenu(props) {
	const isActive = (menuItem) => {
		if (menuItem.contentComponent.props.anchor !== undefined && props.activeComponent.props.anchor !== undefined) {
			return menuItem.contentComponent.props.anchor === props.activeComponent.props.anchor;
		} else {
			return menuItem.contentComponent.type === props.activeComponent.type;
		}
	};

	return (
		<nav className="space-y-1" aria-label="Sidebar">
			{props.navigation.map((category, i) => (
				<div key={category.name + i}>
					<div className="text-lg leading-6 font-bold text-gray-900 px-3 py-2 mt-3">{category.name}</div>
					{category.items.map((item, i) => (
						<div key={category.name + item.name + i}>
							<button
								key={item.name}
								className={classNames(isActive(item) ? "bg-dania-100  text-dania-500" : "text-gray-600 hover:bg-gray-50 hover:text-gray-900", "group w-full flex items-center px-3 py-2 my-1 text-sm font-medium rounded-md")}
								aria-current={isActive(item) ? "page" : undefined}
								onClick={() => props.onNavigation(item.contentComponent) /* Vis valgte component */}
							>
								<span className="truncate">{item.name}</span>
								{isActive(item) ? <ArrowNarrowRightIcon className="h-5 text-dania-500 ml-auto py-0.5 px-3" aria-hidden="true"></ArrowNarrowRightIcon> : null}
							</button>
						</div>
					))}
				</div>
			))}
		</nav>
	);
}
