/* This example requires Tailwind CSS v2.0+ */
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

//export default function IconCard(props) {
//  return (
//    <div className="col-span-1 flex shadow-sm rounded-md">
//      <div
//        className={classNames(
//          props.item.bgColor,
//          "flex-shrink-0 flex items-center justify-center w-16 text-dania-900 text-sm font-medium rounded-l-md"
//        )}
//      >
//        {props.item.icon}
//      </div>
//      <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
//        <div className="flex-1 px-4 py-2 text-sm truncate">
//          {props.children}
//        </div>
//      </div>
//    </div>
//  );
//}

export default function IconCard(props) {
    return (
        <div className="col-span-1 flex shadow-sm rounded-lg">
            <div
                className={classNames(
                    props.item.bgColor,
                    "flex-shrink-0 flex items-center justify-center w-16 text-dania-900 text-sm font-medium rounded-l-md"
                )}
            >
                {props.item.icon}
            </div>
            <div className="flex-1 flex items-center justify-between bg-white rounded-r-md truncate">
                <div className="flex-1 px-4 py-2 text-sm truncate">
                    {props.children}
                </div>
            </div>
        </div>
    );
}