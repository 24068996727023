import authService from "../api-authorization/AuthorizeService";

export const GetRequest = async (url) => {
    return authService.getAccessToken().then((token) => {
        if (token) {
            return fetch(url, {
                headers: !token
                    ? {}
                    : {
                        Authorization: "Bearer " + token,
                    },
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    }
                    throw new Error("Unable to fetch data");
                })
                .then((resData) => {
                    return resData;
                })
                .catch((x) => {
                    console.log("Api load error", x);
                    return {
                        error: x,
                    };
                });
        } else {
            return authService.signIn().then(x => {
                return GetRequest(url);
            }
            ).catch(x => {
                return null;
            }
            )
        }
    });
};

export const PostJsonRequestCustomHeader = async (url, data, headerValue) => {
    return authService.getAccessToken().then((token) => {
        if (token) {
            return fetch(url, {
                method: "POST",
                mode: "cors",
                body: JSON.stringify(data),
                headers: !token
                    ? {
                        "customHeader": headerValue
                    }
                    : {
                        "customHeader": headerValue,
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + token,
                    },
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    }
                    throw new Error("Unable to fetch data");
                })
                .then((resData) => {
                    return resData;
                })
                .catch((x) => {
                    console.log("Api load error", x);
                    return {
                        error: x,
                    };
                });
        } else {
            return authService.signIn().then(x => {
                return GetJsonRequest(url, data);
            }
            ).catch(x => {
                return null;
            }
            )
        }
    });
};

export const GetJsonRequest = async (url, data) => {
    return authService.getAccessToken().then((token) => {
        if (token) {
            return fetch(url, {
                method: "POST",
                mode: "cors",
                body: JSON.stringify(data),
                headers: !token
                    ? {}
                    : {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + token,
                    },
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    }
                    throw new Error("Unable to fetch data");
                })
                .then((resData) => {
                    return resData;
                })
                .catch((x) => {
                    console.log("Api load error", x);
                    return {
                        error: x,
                    };
                });
        } else {
            return authService.signIn().then(x => {
                return GetJsonRequest(url, data);
            }
            ).catch(x => {
                return null;
            }
            )
        }
    });
};

export const PostRequest = async (url, data) => {
    return authService.getAccessToken().then((token) => {
        if (token) {
            return fetch(url, {
                method: "POST",
                mode: "cors",
                body: JSON.stringify(data),
                headers: !token
                    ? {}
                    : {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + token,
                    },
            })
                .then((response) => {
                    if (response.status > 299) {

                        return response
                            .text()
                            .then((txt) => {
                                return {
                                    error: txt,
                                };
                            })
                            .catch((x) => {
                                return {
                                    error: "Unable to post data",
                                };
                            });
                    }
                    return response;
                })
                .catch((x) => {
                    console.log("Api load error", x);
                    return {
                        error: x,
                    };
                });
        } else {
            return authService.signIn().then(x => {
                return PostRequest(url, data);
            }
            ).catch(x => {
                return null;
            }
            )
        }
    });
};

export const PostFormRequest = async (url, data) => {
    return authService.getAccessToken().then((token) => {
        if (token) {
            return fetch(url, {
                method: "POST",
                mode: "cors",
                body: data,
                headers: !token
                    ? {}
                    : {
                        Authorization: "Bearer " + token,
                    },
            })
                .then((response) => {
                    if (response.status > 299) {

                        return response
                            .text()
                            .then((txt) => {
                                return {
                                    error: txt,
                                };
                            })
                            .catch((x) => {
                                return {
                                    error: "Unable to post data",
                                };
                            });
                    }
                    return response;
                })
                .catch((x) => {
                    console.log("Api load error", x);
                    return {
                        error: x,
                    };
                });
        } else {
            return authService.signIn().then(x => {
                return PostFormRequest(url, data);
            }
            ).catch(x => {
                return null;
            }
            )
        }
    });
};

export const DeleteRequest = async (url) => {
    return authService.getAccessToken().then((token) => {
        if (token) {
            return fetch(url, {
                method: "DELETE",
                mode: "cors",
                headers: !token
                    ? {}
                    : {
                        Authorization: "Bearer " + token,
                    },
            })
                .then((response) => {
                    if (response.status > 299) {

                        return response
                            .text()
                            .then((txt) => {
                                return {
                                    error: txt,
                                };
                            })
                            .catch((x) => {
                                return {
                                    error: "Unable to post data",
                                };
                            });
                    }
                    return response;
                })
                .catch((x) => {
                    console.log("Api load error", x);
                    return {
                        error: x,
                    };
                });
        } else {
            return authService.signIn().then(x => {
                return DeleteRequest(url);
            }
            ).catch(x => {
                return null;
            }
            )
        }
    });
};
