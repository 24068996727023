import React, { useState } from "react";
import { ArrowsExpandIcon } from "@heroicons/react/outline";

export default function ResizableBox(props) {
	const [size, setSize] = useState({ y: props.y ? props.y : 300, x: props.x });

	const handler = (mouseDownEvent) => {
		const startPosition = { y: mouseDownEvent.pageY, x: mouseDownEvent.pageX };
		function onMouseMove(mouseMoveEvent) {
			setSize((currentSize) => ({
				y: props.y ? size.y - startPosition.y + mouseMoveEvent.pageY : undefined,
				x: props.x ? size.x - startPosition.x + mouseMoveEvent.pageX : undefined,
			}));
		}

		function onMouseUp() {
			document.body.removeEventListener("mousemove", onMouseMove);
		}

		document.body.addEventListener("mousemove", onMouseMove);
		document.body.addEventListener("mouseup", onMouseUp, { once: true });
	};

	const ExpandIcon = () => {
		return props.icon ? props.icon : <ArrowsExpandIcon className="m-auto w-8 h-8 text-red-100 hover:text-gray-300"></ArrowsExpandIcon>;
	};

	let style = { height: size.y, width: size.x, backgroundColor: props.bgColor ? props.bgColor : "#011627" };

	return (
		<div data-testid={"ResizeRoot"} className="relative overflow-auto" style={style}>
			{props.children}
			{
				//placeholder to push icon down
			}
			<div data-testid={"Placeholder"} className="h-full"></div>
			<div className="sticky bottom-0  text-right pr-5 z-auto">
				<button alt="Resize the box" title="Resize" onMouseDown={handler}>
					{<ExpandIcon />}
				</button>
			</div>
		</div>
	);
}
