import { useState } from "react";

import { DownloadIcon } from '@heroicons/react/outline'

import FileDownloader from "../../Helpers/FileDownloader";
import BlobDownloader from "../../Helpers/BlobDownloader";
import Button from "./Button";

export default function DownloadButton(props) {
    const [downloading, setDownloading] = useState("");

    const loadingText = "downloading...";

    function DownloadBlob(base64contents, fileName) {
        //Set downloading indicator
        setDownloading(loadingText);

        BlobDownloader(base64contents, fileName).then(() => setDownloading(""));
    }

    function DownloadFile(url, fileName) {
        //Set downloading indicator
        setDownloading(loadingText);

        FileDownloader(url, fileName).then(() => setDownloading(""));
    }

    function ResolveDownloadIconClasses(url) {
        //Add animation if downloading
        return downloading === loadingText ? "animate-bounce delay-100" : "";
    }

    return (
        <Button
            icon={DownloadIcon}
            iconClasses={ResolveDownloadIconClasses(loadingText)}
            text={props.text ? props.text : "Download"}
            textColor="text-white"
            bgColor="bg-dania-600"
            hoverColor="bg-dania-700"
            onNavigation={() => props.downloadUrl ?
                DownloadFile(props.downloadUrl, props.fileName)
                :
                DownloadBlob(props.base64contents, props.fileName)
            }
        />
    );
}