import { useState, useEffect } from "react";
import Card from "../../../Components/Card";
import { VideoCameraIcon } from "@heroicons/react/solid";
import ShowCode from "../../../Components/ShowCode";
import Button from "../../../Components/Buttons/Button";
import MarkDown from "../../../Helpers/MarkDown";
import License from "../Assist/License";
import { GetPrerequisites, GetLicense } from "../../../ApiClients/AssistClient";
import assistinstaller from "../../../Assets/assistinstaller.png";
import { XCircleIcon } from "@heroicons/react/outline";
import Player from "../../../Components/player";
import { ProxyUrl } from "../../../Helpers/DaniaProxy"

export default function HelpInnerView(props) {
	const [data, setData] = useState("");
	const [playerUrl, setPlayerUrl] = useState(undefined);
	const [license, setLicense] = useState({});

	useEffect(() => {
		GetLicense()
			.then((data) => {
				setLicense(data);
			});
		GetPrerequisites()
			.then((response) => {
				setData(response[0].text);
			})
			.catch((e) => console.log(e));
		try {
			if (!props.default) {
				let item = document.querySelector(`[data-anchor="${props.anchor}"]`);
				item.scrollIntoView({ behavior: "smooth" });
			}
		} catch {}
	}, [props.anchor, props.default]);

	const openLinkInTab = (url) => {
		const win = window.open(url, "_blank");
		if (win != null) {
			win.focus();
		}
	};

	const HelpTopic = (e) => {
		return (
			<div {...e} className="p-6 transition-all transform duration-700 ease-in-out">
				<h3 className="font-medium mb-1">{e.headLine}</h3>
				<div className="text-gray-500">{e.children}</div>
			</div>
		);
	};

	const Divider = () => {
		return <div className="border-b mt-2 mb-2"></div>;
	};

	const InstallationContent = () => {
		return (
			<div>
				<HelpTopic data-anchor="InstallLicenseKey" anchor="InstallLicenseKey" headLine="Where do i input my license key?">
					<div>
						Simply copy your key and paste it when the installer prompts you. You can find your key under{" "}
						<button onClick={() => props.onNavigation(<License></License>)} className="text-dania-500 cursor-pointer">
							license
						</button>
						.
					</div>
					<div className="h-96 ">
						<img className="mt-6 mb-3 h-96" alt="Installer" src={assistinstaller}></img>
					</div>
				</HelpTopic>
				<Divider />
				<HelpTopic data-anchor="InstallWhiteList" headLine={"Some users cannot see Assist" + (license.licenseType == 100 ? " Pro" : "") +"?"}>
					If some users are unable to see Assist{(license.licenseType == 100 ? " Pro" : "")} inside Microsoft Office, please verify that every client has access to the following url <span className="text-dania-500"> {ProxyUrl}.</span>
				</HelpTopic>

				<Divider />

				<HelpTopic data-anchor="InstallAutomating" headLine="Automating the installation?">
					<p className="text-gray-500">To install Assist{(license.licenseType == 100 ? " Pro" : "")} on all computers within your organisation simply run the following command as Administrator:</p>
					<ShowCode className="mt-5">
						<span className="text-pink-400">msiexec</span>
						<span className="text-white"> /i</span>
						<span className="text-yellow-500"> "\\Server\Share\AssistSetup.msi"</span>
						<span className="text-white"> key=</span>
						<span className="text-yellow-500">"key"</span>
						<span className="text-green-400"> /quiet</span>
					</ShowCode>
					<p className="text-gray-500 mt-5">To remove Assist{(license.licenseType == 100 ? " Pro" : "")} from all computers within your organisation simply run the following command as Administrator:</p>
					<ShowCode className="mt-5">
						<span className="text-pink-400">msiexec</span>
						<span className="text-white"> /x</span>
						<span className="text-yellow-500"> "\\Server\Share\AssistSetup.msi"</span>
						<span className="text-green-400"> /quiet</span>
					</ShowCode>
				</HelpTopic>
			</div>
		);
	};

	const GetStarted = () => {
		return (
			<HelpTopic data-anchor="GetStartedVideo" headLine="Video guide">
				<p className="text-gray-500 mb-5">If you are having trouble using the software, then please watch our guide by pressing the button below.</p>

				<Button className="mt-5" icon={VideoCameraIcon} text={"Watch video guide"} onNavigation={() => showPlayer("https://player.vimeo.com/video/438889680?h=9ad9f67318&title=0&byline=0&portrait=0&playsinline=0&autoplay=1&autopause=0&app_id=122963")} textColor="text-white" bgColor="bg-dania-600" hoverColor="bg-dania-700" />
			</HelpTopic>
		);
	};

	const Contact = () => {
		return (
			<HelpTopic data-anchor="Contact" anchor="Contact" headLine="Contact information">
				{" "}
				<span className="text-gray-500">
					If you are having issues, feel free to contact us on:
					<ul className="list-disc ml-6 pt-4 pb-5">
						<li className="list-item">
							<a alt="email" className="text-dania-500" href="mailto:contact@daniasoftware.com">
								contact@daniasoftware.com
							</a>
						</li>
						<li>Tel.: +45 58503030</li>
					</ul>
					We do our best to answer support tickets within one business day. Reach us by phone Monday through Thursday from 08:00 - 16:00 and Friday from 08:00 - 15:00, Danish local time.
				</span>
			</HelpTopic>
		);
	};

	const Prerequisites = () => {
		return (
			<HelpTopic headLine="Prerequisites">
				<div className="mt-3 min-h-32">{MarkDown(data)}</div>
			</HelpTopic>
		);
	};

	const showPlayer = (url) => {
		setPlayerUrl(url);
	};

	const Wrapper = ({ children }) => (
		<div className="h-full w-full">
			<span className="cursor-pointer text-dania-700 hover:text-dania-900" onClick={() => setPlayerUrl(undefined)}>
				<XCircleIcon className="h-8 w-8" />
			</span>
			{children}
		</div>
	);

	return (
		<div data-anchor="Prerequisites">
			<div className="w-full m-2">
				<Card headLine="System requirements" content={<Prerequisites />}></Card>
				<Card headLine="Installation" content={<InstallationContent />}></Card>
				<Card headLine="Get started" content={<GetStarted />}></Card>
				<Card id="Contact" headLine="Contact" content={<Contact />}></Card>
			</div>
			{playerUrl ? <Player url={playerUrl} OnCloseClick={() => setPlayerUrl(undefined)} /> : null}
		</div>
	);
}
