import { useState } from "react";

export default function TextBox(props) {
    const GetStyle = () => {
        return props.readOnly
            ? "mt-1 focus:ring-dania-500 focus:border-dania-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md bg-gray-200"
            : "mt-1 focus:ring-dania-500 focus:border-dania-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md";
    };

    const [showLabelField, setShowLabelField] = useState(false);

    function toggleLabel() {
        if (props.onLabelChange !== undefined) {
            setShowLabelField(!showLabelField);
        }
    }

    return (
        <div className={props.mb != null ? "mb-" + props.mb : ""}>
            {!showLabelField ?
                <label htmlFor={props.fieldName} className="block text-sm font-medium text-gray-700" onClick={toggleLabel}>
                    {props.label}
                </label>
                : null}
            {props.onLabelChange !== undefined && showLabelField ?
                <input
                    type={props.type ? props.type : "text"}
                    name={props.label + "-name"}
                    id={props.label + "-id"}
                    className={GetStyle()}
                    placeholder={props.placeholder}
                    defaultValue={props.label ? props.label : ""}
                    autoFocus
                    onChange={(e) => {
                        if (props.onLabelChange) {
                            props.onLabelChange(e);
                        }
                    }}
                    onBlur={toggleLabel}
                />
                : null
            }
            {!props.multiLine ?
                <input
                    readOnly={props.readOnly}
                    required
                    type={props.type ? props.type : "text"}
                    name={props.fieldName}
                    id={props.fieldName}
                    autoComplete={props.autoComplete}
                    className={GetStyle()}
                    placeholder={props.placeholder}
                    defaultValue={props.defaultValue ? props.defaultValue : ""}
                    onChange={(e) => {
                        if (props.onChange) {
                            props.onChange(e);
                        }
                    }}
                /> : <textarea
                    readOnly={props.readOnly}
                    required
                    type={props.type ? props.type : "text"}
                    name={props.fieldName}
                    id={props.fieldName}
                    autoComplete={props.autoComplete}
                    className={GetStyle()}
                    placeholder={props.placeholder}
                    defaultValue={props.defaultValue ? props.defaultValue : ""}
                    onChange={(e) => {
                        if (props.onChange) {
                            props.onChange(e);
                        }
                    }}
                    rows="3"
                ></textarea>}
        </div >
    );
}
