import React, { Fragment } from "react";
import Editor from "react-simple-code-editor";
import Highlight, { defaultProps } from "prism-react-renderer";
import theme from "prism-react-renderer/themes/nightOwl";
import "./styles.css";
import { Droppable } from "react-beautiful-dnd";
import ResizableBox from "../ResizableBox";
import { SwitchVerticalIcon } from "@heroicons/react/outline";

export default function CodeEditor({ signature, setSignature }) {
	const styles = {
		root: {
			boxSizing: "border-box",
			fontFamily: '"Dank Mono", "Fira Code", monospace',
			...theme.plain,
		},
	};

	function onValueChange(code) {
		let markUpClone = { ...signature.markUp };
		markUpClone.html = code;
		setSignature((prevState) => ({ ...prevState, markUp: markUpClone }));
	}

	let highlight = (code) => (
		<Droppable droppableId="ThisDropCode">
			{(provided, snapshot) => (
				<div {...provided.droppableProps} ref={provided.innerRef}>
					<Highlight {...defaultProps} theme={theme} code={signature.markUp.html} language="markup">
						{({ className, style, tokens, getLineProps, getTokenProps }) => (
							<Fragment>
								{tokens.map((line, i) => (
									<div {...getLineProps({ line, key: i })}>
										<span className="editorLineNumber">{i}</span>
										{line.map((token, key) => (
											<span {...getTokenProps({ token, key })} />
										))}
									</div>
								))}
							</Fragment>
						)}
					</Highlight>
					{provided.placeholder}
				</div>
			)}
		</Droppable>
	);

	return (
		<ResizableBox bgColor="#011627" y={400} icon={<SwitchVerticalIcon className="m-auto w-8 h-8 text-red-100 hover:text-gray-300" />}>
			<Editor value={signature.markUp.html} onValueChange={onValueChange} highlight={highlight} padding={10} style={styles.root} className="editor" textareaId="codeArea" />
		</ResizableBox>
	);
}
