import { ApproveUserRequest, GetTeamMembers, InviteUser, RejectUserRequest, PromoteUser, DeleteUserAndSendEmail } from "../../ApiClients/TeamClient";
import { ExclamationIcon } from "@heroicons/react/solid";
import { PlusIcon, CheckCircleIcon, ExclamationCircleIcon, TrashIcon, LightningBoltIcon } from "@heroicons/react/outline";
import { Transition } from "@headlessui/react";
import authService from "../../api-authorization/AuthorizeService";
import Button from "../../Components/Buttons/Button";
import Chip from "../../Components/Chip";
import EnchantedTable from "../../Components/EncantedTable";
import ModalDialog from "../../Components/ModalDialog";
import PageHeader from "../../Components/PageHeader";
import React, { useEffect, useState, Fragment } from "react";

export default function TeamMembers() {
    const [activeEditMenu, setActiveEditMenu] = useState("");
    const [busy, setBusy] = useState(false);
    const [dialog, setDialog] = useState({ show: false });
    const [emailField, setEmailField] = useState("");
    const [isAccountOwner, setIsAccountOwner] = useState(false);
    const [notificationBar, setNotificationBar] = useState({ text: "", color: "", show: false, icon: CheckCircleIcon });
    const [teamMembers, setTeamMembers] = useState([]);

    useEffect(() => {
        reloadData();
    }, []);

    function ShowEditMenu(id) {
        return id == activeEditMenu;
    }

    function updateEditMenu(id) {
        if (id == activeEditMenu) {
            setActiveEditMenu("");
        } else {
            setActiveEditMenu(id);
        }
    }

    function approveUserRequest(id, email) {
        if (!busy) {
            setBusy(true);
            ApproveUserRequest(id).then((x) => {
                notficationUpdate("Success! You have accepted " + email + "'s request to join the team.", "green", CheckCircleIcon)
                reloadData()
            })
            setBusy(false);
        }
    }

    function rejectUserRequest(id, email) {
        if (!busy) {
            setBusy(true);
            RejectUserRequest(id).then((x) => {
                notficationUpdate("Success! You have declined " + email + "'s request to join the team.", "green", CheckCircleIcon)
                reloadData()
                setBusy(false);
            })
        }
    }

    function reloadData() {
        GetTeamMembers().then((data) => {
            setTeamMembers(data);
        });
        authService.isAccountOwner().then((result) => {
            setIsAccountOwner(result);
        });
    }

    function sendInvite() {
        if (!busy) {
            setBusy(true);
            if (validateEmail(emailField)) {
                InviteUser(emailField).then((data) => {
                    notficationUpdate("Success! You have invited a new team member.", "green", CheckCircleIcon)
                    setEmailField("");
                    reloadData();
                    setBusy(false);
                })
            } else {
                notficationUpdate("Invalid email format.", "red", ExclamationCircleIcon)
                setBusy(false);
            }
        }
    }

    function notficationUpdate(text, color, icon) {
        setNotificationBar({ text: text, color: color, show: true, icon: icon });
        new Promise(x => setTimeout(x, 5000)).then(() => setNotificationBar(prevState => ({ ...prevState, show: false })));
    }

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    function getStatusChip(status) {
        switch (status) {
            case 0:
                return <Chip text="Active" color="green" />
                break;
            case 1:
                return <Chip text="Invited" color="yellow" />
                break;
            case 2:
                return <Chip text="Request accepted" color="yellow" />
                break;
            case 3:
                return <Chip text="Request received" color="purple" />
                break;
            case 4:
                return <Chip text="Request rejected" color="red" />
                break;
            case 5:
                return <Chip text="Email not confirmed" color="yellow" />
                break;
            default:
                return <Chip text="Error" color="red" />
                break;
        }
    }

    function replaceColor(color, classes) {
        return color ? classes.replaceAll("dania", color) : classes;
    }

    function executeDeleteUser(id) {
        if (!busy) {
            setBusy(true);
            DeleteUserAndSendEmail(id).then((response) => {
                if (response.ok) {
                    notficationUpdate("Success! You have deleted the selected user.", "green", CheckCircleIcon);
                    reloadData();
                }
                else
                    notficationUpdate("Error! Unable to delete user.", "red", ExclamationIcon);
            });
            setBusy(false);
            setDialog(prevState => ({ ...prevState, show: false }));
        }
    }

    function showDeleteUserDialog(id) {
        setDialog({
            iconColor: "red",
            title: "Delete account",
            text: "Are you sure you want to delete this team member’s account? Their data will be permanently deleted and they will no longer be able to access the site. They will receive an email telling them, that their account has been deleted by an admin.",
            actionButtonAction: () => { executeDeleteUser(id) },
            actionButtonColor: "red",
            actionButtonText: "Delete account",
            show: true
        });
    }

    function showPromoteUserDialog(id, fullname) {
        setDialog({
            iconColor: "yellow",
            title: "Promote to admin",
            text: "Are you sure you want to promote this user to admin? This will transfer your admin role, and you will no longer be able to manage information such as billing. They will receive an email informing them of their new role. <br /><br /> NB: You will be signed out when this process is completed.",
            actionButtonAction: () => { promoteUser(id, fullname) },
            actionButtonColor: "yellow",
            actionButtonText: "Promote to admin",
            show: true
        });
    }

    function promoteUser(id, fullname) {
        setDialog(prevState => ({ ...prevState, show: false }));
        PromoteUser(id).then((data) => {
            notficationUpdate("Success! You have made " + fullname + " an admin.", "green", CheckCircleIcon);
            authService.signOut();
        })
    }

    function closeDialog() {
        setDialog(prevState => ({ ...prevState, show: false }));
    }

    const columns = [
        {
            Header: 'Name',
            Accessor: 'fullname',
            Cell: function (row) {
                return <div className="ml-4">
                    <div className="text-sm font-medium text-gray-900">{row.fullName}</div>
                    <div className="text-sm text-gray-500">{row.email}</div>
                </div>
            }
        },
        {
            Header: 'Status',
            Accessor: 'status',
            Cell: function (row) { return getStatusChip(row.status); }
        },
        {
            Header: 'Role',
            Accessor: 'title',
            Cell: function (row) { return <div className="text-sm text-gray-500">{row.accountOwner ? "Admin" : "Member"}</div> }
        },
        {
            Header: '',
            Accessor: 'editButton',
            Cell: function (row) {
                return <div>{!row.accountOwner && isAccountOwner ? <div>
                    <div className="realative" show>
                        <button className={(ShowEditMenu(row.id) ? "bg-dania-100 " : "") + "hover:bg-dania-100 text-dania-500 flex items-center p-4 rounded-lg"} onClick={() => updateEditMenu(row.id)}>
                            Edit
                        </button>
                        <Transition
                            show={ShowEditMenu(row.id)}
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                        >
                            <div className="absolute text-left rounded-md shadow-xl drop-shadow-xl border border-1 border-gray-200 ml-10 bg-white z-40 pt-2 pb-2">
                                {row.status == 0
                                    ? <div onClick={() => { updateEditMenu(row.id); showPromoteUserDialog(row.id, row.fullName); }}
                                        className="cursor-pointer hover:bg-dania-100 text-sm font-normal text-gray-700 flex items-center pl-6 pr-8 pb-2 pt-2 rounded-lg"
                                    >
                                        <LightningBoltIcon className="block h-5 w-5 rounded-full" />
                                        <div className="ml-2">
                                            <p>Promote to admin</p>
                                        </div>
                                    </div>
                                    : ""
                                }

                                <div onClick={() => { updateEditMenu(row.id); showDeleteUserDialog(row.id); }}
                                    className="cursor-pointer hover:bg-dania-100 text-sm text-gray-700 flex items-center pl-6 pr-8 pb-2 pt-2 rounded-lg"
                                >
                                    <TrashIcon className="block h-5 w-5 rounded-full" />
                                    <div className="ml-2">
                                        <p>Delete account</p>
                                    </div>
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div> : ""}</div>
            }
        }
    ]

    const columnsRequest = [
        {
            Header: 'Name',
            Accessor: 'fullname',
            Cell: function (row) {
                return <div className="ml-4">
                    <div className="text-sm font-medium text-gray-900">{row.fullName}</div>
                    <div className="text-sm text-gray-500">{row.email}</div>
                </div>
            }
        },
        {
            Header: 'Status',
            Accessor: 'status',
            Cell: function (row) { return getStatusChip(row.status); }
        },
        {
            Header: 'Action',
            Accessor: 'editButton',
            Cell: function (row) {
                return <div>
                    {isAccountOwner
                        ? <div className="grid grid-cols-2 gap-6">
                            <Button onNavigation={() => { approveUserRequest(row.id, row.email) }} text="Accept" textColor="text-green-700" bgColor="bg-green-100" hoverColor="bg-green-200" />
                            <Button onNavigation={() => { rejectUserRequest(row.id, row.email) }} text="Decline" textColor="text-red-700" bgColor="bg-red-100" hoverColor="bg-red-200" />
                        </div>
                        : ""
                    }
                </div>
            }
        }
    ]

    return (
        <div className={isAccountOwner ? "relative mb-20" : "relative"}>
            <ModalDialog
                iconColor={dialog.iconColor}
                title={dialog.title}
                text={dialog.text}
                actionButtonAction={dialog.actionButtonAction}
                actionButtonColor={dialog.actionButtonColor}
                actionButtonText={dialog.actionButtonText}
                show={dialog.show}
                close={() => { closeDialog() }}
            />
            <PageHeader headLine="Team members" />
            <div>
                <Transition
                    show={notificationBar.show}
                    as={Fragment}
                    enter="transition ease-out duration-500"
                    enterFrom="transform opacity-0 scale-100"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-500"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-100"
                >
                    <div className={replaceColor(notificationBar.color, "rounded-md bg-dania-50 mb-6 p-4 w-full")}>
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <notificationBar.icon className={replaceColor(notificationBar.color, "h-5 w-5 text-dania-400")} aria-hidden="true" />
                            </div>
                            <div className="ml-3 flex-1 md:flex md:justify-between">
                                <p className={replaceColor(notificationBar.color, "text-sm font-medium text-dania-700")}>{notificationBar.text}</p>
                            </div>
                        </div>
                    </div>
                </Transition>
            </div>
            {isAccountOwner ?
                <div>
                    <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-1 sm:col-span-4 xl:col-span-2 ml-1">
                            <div className="flex rounded-md">
                                <input
                                    type="text"
                                    name="company-website"
                                    id="company-website"
                                    className="mt-1 focus:ring-dania-500 focus:border-dania-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                    placeholder="Type email..."
                                    value={emailField}
                                    onChange={(x) => { setEmailField(x.target.value) }}
                                />
                            </div>
                        </div>
                        <div className="col-span-1 col-span-6">
                            <div className="mt-1 flex rounded-md">
                                <Button onNavigation={() => { sendInvite() }}
                                    icon={PlusIcon}
                                    iconClasses=""
                                    text="Invite new team member"
                                    textColor="text-dania-500"
                                    bgColor="bg-dania-100"
                                    hoverColor="bg-dania-200"
                                />
                            </div>
                        </div>
                    </div>
                    <br />
                </div> : ""}
            <EnchantedTable columns={columns} data={teamMembers.filter(x => x.status <= 2)} />
            {teamMembers.filter(x => x.status == 3).length > 0 ?
                <div>
                    <br />
                    <div className="text-gray-900 text-lg font-semibold">Requests to join</div>
                    <br />
                    <EnchantedTable columns={columnsRequest} data={teamMembers.filter(x => x.status == 3)} />
                </div> : ""}
        </div>
    );
}