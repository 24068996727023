import React from "react";
import { Component } from "react";
import authService from "./AuthorizeService";
import { AuthenticationResultStatus } from "./AuthorizeService";
import { QueryParameterNames, LogoutActions, ApplicationPaths } from "./ApiAuthorizationConstants";
import Logo from "../Assets/dynamictemplatecubes.svg";

// The main responsibility of this component is to handle the user's logout process.
// This is the starting point for the logout process, which is usually initiated when a
// user clicks on the logout button on the LoginMenu component.
export class Logout extends Component {
	constructor(props) {
		super(props);

		this.state = {
			message: undefined,
			isReady: false,
			authenticated: false,
		};
	}

	componentDidMount() {
		const action = this.props.action;
		switch (action) {
			case LogoutActions.Logout:
				if (!!window.history.state.state.local) {
					this.logout(this.getReturnUrl());
				} else {
					// This prevents regular links to <app>/authentication/logout from triggering a logout
					this.setState({
						isReady: true,
						message: "The logout was not initiated from within the page.",
					});
				}
				break;
			case LogoutActions.LogoutCallback:
				this.processLogoutCallback();
				break;
			case LogoutActions.LoggedOut:
				this.setState({
					isReady: true,
					message: "You successfully logged out!",
				});
				break;
			default:
				throw new Error(`Invalid action '${action}'`);
		}

		this.populateAuthenticationState();
	}

	render() {
		const { isReady, message } = this.state;
		if (!isReady) {
			return <div></div>;
		}
		if (!!message) {
			return this.createReLoginPageContent();
			
		} else {
			const action = this.props.action;
			switch (action) {
				case LogoutActions.Logout:
					return this.createLogoutPageContent();
				case LogoutActions.LogoutCallback:
					return this.createLogoutPageContent();
				case LogoutActions.LoggedOut:
					return this.createReLoginPageContent();
				default:
					throw new Error(`Invalid action '${action}'`);
			}
		}
	}

	async logout(returnUrl) {
		const state = { returnUrl };
		const isAuthenticated = await authService.isAuthenticated();
		if (isAuthenticated) {
			const result = await authService.signOut(state);
			switch (result.status) {
				case AuthenticationResultStatus.Redirect:
					break;
				case AuthenticationResultStatus.Success:
					await this.navigateToReturnUrl(returnUrl);
					break;
				case AuthenticationResultStatus.Fail:
					this.setState({ message: result.message });
					break;
				default:
					throw new Error("Invalid authentication result status.");
			}
		} else {
			this.setState({ message: "You successfully logged out!" });
		}
	}

	async processLogoutCallback() {
		const url = window.location.href;
		const result = await authService.completeSignOut(url);
		switch (result.status) {
			case AuthenticationResultStatus.Redirect:
				// There should not be any redirects as the only time completeAuthentication finishes
				// is when we are doing a redirect sign in flow.
				throw new Error("Should not redirect.");
			case AuthenticationResultStatus.Success:
				await this.navigateToReturnUrl(this.getReturnUrl(result.state));
				break;
			case AuthenticationResultStatus.Fail:
				this.setState({ message: result.message });
				break;
			default:
				throw new Error("Invalid authentication result status.");
		}
	}

	async populateAuthenticationState() {
		const authenticated = await authService.isAuthenticated();
		this.setState({ isReady: true, authenticated });
	}

	getReturnUrl(state) {
		const params = new URLSearchParams(window.location.search);
		const fromQuery = params.get(QueryParameterNames.ReturnUrl);
		if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
			// This is an extra check to prevent open redirects.
			throw new Error("Invalid return url. The return url needs to have the same origin as the current page.");
		}
		return (state && state.returnUrl) || fromQuery || `${window.location.origin}${ApplicationPaths.LoggedOut}`;
	}

	navigateToReturnUrl(returnUrl) {
		return window.location.replace(returnUrl);
	}

	createLogoutPageContent() {
		return (
			<div className="h-screen w-screen flex items-center justify-center">
				<div className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200 animate-pulse">
					<div className="flex-1 flex flex-col p-8">
						<img className="w-24 h-24 flex-shrink-0 mx-auto" src={Logo} alt="Logo" />
						<h3 className="mt-6 text-gray-900 text-sm font-medium">Goodbye</h3>
						<dl className="mt-1 flex-grow flex flex-col justify-between">
							<dt className="sr-only">Description</dt>
							<dd className="text-gray-500 text-sm">Logging out...</dd>
						</dl>
					</div>
				</div>
			</div>
		);
	}

	createReLoginPageContent() {
		return (
			<div className="h-screen w-screen flex items-center justify-center">
				<div className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
					<div className="flex-1 flex flex-col p-8">
						<img className="w-24 h-24 flex-shrink-0 mx-auto" src={Logo} alt="Logo" />
						<h3 className="mt-6 text-gray-900 text-sm font-medium">Goodbye</h3>
						<dl className="mt-1 flex-grow flex flex-col justify-between">
							<dt className="sr-only">Description</dt>
							<dd className="text-gray-500 text-sm">
								<a href="/login" className="text-dania-500"> Go to login</a>
							</dd>

						</dl>
					</div>
				</div>
			</div>
		);
	}
}
