import { InformationCircleIcon, LinkIcon } from "@heroicons/react/outline";
import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import {
    AddConsent, DeleteConsent, GetClientId,
    GetConsents
} from "../../../ApiClients/DynamicTemplateClient";
import TextBox from "../../../Components/Input/TextBox";
import Modal from "../../../Components/ModalDialog";
import NotificationBarWithTransition from "../../../Components/NotificationBarWithTransition";
import PageHeader from "../../../Components/PageHeader";
import ConsentGrantedList from "./ConsentGrantedList";
import CheckBox from "../../../Components/Input/CheckBox";

export default function ActiveDirectory() {
    const [notificationBar, setNotificationBar] = useState({
        text: "",
        color: "",
        show: false,
        icon: CheckCircleIcon,
    });
    const [consentGrantedList, setConsentGrantedList] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showInputModal, setShowInputModal] = useState(false);
    const [showPermissionModal, setShowPermissionModal] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState({ showDialog: false, id: null });
    const [newDirectoryName, setNewDirectoryName] = useState("");
    const [permissionsAccessAllUsers, setPermissionsAccessAllUsers] = useState(true);

    var newStateId = Math.floor(Math.random() * 10000) + 1;

    useEffect(() => {
        async function loadSettings() {
            const params = new URLSearchParams(window.location.search);
            var state = params.get("state");
            let isStateValid = false;
            if (state != null)
                isStateValid = validState(encodeURI(state).split(",")[0]);
            await loadConsentsList();
            if (isStateValid) {
                if (params.has("admin_consent")) {
                    var status = params.get("admin_consent");

                    if (status.toLowerCase() === "true") {
                        setShowInputModal(true);
                    }
                }

                if (params.has("error")) {
                    setNotificationBar({
                        text: "Unable to connect to Active Directory",
                        color: "red",
                        show: true,
                        icon: ExclamationCircleIcon,
                    });
                    new Promise((x) => setTimeout(x, 5000)).then(() =>
                        setNotificationBar((prevState) => ({ ...prevState, show: false }))
                    );
                    clearState();
                }
            }
            setLoading(false);
        }

        loadSettings();
    }, []);

    const loadConsentsList = async () => {
        var result = await GetConsents();

        if (result && !result.error) {
            setConsentGrantedList(result);
        }
    };

    const existingConsentList = async (tenant) => {
        try {
            var result = await GetConsents();
            return result.find((object) => object.tenantId === tenant);
        } catch (err) {
            console.error(err);
            return false;
        }
    };

    const getParamValue = (name) => {
        var params = new URLSearchParams(window.location.search);

        return params.get(name);
    };

    const inputModalAction = async () => {

        var tenant = getParamValue("tenant");
        var state = getParamValue("state");
        var clientId = encodeURI(state).split(",")[1];

        AddConsent(newDirectoryName, tenant, clientId)
            .then((x) => {
                setShowInputModal(false);
                clearState();
                notificationUpdate("Consent granted", "green");
            })
            .then((x) => loadConsentsList())
            .catch((error) => clearState());
    };

    function validState(stateId) {
        return stateId === sessionStorage.getItem("consentState");
    }
    function clearState() {
        sessionStorage.removeItem("consentState");
    }

    function setState(stateId) {
        sessionStorage.setItem("consentState", stateId);
    }

    function notificationUpdate(text, color) {
        setNotificationBar({
            text: text,
            color: color,
            show: true,
            icon: CheckCircleIcon,
        });
        new Promise((x) => setTimeout(x, 5000)).then(() =>
            setNotificationBar((prevState) => ({ ...prevState, show: false }))
        );
    }

    function linkAdClicked() {
        GetClientId().then((x) => {
            let cId = !permissionsAccessAllUsers ? x.clientId : x.allUsersClientId
            setState(newStateId);
            var returnUrl = `${window.location.origin.toLowerCase()}${window.location.pathname.toLowerCase()}?tab=Settings`;
            var uri = `https://login.microsoftonline.com/common/adminconsent?state=${newStateId},${cId}&client_id=${cId}&redirect_uri=${returnUrl}`;
            window.location.href = uri;
        });
    }

    function LoadingPlaceholder() {
        return (
            <div className="mb-5 mt-5">
                <div className="border-dashed animate-pulse border-gray-400 border-2 text-center py-10 px-5 rounded-lg ">
                    <div className=" flex space-x-4">
                        <div className="flex-1 space-y-6 py-1">
                            <div className="h-2 bg-gray-500 rounded"></div>
                            <div className="space-y-3">
                                <div className="grid grid-cols-3 gap-4">
                                    <div className="h-2 bg-gray-500 rounded col-span-2"></div>
                                    <div className="h-2 bg-gray-500 rounded col-span-1"></div>
                                </div>
                                <div className="h-2 bg-gray-500 rounded"></div>
                            </div>
                        </div>
                    </div>
                    <button
                        type="button"
                        className="inline-flex mt-4 items-center px-4 py-2 border border-transparent text-sm rounded-md shadow-sm text-gray-700 bg-gray-100 font-medium"
                        onClick={() => {
                            setShowPermissionModal(true);
                        }}
                    >
                        <div className="h-2 bg-gray-500 rounded w-16"></div>
                    </button>
                </div>
            </div>
        );
    }

    function ConsentBox() {
        return (
            <div className="mb-5 mt-5">
                <div className="border-dashed border-gray-400 border-2 text-center py-10 px-5 rounded-lg ">
                    <div className="rounded bg-dania-100 text-dania-700 w-10 h-10 text-center align-middle m-auto">
                        <LinkIcon className="m-auto text-dania-500 w-8 pt-1" />
                    </div>
                    <div className="mb-5 mt-5">
                        <div className="text-gray-900 text-lg font-semibold">
                            Connect Azure Active Directory
                        </div>
                    </div>
                    <div className="text-gray-500">
                        <span className="text-base">
                            You need to grant consent in one or more tenants, before you can
                            use Azure Active Directory in DynamicTemplate.
                        </span>
                    </div>
                    <button
                        type="button"
                        className="inline-flex mt-4 items-center px-4 py-2 border border-transparent text-sm rounded-md shadow-sm text-dania-500 bg-dania-100 font-medium "
                        onClick={() => {
                            setShowPermissionModal(true);
                        }}
                    >
                        Grant consent
                    </button>
                </div>
            </div>
        );
    }


    function deleteDialogHandler(id) {
        setConfirmDelete({ showDialog: true, id: id })
    }


    function deleteAction(id) {
        return DeleteConsent(id)
            .then((x) => notificationUpdate("Consent removed", "green"))
            .then((x) => loadConsentsList())
            .then(x => setConfirmDelete({ showDialog: false }))
            .catch((x) => notificationUpdate("Unable to delete consent", "red"));
    }


    return (
        <div>
            <PageHeader headLine="Azure Active Directory"></PageHeader>
            <NotificationBarWithTransition
                show={notificationBar.show}
                color={notificationBar.color}
                text={notificationBar.text}
                Icon={notificationBar.icon}
            />

            <div className="pb-3 mb-5">
                <div className="text-gray-900 text-lg font-semibold">
                    Connect your Azure Active Directory
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 border-b border-gray-200">
                {loading ? <LoadingPlaceholder /> : <ConsentBox />}
            </div>
            <ConsentGrantedList
                grantedTenants={consentGrantedList}
                consentAction={() => setShowPermissionModal(true)}
                deleteAction={(id) => deleteDialogHandler(id)}
            />
            <Modal
                icon={InformationCircleIcon}
                title="Azure Active Directory Name"
                iconColor={"green"}
                show={showInputModal}
                close={() => {
                    setShowInputModal(false);
                }}
                actionButtonAction={inputModalAction}
                text={
                    "Enter a display name for your Azure Active Directory. Fx Company.org"
                }
            >
                <div className="ml-20 mr-10 mb-5">
                    <TextBox
                        placeholder={"Enter a display name"}
                        onChange={(e) => setNewDirectoryName(e.target.value)}
                    />
                </div>
            </Modal>

            <Modal
                show={confirmDelete.showDialog}
                title="Confirm remove" iconColor="red" actionButtonColor="red" actionButtonText="Remove" text={"Note: This action only removes the item from the list in the Dania Software Apps Portal. The action has no effect on your underlying Azure Active Directory"}
                close={() => { setConfirmDelete({ showDialog: false }) }}
                actionButtonAction={() => deleteAction(confirmDelete.id)}
            />

            <Modal
                icon={LinkIcon}
                title="Grant Consent"
                iconColor={"dania"}
                show={showPermissionModal}
                close={() => {
                    setShowPermissionModal(false);
                }}
                actionButtonAction={linkAdClicked}
                maxWidth="max-w-xl">
                <div className="bg-white pr-6 pl-20 pb-2">
                    <CheckBox checked={true} label="Allow DynamicTemplate to read the current user's profile" onChange={(e) => { }} isDisabled={true} />
                    <CheckBox id="allUsersCheckBox" checked={permissionsAccessAllUsers} label="Allow DynamicTemplate to read all users' profiles" onChange={(e) => { setPermissionsAccessAllUsers(e) }} />
                </div>

            </Modal>

        </div>
    );
}
