import { GetAssets } from "../../../ApiClients/OutlookSignatureClient";
import DownloadList from "../../../Components/Lists/DownloadList";
import PageHeader from "../../../Components/PageHeader";
import React from "react";
import NotificationBar from "../../../Components/NotificationBar";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { useState, useEffect } from "react";
import HelpNotificationBar from "../../../Components/HelpNotificationBar";
import Help from "./Help";

export default function Assets(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    try {
      const fileBadge = (x) => {
        if (x === 0) {
          return { color: "green", text: "Latest" };
        } else {
          return { color: "red", text: "Archived" };
        }
      };

      //Fetch install files from API
      GetAssets().then((response) => {
        if (response.error === undefined) {
          var mappedViewItems = response.map((x, index) => {
            //Resolve badge color and text
            var badge = fileBadge(index);

            //Map object
            return {
              name: x.name,
              releaseDate: x.date,
              badgeText: badge.text,
              badgeColor: badge.color,
              downloadUrl: x.downloadUrl,
              description: x.description,
              archived: index !== 0,
              fileName: x.filename,
            };
          });

          setData(mappedViewItems);
          setLoading(false);
        } else {
          setError(response.error.message);
        }
      });
    } catch {
      setError("Unable to fetch assets data");
    }
  }, []);

  const AssetList = () => {
    if (loading === false && data.length === 0) {
      return <NotificationBar color="yellow" text="There are no assets available yet."></NotificationBar>;
    } else {
        return <DownloadList applications={data} pageSize={5} headLine="Assets" showMoreText="View older versions"/>;
    }
  };

  return (
    <div className="w-full">
      <PageHeader headLine="Assets for OutlookSignature"></PageHeader>
      <div className="pt-1 pb-6">
        <HelpNotificationBar onNavigation={() => props.onNavigation(<Help />)} />
      </div>
      {error ? (
        <div className="pt-1 pb-6">
          <NotificationBar color="red" text={error} icon={ExclamationCircleIcon} />
        </div>
      ) : (
        <AssetList />
      )}
    </div>
  );
}
