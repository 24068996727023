import { GetRequest, PostRequest } from './ApiClientHelper'

export const GetConfig = async () => {
	return GetRequest("/api/Assist/Config/");
};
export const GetReleaseNotes = async () => {
	return GetRequest("/api/Assist/ReleaseNotes/");
};
export const GetInstallFiles = async () => {
	return GetRequest("/api/Assist/InstallFiles/");
};
export const GetLatestStableInstallFile = async () => {
	return GetRequest("/api/Assist/InstallFiles/LatestStable");
};
export const GetDocumentation = async () => {
	return GetRequest("/api/Assist/Documentation/");
};
export const SetConfig = async (config) => {
	return PostRequest("/api/Assist/Config/", config);
};
export const GetPrerequisites = async () => {
	return GetRequest("/api/Assist/Prerequisites/");
};
export const GetLicense = async () => {
	return GetRequest("/api/Assist/GetCurrentLicense/");
};
export const GetRoadMap = async () => {
	return GetRequest("/api/Assist/RoadMap/");
};
export const GetNewTrial = async () => {
	return GetRequest("/api/Assist/NewTrialLicense/");
};
export const ExportLicense = async () => {
	return GetRequest("/api/Assist/ExportLicense/");
};