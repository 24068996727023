import { Link } from "react-router-dom";
import Fag from "../Components/Faq";
import PageHeader from "../Components/PageHeader";
import MessageForm from "../Components/MessageForm";

export default function Help() {
    const faqs = [
        {
            question: "What is the difference between a team and an account?",
            answer:
                "Accounts are personal, and linked to individual people. Accounts must be a part of a team. A team is created when the first person from an organisation signs up for an account. This person becomes the administrator of the team. From that point on, any person from the organisation that signs up for an account, must be accepted into the team by the admin, before being able to access the platform.",
        },
        {
            question: "My trial has expired, is there any way to renew it?",
            answer: (
                <div>
                    If your trial period has expired, and you wish to have it extended, you can
                    <a className="text-dania-500" href="mailto:contact@daniasoftware.com">
                        {" "}
                        contact sales{" "}
                    </a>
                    to request an extension.
                </div>
            ),
        },
        {
            question: "The admin of our team no longer works for us, how do we transfer the admin rights?",
            answer: "If the admin of your team has left your company, please contact support by filling in the form above, to have the admin rights transferred to a different member.",
        },
        {
            question: "How do I invite members to my team?",
            answer: (
                <div>
                    {" "}
                    Administrators can invite team members and accept/decline any incoming requests directly from the{" "}
                    <Link className="text-dania-500" to={"team?tab=members"}>
                        Teams section
                    </Link>{" "}
                    on the platform.{" "}
                </div>
            ),
        },
    ];

    const messageFormSettings = {
        headline: "How can we help?",
        description: "Need help? You’ve come to the right place. Describe your problem, and we’ll be sure to help you as soon as possible. Should you prefer to contact us directly, please dial +45 5850 3030.",
        messageBoxLabel: "Describe the issue you are facing",
    };

    return (
        <div>
            <PageHeader headLine="Help"></PageHeader>
            <div className="mr-5">
                <MessageForm headLine={messageFormSettings.headline} description={messageFormSettings.description} messageBoxLabel={messageFormSettings.messageBoxLabel} />
                <div className="mt-5">
                    <label className="text-lg font-semibold text-gray-900">Frequently asked questions</label>
                    <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6 mt-5">
                        <Fag faqs={faqs}></Fag>
                    </div>
                </div>
            </div>
        </div>
    );
}
