export default function DateFormat(dateString, format = "") {
    try {
        var currentDate = new Date(Date.parse(dateString));

        var date = currentDate.getDate();
        var month = currentDate.getMonth();
        var year = currentDate.getFullYear();

        var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        var result = "";

        if (format === "") {
            result = date + ". " + monthNames[month] + ", " + year;
        } else {
            result = format.replace("dd", date).replace("mm", monthNames[month]).replace("yyyy", year);
        }

        return result;
    } catch {
        return "Unknown date";
    }
}
