import SubmenuLayout from "../../../Components/SubmenuLayout";
import InstallFiles from "./InstallFiles";
import Settings from "./Settings";
import ReleaseNotes from "./ReleaseNotes";
import Help from "./Help";
import Documentation from "./Documentation";
import License from "./License";
import RoadMap from "./RoadMap";
import GetStarted from "./GetStarted";
import { GetLicense } from "../../../ApiClients/AssistClient";

import { useState, useEffect } from "react";

export default function AssistMain() {
    const [activeComponent, setActiveComponent] = useState(<GetStarted onNavigation={(e) => onNavigation(e)} />);
    const [license, setLicense] = useState({});

    useEffect(() => {
        GetLicense()
            .then((data) => {
                setLicense(data);
            });
    }, []);

    const navigation = [
        {
            name: "Welcome",
            items: [{ name: "Get started", contentComponent: <GetStarted onNavigation={(e) => onNavigation(e)} /> }],
        },
        {
            name: "Downloads",
            items: [{ name: "Software", contentComponent: <InstallFiles onNavigation={(e) => onNavigation(e)} /> }],
        },
        {
            name: "Settings",
            items: [{ name: "Configuration", contentComponent: <Settings onNavigation={(e) => onNavigation(e)} /> }],
        },
        {
            name: "Information",
            items: [
                { name: "License", contentComponent: <License onNavigation={(e) => onNavigation(e)} /> },
                { name: "Documentation", contentComponent: <Documentation onNavigation={(e) => onNavigation(e)} /> },
                { name: "Release notes", contentComponent: <ReleaseNotes onNavigation={(e) => onNavigation(e)} /> },
                { name: "Roadmap", contentComponent: <RoadMap onNavigation={(e) => onNavigation(e)} /> },
                { name: "Help", contentComponent: <Help onNavigation={(e) => onNavigation(e)} /> },
            ],
        },
    ];

    function onNavigation(activeComponent) {
        setActiveComponent(activeComponent);
    }

    return <SubmenuLayout navigation={navigation} contentComponent={activeComponent} product={"Assist" + (license.licenseType == 100 ? " Pro" : "")} backUrl="/products" onNavigation={(e) => onNavigation(e)} />;
}
