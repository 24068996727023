import { useEffect, useState } from "react";
import PageHeader from "../../Components/PageHeader";
import CountryDropdown from "../../Components/Input/CountryDropdown";
import AlertBar from "../../Components/AlertBar";
import { GetTeamInfo, SetTeamInfo } from '../../ApiClients/TeamClient'
import authService from "../../api-authorization/AuthorizeService";

export default function TeamInformation() {
    const [isAccountOwner, setIsAccountOwner] = useState(false);
    const [teamData, setTeamData] = useState({});
    const [alert, setAlert] = useState({
        visible: false,
        text: "",
        type: "error"
    });

    useEffect(() => {
        authService.isAccountOwner().then((result) => {
            setIsAccountOwner(result);
            setAlert({ visible: !result, text: "Only the admin of the team can update information.", type: "dania" });
        });

        GetTeamInfo().then((data) => {
            setTeamData(data);

            // Apply default-value in country selection dropdown:
            if (isAccountOwner && data.country != "") document.getElementById("country").value = data.country;
        });



    }, []);

    function ChangeHandler(e, propName) {
        teamData[propName] = e.target.value;
    }

    function OptionalTextbox(props) {
        return (
            <div className="col-span-1 sm:col-span-1">
                <Optional>
                    <label htmlFor={props.fieldname} className="block text-sm font-medium text-gray-700">
                        {props.label}
                    </label>
                </Optional>
                <input
                    type="text"
                    name={props.fieldname}
                    id={props.fieldname}
                    autoComplete={props.fieldname}
                    className={props.readOnly ? "mt-1 focus:ring-dania-500 focus:border-dania-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md bg-gray-200" : "mt-1 focus:ring-dania-500 focus:border-dania-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"}
                    defaultValue={teamData[props.propName]}
                    onChange={(e) => { ChangeHandler(e, props.propName) }}
                    readOnly={props.readOnly}
                />
            </div>
        );
    }

    function InputBoxReadOnly(props) {
        return (
            <div className="relative flex-auto">
                <div className="col-span-1 sm:col-span-1">
                    <label htmlFor="team-name" className="block text-sm font-medium text-gray-700">
                        {props.label}
                    </label>
                    <input value={props.value} readOnly type="text" name={props.fieldname} id={props.fieldname} className="mt-1 focus:ring-dania-500 focus:border-dania-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md bg-gray-200" />
                </div>
            </div>
        );
    }

    function Optional(props) {
        return (
            <div className="flex flex-row justify-between">
                {props.children}
                <div className="text-sm text-gray-400">
                    Optional
                </div>
            </div>
        );
    }

    function InputValue(id) {
        return document.getElementById(id).value;
    }

    function HandleSubmit(e) {
        e.preventDefault();

        if (isAccountOwner) {
            var infoData = {
                name: InputValue("team-name"),
                address: InputValue("street-address"),
                postalCode: InputValue("postal-code"),
                city: InputValue("city"),
                country: InputValue("country"),
            };

            SetTeamInfo(infoData).then((data) => {
                if (data.ok) {
                    setAlert({ visible: true, text: "Success! The information has been saved.", type: "success" });
                } else {
                    setAlert({ visible: true, text: "Error! Could not save the information.", type: "error" });
                }
            });

        } else {
            console.log("Access denied.");
        }
    }

    const SaveButton = () => (
        <div>
            <button type="submit" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-dania-600 text-base font-normal text-white hover:bg-dania-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dania-500 mt-5 sm:w-auto sm:text-sm">
                Save
            </button>
        </div>
    );

    const SaveButtonDisabled = () => (
        <div>
            <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-dania-50 text-base font-normal text-dania-400 focus:outline-none mt-5 sm:w-auto sm:text-sm cursor-not-allowed">
                Save
            </button>
        </div>
    );

    const CountrySelection = () => (
        <div className="col-span-1 sm:col-span-1">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                Country
            </label>
            <CountryDropdown id="country" name="country" autoComplete="country-name" defaultValue={teamData.country} onChange={(e) => { ChangeHandler(e, "country") }} />
        </div>
    );

    return (
        <div>
            <PageHeader headLine="Information" />
            <div id="alertBar">
                {alert.visible ? <AlertBar header={alert.text} disableTransition="false" type={alert.type} /> : null}
            </div>
            <div className="mb-5 mt-5">
                <div className="bg-white ml-1 shadow sm:rounded-md h-max rounded-t">
                    <div className="m-5 p-5">

                        <form onSubmit={(e) => { HandleSubmit(e) }}>

                            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">

                                <div className="relative flex-auto">
                                    <InputBoxReadOnly label="Team name" value={teamData.name} fieldname="team-name" />
                                </div>

                                {isAccountOwner ? <CountrySelection /> : <InputBoxReadOnly label="Country" value={teamData.country} fieldname="country" />}

                                <div className="relative flex-auto col-span-2">
                                    <OptionalTextbox label="Street address" fieldname="street-address" propName="address" readOnly={!isAccountOwner} />
                                </div>

                                <div className="relative flex-auto">
                                    <OptionalTextbox label="ZIP / Postal" fieldname="postal-code" propName="postalCode" readOnly={!isAccountOwner} />
                                </div>

                                <div className="relative flex-auto">
                                    <OptionalTextbox label="City" fieldname="city" propName="city" readOnly={!isAccountOwner} />
                                </div>

                            </div>

                            {isAccountOwner ? <SaveButton /> : <SaveButtonDisabled />}

                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
}