import { GetRequest, PostRequest, DeleteRequest } from "./ApiClientHelper";

export const GetUserInfo = async () => {
	return GetRequest("/api/UserAccount/UserInfo/");
};
export const SetUserInfo = async (userInfo) => {
	return PostRequest("/api/UserAccount/UserInfo/", userInfo);
};

export const ChangeEmail = async (changeRequest) => {
	return PostRequest("/api/UserAccount/ChangeEmail/", changeRequest);
};
export const ConfirmChangeEmail = async (changeRequest) => {
	return PostRequest("/api/UserAccount/ConfirmChangeEmail/", changeRequest);
};
export const ChangePassword = async (changeRequest) => {
	return PostRequest("/api/UserAccount/ChangePassword/", changeRequest);
};

export const DeleteCurrentUser = async () => {
	return DeleteRequest("/api/UserAccount/DeleteCurrentUser/");
};
