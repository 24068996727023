import React from "react";
import ColorPicker from "../Input/ColorPicker";
import * as StylingConstants from "../SignatureBuilder/StylingConstants";

export default function Styling({ signature, setSignature }) {

	function ChangeValue(e, propName) {
		setSignature((prevState) => ({ ...prevState, markUp: { ...prevState.markUp, style: { ...prevState.markUp.style, [propName]: e } } }));
	}
	var styles = {};

	if (signature.markUp) styles = signature.markUp.style;

	var fontsOptions = () =>
		StylingConstants.fonts.map((x) => {
			return { name: x, value: x };
		});
	var fontsSizeOptions = () =>
		StylingConstants.fontSize.map((x) => {
			return { name: x, value: x };
		});
	var weightOptions = () =>
		StylingConstants.weight.map((x) => {
			return { name: x, value: x };
		});

	return (
		<div className="flex flex-col">
			<div className="pb-1 pt-3">
				<div className="text-gray-900 text-lg font-semibold">Style your signature</div>
			</div>
			<Dropdown id={"font"} headLine={"Font"} defaultValue={styles.font} options={fontsOptions()} onChange={(e) => ChangeValue(e, "font")} />
			<Dropdown id={"fontSize"} headLine={"Font size"} defaultValue={styles.fontSize} options={fontsSizeOptions()} onChange={(e) => ChangeValue(e, "fontSize")} />
			<Dropdown id={"headerWeight"} headLine={"Header weight"} defaultValue={styles.headerWeight} options={weightOptions()} onChange={(e) => ChangeValue(e, "headerWeight")} />
			<Dropdown id={"headerFont"} headLine={"Header font"} defaultValue={styles.headerFont} options={fontsOptions()} onChange={(e) => ChangeValue(e, "headerFont")} />
			<Dropdown id={"headerFontSize"} headLine={"Header font size"} defaultValue={styles.headerFontSize} options={fontsSizeOptions()} onChange={(e) => ChangeValue(e, "headerFontSize")} />
			<ColorPicker label="Header colour" color={styles.headerColor} fieldName="HeaderColor" onChange={(e) => ChangeValue(e, "headerColor")} />
			<ColorPicker label="Body colour" color={styles.bodyColor} fieldName="BodyColour" onChange={(e) => ChangeValue(e, "bodyColor")} />
			<ColorPicker label="Accent colour" color={styles.accentColor} fieldName="AccentColor" onChange={(e) => ChangeValue(e, "accentColor")} />
		</div>
	);

	function Dropdown(props) {
		return (
			<div className="mt-4">
				<label htmlFor={props.fieldName} className="block text-sm font-medium text-gray-700">
					{props.headLine}
				</label>
				<div className="flex">
					<div className="flex-auto">
						<select
							required
							id={props.id}
							name={props.id}
							className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-dania-500 focus:border-dania-500 sm:text-sm"
							defaultValue={props.defaultValue}
							onChange={(e) => {
								props.onChange(e.target.value);
							}}
						>
							{props.options.map((option, i) => {
								return <option key={i} value={option.value}>{option.name}</option>;
							})}
						</select>
					</div>
				</div>
			</div>
		);
	}
}
