import PageHeader from "../../../Components/PageHeader";
import React, { Fragment, useEffect } from "react";
import NotificationBar from "../../../Components/NotificationBar";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import CardWithList from "../../../Components/CardWithList"
import { useState } from "react";
import { GetLicenses } from "../../../ApiClients/DynamicTemplateClient"

export default function License(props) {
	const [error, setError] = useState();
	const [licenses, setLicenses] = useState();
	
	useEffect(() => {
		
		GetLicenses().then((data) => 
			{
				setLicenses(data);
			}
		).catch((error) => {
			setError("Unable to fetch data: " + error);
		});

	}, []);

	const CreateCard = (props) => {

		var licenseItems = [
			<CreateValueRow name="Name" value={props.license.companyName} />,
			props.license.disableAdDomainCheck ? <CreateValueRow name="License type" value="Trial" /> : null,
			!props.license.disableAdDomainCheck ? <CreateValueRow name="Allowed domains" value={props.license.domainName ?? "-"} /> : null,
			<CreateValueRow name="Number of users" value={props.license.allowedClientUsers + " / " + props.license.allowedCloudUsers + " (Client / Cloud)"} />,
			<CreateValueRow name="License key" value={props.license.licenseId} />,
			<CreateValueRow name="Expiration date" value={props.license.expirationDate ?? "-"} />,
			<CreateValueRow name="Support e-mail" value={props.license.supportEmail ?? "-"} />,
		];

		if (props.license.featureGroups) {
			props.license.featureGroups.forEach((group) => {
				licenseItems.push(<CreateValueRow name={group.groupName} value={group.activeFeatures?.length > 0 ? group.activeFeatures.join(", ") : "-"} />);
			});	
		}

		return (
			<CardWithList headLine="License information" items={licenseItems} />
		);
	}

	const CreateValueRow = (props) => {
		return (
			<dl className="sm:divide-y sm:divide-gray-200">
				<div className="py-3 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4">
					<dt className="text-sm font-medium text-gray-500">{props.name}</dt>
					<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{props.value}</dd>
				</div>
			</dl>
		);
	}

	var mapIndex = 0;
	return (
		<Fragment>
			<PageHeader headLine="DynamicTemplate Web license"></PageHeader>
			{error ? (
				// Error
				<NotificationBar color="red" text={error} icon={ExclamationCircleIcon} />
			) : (
				licenses ?
					// Actual content
					licenses.map((x) => {
						return (
							<CreateCard key={mapIndex++} license={x} />
						);
					})
				:
					// Loading					
						<CardWithList headLine="License information" />										
					
			)}
		</Fragment>
	);
}
