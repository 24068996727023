import NotificationBar from "./NotificationBar";

export default function HelpNotificationBar(props)
{
    return (
        <NotificationBar
            color="dania" 
            text="If you’re having issues with downloading or installing, please visit our help page" 
            onClick={() => props.onNavigation()}
            linkText="Get help" 
        />
    );
}
