export default function InfoHeader(props) {
  return (
    <>
      <h3 className="text-lg font-medium leading-6 text-gray-900">
        {props.header}
      </h3>
      <p className="mt-1 text-sm text-gray-600">
        {props.text}
        {props.children}
      </p>
    </>
  )
}