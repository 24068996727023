import ReactMarkdown from "react-markdown";
export default function MarkDown(text) {
	return (
		<ReactMarkdown
			components={{
				ul: ({ node, ...props }) => {
					props.ordered = "false";
					return <ul {...props} className="text-black mb-5 list-disc pl-5" />;
				},
				li: ({ node, ...props }) => {
					props.ordered = "false";
					return <li {...props} className="text-gray-500" />;
				},
				strong: ({ node, ...props }) => {
					return <strong {...props} className="text-black font-medium" />;
				},
				h1: ({ node, ...props }) => {
					return (
						<h1 {...props} className="text-xl"/>						
					);
				},
				h2: ({ node, ...props }) => {
					return (
						<h2 {...props} className="text-lg" />
					);
				},
				h3: ({ node, ...props }) => {
					return (
						<h3 {...props} className="text-base" />
					);
				},
				a: ({ node, ...props }) => {
					return (
						<a {...props} className="text-blue-500 cursor-pointer" target="_blank"/>
					);
				},
			}}
			children={text}
		></ReactMarkdown>
	);
}
