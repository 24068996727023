export default function DownloadFile(base64contents, fileName) {

    const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {

        let blob;
        if (typeof b64Data === 'string') {
            const byteArrays = [];
            const byteCharacters = atob(b64Data);
            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                const slice = byteCharacters.slice(offset, offset + sliceSize);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }
            blob = new Blob(byteArrays, { type: contentType });
        } else {
            blob = new Blob([b64Data], { type: contentType });
        }
        return blob;
    }

    return new Promise((resolve, reject) => {

        // Create blob link to download
        const blob = b64toBlob(base64contents);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);

        resolve();
    })
}