import React, { useEffect, useState, Fragment } from "react";
import { GetAssetsListView } from "../../../ApiClients/OutlookSignatureCloudClient";
import { PencilIcon } from "@heroicons/react/solid";
import { PostFormRequest } from "../../../ApiClients/ApiClientHelper";
import { Transition } from "@headlessui/react";
import { TrashIcon, SwitchHorizontalIcon, CheckCircleIcon, UploadIcon, ExclamationIcon } from "@heroicons/react/outline";
import Button from "../../../Components/Buttons/Button";
import Chip from "../../../Components/Chip";
import EnchantedTable from "../../../Components/EncantedTable";
import NotificationBar from "../../../Components/NotificationBar";
import Overlay from "../../../Components/Overlays/Overlay";
import PageHeader from "../../../Components/PageHeader";

export default function Assets() {

    const [activeEditMenu, setActiveEditMenu] = useState("");
    const [activeInfoMenu, setActiveInfoMenu] = useState("");
    const [assets, setAssets] = useState({ files: [], storageQuotaExceeded: false, storageQuotaMB: 0.0, storageUsedMB: 0.0 });
    const [errorNotification, setErrorNotification] = useState({ text: "", color: "", show: false });
    const [fileData, setFileData] = useState({ text: "", assetId: "" });
    const [fileForm, setFileForm] = useState(null);
    const [notificationBar, setNotificationBar] = useState({ text: "", color: "", show: false, icon: CheckCircleIcon });
    const [showDialog, setShowDialog] = useState({ upload: false, edit: false, replace: false, delete: false })
    const [uploadDescription, setUploadDescription] = useState("");
    const hiddenFileInput = React.useRef(null);
    const hiddenReplaceFileInput = React.useRef(null);

    const _validFileExtensions = ["png", "bmp", "jpg", "jpeg", "gif", "dib", "jfif", "jpe"];

    const showFileDialog = () => {
        hiddenFileInput.current.click();
    };

    const showReplaceFileDialog = () => {
        hiddenReplaceFileInput.current.click();
    }

    useEffect(() => {
        refreshAssetsList();
    }, []);

    function refreshAssetsList() {
        GetAssetsListView().then((data) => {
            setAssets(data);
        });
    }

    function getStatusChip(signatureList, id) {
        if (signatureList !== undefined && Array.isArray(signatureList)) {

            // Filter the list to remove any undefined entries.
            let filteredList = signatureList.filter(signature => signature !== undefined);

            // Show "In use" chip if list contains any, otherwise show "Not used" chip instead.
            if (filteredList.length > 0) {
                return <div show onMouseEnter={() => { updateInfoMenu(id) }} onMouseLeave={() => { updateInfoMenu(id) }}>
                    <Chip text="In use" color="green" />
                    <Transition
                        show={ShowInfoMenu(id)}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <div className="absolute text-left rounded-md shadow-xl drop-shadow-xl border border-1 border-gray-200 -ml-5 bg-white z-40 pt-2 pb-2">
                            <div className="text-sm font-normal text-gray-900 flex items-center p-2 rounded-lg">
                                <div className="ml-2">
                                    <p className="font-semibold">Asset used in</p>
                                </div>
                            </div>
                            {filteredList.map((signatureName) => {
                                return <div key={"signature-" + signatureName} className="text-sm font-normal text-gray-900 flex items-center p-2 rounded-lg">
                                    <div className="ml-2">
                                        <p className={(signatureName === "") ? "italic" : ""}>{(signatureName === "") ? "Unnamed signature" : signatureName}</p>
                                    </div>
                                </div>
                            })}
                        </div>

                    </Transition>
                </div>
            } else {
                return <Chip text="Not Used" color="yellow" />
            }
        }
    }

    const onFileInputChange = (event) => {

        const formData = new FormData();
        const fileExtension = event.target.files[0].name.split('.').pop().toLowerCase();

        if (_validFileExtensions.includes(fileExtension)) {
            if (event.target.files[0].size < 5242880) {
                resetErrorNotification();
                formData.append("nameusedinFormData", event.target.files[0]);
                setFileForm(formData);
                setShowDialog(prevState => ({ ...prevState, upload: true }));

            } else {
                errorUpdate("File size cannot exceed 5 MB.");
            }
        } else {
            errorUpdate("File type is not supported. The following file types are allowed: ." + _validFileExtensions.join(", ."));
        }
    };

    const onReplaceFileInputChange = (event) => {

        const formData = new FormData();
        const fileExtension = event.target.files[0].name.split('.').pop().toLowerCase();

        if (_validFileExtensions.includes(fileExtension)) {
            if (event.target.files[0].size < 5242880) {
                resetErrorNotification();

                formData.append("nameusedinFormData", event.target.files[0]);
                formData.append("description", fileData.text);
                setFileForm(formData);
                PostFormRequest("/api/OutlookSignatureCloud/ReplaceAssetContent/" + fileData.assetId, formData)
                    .then((x) => x.json())
                    .then((x) => {
                        notficationUpdate("Success! Asset was successfully replaced and is now available to be used in templates.");
                        setShowDialog(prevState => ({ ...prevState, replace: false }));
                        setUploadDescription("");
                        refreshAssetsList();
                    })
                    .catch((x) => {
                        errorUpdate("Unable to replace asset content.");
                    });

            } else {
                errorUpdate("File size cannot exceed 5 MB.");
            }
        } else {
            errorUpdate("File type is not supported. The following file types are allowed: ." + _validFileExtensions.join(", ."));
        }
    };

    const onUploadClick = () => {
        fileForm.append("description", uploadDescription);
        PostFormRequest("/api/OutlookSignatureCloud/AddAsset", fileForm)
            .then((x) => x.json())
            .then((x) => {
                notficationUpdate("File successfully uploaded.");
                setShowDialog(prevState => ({ ...prevState, upload: false }));
                setUploadDescription("");
                refreshAssetsList();
            })
            .catch((x) => {
                errorUpdate("Unable to upload asset.");
            });
    };

    const onSaveDescriptionClick = () => {
        const formData = new FormData()
        formData.append("description", fileData.text);
        PostFormRequest("/api/OutlookSignatureCloud/UpdateAssetDescription/" + fileData.assetId, formData)
            .then((response) => response.json())
            .then((response) => {
                notficationUpdate("Success! Asset description was successfully updated.");
                setShowDialog(prevState => ({ ...prevState, edit: false }));
                setFileData({ text: "", assetId: "" });
                refreshAssetsList();
            })
            .catch((response) => {
                errorUpdate("Unable to save description changes.");
            });
    };

    const onDeleteClick = () => {
        PostFormRequest("/api/OutlookSignatureCloud/DeleteAsset/" + fileData.assetId)
            .then((response) => response.json())
            .then((response) => {
                notficationUpdate("Success! Asset successfully deleted.");
                setShowDialog(prevState => ({ ...prevState, delete: false }));
                setFileData({ text: "", assetId: "" });
                refreshAssetsList();
            })
            .catch((response) => {
                errorUpdate("Unable to delete asset.");
            });
    };

    function resetErrorNotification() {
        setErrorNotification({ text: "", color: "", show: false });
    }

    function notficationUpdate(text) {
        setNotificationBar({ text: text, color: "green", show: true, icon: CheckCircleIcon });
        new Promise(x => setTimeout(x, 5000)).then(() => setNotificationBar(prevState => ({ ...prevState, show: false })));
    }

    function errorUpdate(text) {
        setErrorNotification({ text: text, color: "red", show: true });
    }

    function formatSize(sizeInByte) {
        if (sizeInByte === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(sizeInByte) / Math.log(k));
        return parseFloat((sizeInByte / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
    }

    function updateEditMenu(id) {
        setActiveEditMenu(id === activeEditMenu ? "" : id);
    }

    function updateInfoMenu(id) {
        setActiveInfoMenu(id === activeInfoMenu ? "" : id);
    }

    function ShowInfoMenu(id) {
        return id === activeInfoMenu;
    }

    function ShowEditMenu(id) {
        return id === activeEditMenu;
    }

    function replaceColor(color, classes) {
        return color ? classes.replaceAll("dania", color) : classes;
    }

    function editAssetDescription(id) {
        let asset = assets.files[assets.files.findIndex((item) => item.id === id)];
        setFileData({ text: asset.description, assetId: id });
        resetErrorNotification();
        setShowDialog(prevState => ({ ...prevState, edit: true }));
    }

    function replaceAsset(id) {
        let asset = assets.files[assets.files.findIndex((item) => item.id === id)];
        setFileData({ text: asset.description, assetId: id });
        resetErrorNotification();
        setShowDialog(prevState => ({ ...prevState, replace: true }));
    }

    function deleteAsset(id) {
        setFileData({ text: "", assetId: id });
        resetErrorNotification();
        setShowDialog(prevState => ({ ...prevState, delete: true }));
    }

    const columns = [
        {
            Header: 'Name',
            Accessor: 'fullname',
            Cell: function (row) {
                return <div className="flex mr-1">
                    <div className="flex justify-center items-center rounded-full h-12 w-12 bg-gray-300 mr-4 overflow-hidden">
                        {Object.keys(row).length > 0 ?
                            <img src={row.downloadUrl + "&cacheKey=" + row.fileSize + "-" + row.filename} alt=""></img>
                            :
                            ""
                        }
                    </div>
                    <div className="flex flex-col">
                        {Object.keys(row).length > 0 ?
                            <Fragment>
                                <div className="text-gray-900">{row.filename}</div>
                                <div className="text-gray-500">{row.description}</div>
                            </Fragment>
                        :
                            <Fragment>
                                <div className="m-2 w-32 h-2 bg-gray-400 rounded" />
                                <div className="m-2 w-24 h-2 bg-gray-400 rounded" />
                            </Fragment>
                        }
                    </div>
                </div>
            }
        },
        {
            Header: 'Status',
            Accessor: 'status',
            Cell: function (row) {
                if (Object.keys(row).length > 0)
                    return getStatusChip(row.usedInSignatures, row.id); 
                else
                    return <Chip text="..." color="gray" />
            }
        },
        {
            Header: 'Size',
            Accessor: 'title',
            Cell: function (row) {
                if (Object.keys(row).length > 0)
                    return <div className="text-gray-500">{formatSize(row.fileSize)}</div>
                else
                    return <div className="w-24 h-2 bg-gray-400 rounded" />
            }
        },
        {
            Header: 'Action',
            Accessor: 'editButton',
            Cell: function (row) {
                if (Object.keys(row).length > 0)
                    return <div show>
                        <span className={(ShowEditMenu(row.id) ? "bg-dania-100 " : "") + "text-dania-500 hover:bg-dania-100 p-4 cursor-pointer rounded-lg" } onClick={() => updateEditMenu(row.id)}>Edit</span>
                        <div>
                            <Transition
                                show={ShowEditMenu(row.id)}
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <div className="absolute text-left rounded-md shadow-xl drop-shadow-xl border border-1 border-gray-200 -ml-5 bg-white z-40 pt-2 pb-2">

                                    <div onClick={() => { updateEditMenu(row.id); editAssetDescription(row.id); }} className="cursor-pointer hover:bg-dania-100 text-sm font-normal text-gray-700 flex items-center pl-6 pr-8 pb-2 pt-2 rounded-lg">
                                        <PencilIcon className="block h-5 w-5 text-gray-500" />
                                        <div className="ml-2">
                                            <p>Edit description</p>
                                        </div>
                                    </div>

                                    <div onClick={() => { updateEditMenu(row.id); replaceAsset(row.id); }} className="cursor-pointer hover:bg-dania-100 text-sm font-normal text-gray-700 flex items-center pl-6 pr-8 pb-2 pt-2 rounded-lg">
                                        <SwitchHorizontalIcon className="block h-5 w-5 text-gray-500" />
                                        <div className="ml-2">
                                            <p>Replace</p>
                                        </div>
                                    </div>

                                    {row.usedInSignatures !== undefined && row.usedInSignatures.length === 0 ?
                                        <div onClick={() => { updateEditMenu(row.id); deleteAsset(row.id); }} className="cursor-pointer hover:bg-dania-100 text-sm font-normal text-gray-700 flex items-center pl-6 pr-8 pb-2 pt-2 rounded-lg">
                                            <TrashIcon className="block h-5 w-5 text-gray-500" />
                                            <div className="ml-2">
                                                <p>Delete asset</p>
                                            </div>
                                        </div> : ""}
                                </div>
                            </Transition>
                        </div>
                    </div>
                else
                    return <div className="w-14 h-2 bg-gray-400 rounded" />
            }
        }
    ]

    function getEditDialog() {
        return (
            <Overlay show={showDialog.edit}>
                <div className="bg-white rounded-md p-5">
                    <div className="flex">
                        <div className="flex-none w-14 h-14">
                            <div className="flex-none w-14 h-14 pt-2">
                                <div className="flex w-10 h-10 rounded-full bg-dania-100 justify-center items-center">
                                    <PencilIcon className="h-6 w-6 text-dania-500" aria-hidden="true" />
                                </div>
                            </div>
                        </div>
                        <div className="flex-auto text-left flex flex-col" style={{ width: "500px" }}>
                            <div className="text-lg font-medium text-gray-900 mb-3 flex-1"> Edit description </div>
                            <div className="flex-1">
                                <textarea className="border-gray-300 w-full h-px75 mt-1 focus:ring-dania-500 focus:border-dania-500 block shadow-sm sm:text-sm rounded-md"
                                    value={fileData.text ? fileData.text : ""}
                                    onChange={(event) => setFileData(prevState => ({ ...prevState, text: event.target.value }))}></textarea>
                            </div>
                            <div className="flex justify-end mb-3 flex-1 mt-5">
                                <span className="mr-3">
                                    <Button text="Cancel" bgColor="bg-white" textColor="text-gray-700" className="mr-3"
                                        onNavigation={() => setShowDialog(prevState => ({ ...prevState, edit: false }))}
                                    />
                                </span>
                                <Button text="Save description" bgColor="bg-dania-500" textColor="text-white"
                                    onNavigation={() => onSaveDescriptionClick()}
                                />
                            </div>

                            {!errorNotification.show ? null : <div className="flex justify-end mb-3 flex-1 mt-5">
                                <NotificationBar text={errorNotification.text} color={errorNotification.color} />
                            </div>}

                        </div>
                    </div>
                </div>
            </Overlay>
        );
    }

    function getReplaceDialog() {
        return (
            <div>
                <Overlay show={showDialog.replace}>
                    <div className="bg-white rounded-md p-5">
                        <div className="flex">
                            <div className="flex-none w-14 h-14">
                                <div className="flex-none w-14 h-14 pt-2">
                                    <div className="flex w-10 h-10 rounded-full bg-yellow-100 justify-center items-center">
                                        <ExclamationIcon className="h-6 w-6 text-yellow-700" aria-hidden="true" />
                                    </div>
                                </div>
                            </div>
                            <div className="flex-auto text-left flex flex-col" style={{ width: "500px" }}>
                                <div className="text-lg font-medium text-gray-900 mb-3 flex-1"> Replace asset </div>
                                <div className="flex-1">
                                    Are you sure you want to replace the asset? All signatures that draw use of the asset will have it replaced immediately.
                                    <br />
                                    This action cannot be undone.
                                </div>
                                <div className="flex justify-end mb-3 flex-1 mt-5">
                                    <span className="mr-3">
                                        <Button text="No, take me back" bgColor="bg-white" textColor="text-gray-700" className="mr-3"
                                            onNavigation={() => { setShowDialog(prevState => ({ ...prevState, replace: false })); resetErrorNotification(); }}
                                        />
                                    </span>
                                    <Button text="Yes - replace asset" bgColor="bg-yellow-600" textColor="text-white"
                                        onNavigation={showReplaceFileDialog}
                                    />
                                </div>

                                {!errorNotification.show ? null : <div className="flex justify-end mb-3 flex-1 mt-5">
                                    <NotificationBar text={errorNotification.text} color={errorNotification.color} />
                                </div>}

                            </div>
                        </div>
                    </div>
                </Overlay>
                <input type="file" accept="image/*" onChange={onReplaceFileInputChange} style={{ display: "none" }} ref={hiddenReplaceFileInput} />
            </div>
        );
    }

    function getDeleteDialog() {
        return (
            <Overlay show={showDialog.delete}>
                <div className="bg-white rounded-md p-5">
                    <div className="flex">
                        <div className="flex-none w-14 h-14">
                            <div className="flex-none w-14 h-14 pt-2">
                                <div className="flex w-10 h-10 rounded-full bg-red-100 justify-center items-center">
                                    <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                </div>
                            </div>
                        </div>
                        <div className="flex-auto text-left flex flex-col" style={{ width: "500px" }}>
                            <div className="text-lg font-medium text-gray-900 mb-3 flex-1"> Delete asset </div>
                            <div className="flex-1">
                                Are you sure you want to delete the asset?
                                <br />
                                The action cannot be undone.
                            </div>
                            <div className="flex justify-end mb-3 flex-1 mt-5">
                                <span className="mr-3">
                                    <Button text="No, take me back" bgColor="bg-white" textColor="text-gray-700" className="mr-3"
                                        onNavigation={() => setShowDialog(prevState => ({ ...prevState, delete: false }))}
                                    />
                                </span>
                                <Button text="Yes - delete asset" bgColor="bg-red-600" textColor="text-white"
                                    onNavigation={() => onDeleteClick()}
                                />
                            </div>

                            {!errorNotification.show ? null : <div className="flex justify-end mb-3 flex-1 mt-5">
                                <NotificationBar text={errorNotification.text} color={errorNotification.color} />
                            </div>}

                        </div>
                    </div>
                </div>
            </Overlay>
        );
    }

    function getUploadDialog() {
        return assets.storageQuotaExceeded
            ? getDisabledUpload()
            : getEnabledUpload();
    }

    function getDisabledUpload() {
        return (
            <div className="text-center pt-5 pb-10 items-center">
                <div className="w-1/2 mb-5 ml-auto mr-auto bg-dania-100 border-l-4 border-dania-500 text-dania-700 p-4" role="alert">
                    <p className="font-bold">Quota exceeded</p>
                    <p>You are using {assets.storageUsedMB} MB of the allowed {assets.storageQuotaMB} MB.</p>
                    <p>Please remove some assets and try again.</p>
                </div>
                <div className="w-full">
                    <button type="button" className="inline-flex items-center px-4 py-2 border border-transparent text-sm rounded-md shadow-sm text-white bg-dania-300 cursor-not-allowed">
                        <UploadIcon className="h-4 w-4 mr-3" aria-hidden="true" />
                        Upload new asset
                    </button>
                </div>
            </div>
        );
    }

    function getEnabledUpload() {
        return (
            <div className="text-center pt-5 pb-10">
                <button onClick={showFileDialog} type="button" className="inline-flex items-center px-4 py-2 mt-5 border border-transparent text-sm rounded-md shadow-sm text-white bg-dania-500 hover:bg-dania-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dania-500">
                    <UploadIcon className="h-4 w-4 mr-3" />
                    Upload new asset
                </button>

                <Overlay show={showDialog.upload}>
                    <div className="bg-white rounded-md p-5">
                        <div className="flex">
                            <div className="flex-none w-14 h-14">
                                <div className="flex-none w-14 h-14 pt-2">
                                    <div className="flex w-10 h-10 rounded-full bg-dania-100 justify-center items-center">
                                        <UploadIcon className="h-4 w-4 text-dania-500 cursor-pointer" aria-hidden="true" />
                                    </div>
                                </div>
                            </div>
                            <div className="flex-auto text-left flex flex-col" style={{ width: "500px" }}>
                                <div className="text-lg font-medium text-gray-900 mb-3 flex-1"> Upload new asset </div>
                                <div className="flex-1">
                                    <div className="text-sm font-medium text-gray-500 mb-3"> Description </div>
                                    <div>
                                        <textarea className="border-gray-300 w-96 h-px75 mt-1 focus:ring-dania-500 focus:border-dania-500 block shadow-sm sm:text-sm rounded-md"
                                            onChange={(event) => setUploadDescription(event.target.value)}></textarea>
                                    </div>
                                </div>
                                <div className="flex justify-end mb-3 flex-1 mt-5">
                                    <span className="mr-3">
                                        <Button text="Cancel" className="mr-3" bgColor="bg-white" textColor="text-gray-700"
                                            onNavigation={() => setShowDialog(prevState => ({ ...prevState, upload: false }))}
                                        />
                                    </span>
                                    <Button text="Upload" bgColor="bg-dania-500" textColor="text-white"
                                        onNavigation={() => onUploadClick()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Overlay>

                <input type="file" accept="image/*" onChange={onFileInputChange} style={{ display: "none" }} ref={hiddenFileInput} />
            </div>
        );
    }

    return (
        <div className="relative">
            <PageHeader headLine="Assets" />
            <div className="pb-3 mb-5">
                <div className="text-gray-900 text-lg font-semibold">Get an overview of the assets that you have uploaded to your signatures.</div>
            </div>

            {!errorNotification.show ? null : <div className="text-center mb-5">
                <NotificationBar text={errorNotification.text} color={errorNotification.color} />
            </div>}

            <div>
                <Transition
                    show={notificationBar.show}
                    as={Fragment}
                    enter="transition ease-out duration-500"
                    enterFrom="transform opacity-0 scale-100"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-500"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-100"
                >
                    <div className={replaceColor(notificationBar.color, "rounded-md bg-dania-50 mb-6 p-4 w-full")}>
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <notificationBar.icon className={replaceColor(notificationBar.color, "h-5 w-5 text-dania-400")} aria-hidden="true" />
                            </div>
                            <div className="ml-3 flex-1 md:flex md:justify-between">
                                <p className={replaceColor(notificationBar.color, "text-sm font-medium text-dania-700")}>{notificationBar.text}</p>
                            </div>
                        </div>
                    </div>
                </Transition>
            </div>

            <EnchantedTable columns={columns} data={assets.files} />

            {getUploadDialog()}

            {getEditDialog()}

            {getReplaceDialog()}

            {getDeleteDialog()}

        </div>
    );
}