import { GetRequest, PostRequest } from './ApiClientHelper'

export const GetTeamInfo = async () => {
    return GetRequest("/api/Team/TeamInfo/");
};
export const GetTeamMembers = async () => {
    return GetRequest("/api/Team/GetTeamMembers/");
};
export const SetTeamInfo = async (teamInfo) => {
    return PostRequest("/api/Team/TeamInfo/", teamInfo);
};
export const ApproveUserRequest = async (userId) => {
    return PostRequest("/api/Team/ApproveUserRequest/", userId);
};
export const RejectUserRequest = async (userId) => {
    return PostRequest("/api/Team/RejectUserRequest/", userId);
};
export const PromoteUser = async (userId) => {
    return PostRequest("/api/Team/PromoteUserAndSendEmail/", userId);
};
export const InviteUser = async (email) => {
    return PostRequest("/api/Team/InviteUser/", email);
};
export const DeleteUserAndSendEmail = async (userId) => {
    return PostRequest("/api/Team/DeleteUserAndSendEmail/", userId);
};
export const GetBillingInfo = async () => {
    return GetRequest("/api/Team/BillingInfo/");
};
export const SetBillingInfo = async (billingInfo) => {
    return PostRequest("/api/Team/BillingInfo/", billingInfo);
};