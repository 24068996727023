import { ExclamationCircleIcon, CheckCircleIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import { SendEmailMessage } from "../ApiClients/MessageClient";
import { useState } from "react";
import authService from "../api-authorization/AuthorizeService";
import NotificationBar from "../Components/NotificationBar";

export default function MessageForm(props) {

	const [message, setMessage] = useState("");
	const [messageSent, setMessageSent] = useState(null);
	const [privacyChecked, setPrivacyChecked] = useState(false);
	const [showSizeWarning, setShowSizeWarning] = useState(false);

	let MaxMessageSize = props.messageSize ? props.messageSize : 110;

	const onSendClick = () => {
		authService
			.getUser()
			.then((user) => {
				var msg = {
					sender: user.email,
					message: message,
					subject: "Help request sent from Apps Portal",
				};
				SendEmailMessage(msg).then((response) => {
					setMessage("");
					setPrivacyChecked(false);
					setMessageSent(true);
				});
			})
			.catch((error) => {
				setMessageSent(false);
				console.log(error);
			});
	};

	const handleMessageInput = (input) => {
		if (input.length < MaxMessageSize) {
			setMessage(input);

			if (showSizeWarning) {
				setShowSizeWarning(false);
			}
		} else {
			setShowSizeWarning(true);
		}
	};

	return (
		<div>
			{messageSent === false ? (
				<div className="mt-5 mb-5">
					<NotificationBar icon={ExclamationCircleIcon} color="red" text="Unable to send message" />
				</div>
			) : null}

			{messageSent === true ? (
				<div className="mt-5 mb-5">
					<NotificationBar icon={CheckCircleIcon} color="green" text="Message is sent successfully" />
				</div>
			) : null}

			{showSizeWarning === true ? (
				<div className="mt-5 mb-5">
					<NotificationBar icon={ExclamationCircleIcon} color="yellow" text={`The message is limited to ${MaxMessageSize} letters`} />
				</div>
			) : null}

			<div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
				<div className="md:grid md:grid-cols-3 md:gap-6">
					<div className="md:col-span-1">
						<h3 className="text-lg font-medium leading-6 text-gray-900">{props.headLine ? props.headLine : "HeadLine"}</h3>
						<p className="mt-1 text-sm text-gray-500">{props.description ? props.description : "Description Description Description Description "}</p>
					</div>
					<div className="mt-5 md:mt-0 md:col-span-2">
						<div className="space-y-6">

							<div>
								<label htmlFor="about" className="block text-sm font-medium text-gray-700">
									{props.messageBoxLabel ? props.messageBoxLabel : "MessageBoxLabel"}
								</label>
								<div className="mt-1">
									<textarea
										maxLength={MaxMessageSize}
										id="about"
										name="about"
										rows={3}
										className="shadow-sm p-2 h-40 focus:outline-none focus:ring-dania-500 focus:ring block w-full sm:text-sm border border-gray-300 rounded-md"
										onChange={(e) => handleMessageInput(e.target.value)}
										value={message}
									/>
								</div>
							</div>

							<div>
								<div className="relative flex items-start">
									<div className="flex items-center h-5 pl-1">
										<input
											id="comments"
											aria-describedby="comments-description"
											onChange={() => setPrivacyChecked(!privacyChecked)}
											checked={privacyChecked}
											name="comments"
											type="checkbox"
											className="focus:ring-dania-500 h-4 w-4 text-dania-600 border-gray-300 rounded"
										/>
									</div>
									<div className="ml-3 text-sm">
										<label htmlFor="comments" className="text-gray-500">
											By submitting this form, you agree to our
										</label>
										<span id="comments-description" className="text-gray-500">
											<Link to={"/Privacy"} className="font-bold" target="_blank">
												{" "}
												Privacy policy.
											</Link>
										</span>
									</div>
								</div>
							</div>

							<div>
								<button
									disabled={!privacyChecked || message.length === 1}
									onClick={() => onSendClick()}
									type="button"
									className={`${
										!privacyChecked || message.length === 0 ? "bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 text-white cursor-not-allowed" : "bg-dania-600 hover:bg-dania-700 focus:ring-dania-500 text-white "
									} inline-flex items-center px-4 py-2 font-medium border border-transparent text-sm rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 `}
								>
									Send
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}