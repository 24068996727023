import SubmenuLayout from "../../../Components/SubmenuLayout";
import GetStarted from "./GetStarted";
import InstallFiles from "./InstallFiles";
import Assets from "./Assets";
import License from "./License";
import Documentation from "./Documentation";
import Tools from "./Tools";
import RoadMap from "./RoadMap";
import ReleaseNotes from "./ReleaseNotes";
import Help from "./Help";
import ActiveDirectory from "./ActiveDirectory"

import { useState,useEffect } from "react";

export default function DynamicTemplateMain() {
	const [activeComponent, setActiveComponent] = useState(<GetStarted onNavigation={(e) => onNavigation(e)} />);

	const navigation = [
		{
			name: "Welcome to DynamicTemplate Web",
			items: [{ name: "Get started", contentComponent: <GetStarted onNavigation={(e) => onNavigation(e)} /> }],
		},
		{
			name: "Downloads",
			items: [
				{ name: "Software", contentComponent: <InstallFiles onNavigation={(e) => onNavigation(e)} /> },
				{ name: "Tools", contentComponent: <Tools onNavigation={(e) => onNavigation(e)} /> },
				{ name: "Assets", contentComponent: <Assets onNavigation={(e) => onNavigation(e)} /> },
			],
		},
		{
			name: "Settings",
			items: [
				{ name: "Microsoft Entra ID", contentComponent: <ActiveDirectory onNavigation={(e) => onNavigation(e)} /> }
			],
		},
		{
			name: "Information",
			items: [
				{ name: "License", contentComponent: <License onNavigation={(e) => onNavigation(e)} /> },
				{ name: "Documentation", contentComponent: <Documentation onNavigation={(e) => onNavigation(e)} /> },
				{ name: "Release notes", contentComponent: <ReleaseNotes onNavigation={(e) => onNavigation(e)} /> },
				{ name: "Roadmap", contentComponent: <RoadMap onNavigation={(e) => onNavigation(e)} /> },
				{ name: "Help", contentComponent: <Help onNavigation={(e) => onNavigation(e)} /> },
			],
		},
	];
	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		if (params.has("tab")) {
			var tab = params.get("tab");
			switch (tab) {
				case "CreateAndManage":
					setActiveComponent(navigation[1].items[0].contentComponent);
					break;
				case "Settings":
					setActiveComponent(navigation[2].items[0].contentComponent);
					break;
				default:
					setActiveComponent(navigation[0].items[0].contentComponent);
					break;
			}
		}
	}, []);
	function onNavigation(activeComponent) {
		setActiveComponent(activeComponent);
	}

	return <SubmenuLayout navigation={navigation} contentComponent={activeComponent} product="DynamicTemplate Web" backUrl="/products" onNavigation={(e) => onNavigation(e)} />;
}
