import { GetRequest, GetJsonRequest, PostJsonRequestCustomHeader, PostRequest, DeleteRequest } from "./ApiClientHelper";

export const AddSignature = async (signature) => {
    return PostRequest("/api/OutlookSignatureCloud/AddSignature", signature);
};
export const ChangeSignatureStatus = async (id) => {
    return PostRequest("/api/OutlookSignatureCloud/ChangeSignatureStatus/" + id);
};
export const CreateNewSignatureTrialLicense = async () => {
    return GetRequest("/api/OutlookSignatureCloud/NewTrialLicense");
};
export const DeleteSignature = async (id) => {
    return DeleteRequest("/api/OutlookSignatureCloud/DeleteSignature/" + id);
};
export const GenerateHtmlForPreview = async (signature, token) => {
    return PostJsonRequestCustomHeader("/api/OutlookSignatureCloud/GenerateHtmlForPreview", signature, token);
};
export const GetAsset = async (id) => {
    return GetRequest("/api/OutlookSignatureCloud/GetAsset/" + id);
};
export const GetAssets = async () => {
    return GetRequest("/api/OutlookSignatureCloud/Assets/");
};
export const GetAssetsListView = async () => {
    return GetRequest("/api/OutlookSignatureCloud/AssetsListView/");
};
export const GetDocumentation = async () => {
    return GetRequest("/api/OutlookSignatureCloud/Documentation/");
};
export const GetInstallFiles = async () => {
    return GetRequest("/api/OutlookSignatureCloud/ManifestFiles/");
};
export const GetLatestStableInstallFile = async () => {
    return GetRequest("/api/OutlookSignatureCloud/ManifestFiles/LatestStable");
};
export const GetLicense = async () => {
    return GetRequest("/api/OutlookSignatureCloud/GetCurrentLicense/");
};
export const GetPredefinedSignatures = async () => {
    return GetRequest("/api/OutlookSignatureCloud/GetPredefinedSignatures");
};
export const GetPrerequisites = async () => {
    return GetRequest("/api/OutlookSignatureCloud/Prerequisites/");
};
export const GetReleaseNotes = async () => {
    return GetRequest("/api/OutlookSignatureCloud/ReleaseNotes/");
};
export const GetRoadMap = async () => {
    return GetRequest("/api/OutlookSignatureCloud/RoadMap/");
};
export const GetSignature = async (id) => {
    return GetRequest("/api/OutlookSignatureCloud/GetSignature/" + id);
};
export const GetSignatures = async () => {
    return GetRequest("/api/OutlookSignatureCloud/GetSignatures");
};
export const UpdateAsset = async (id, description) => {
    return PostRequest("/api/OutlookSignatureCloud/UpdateAsset/" + id, description);
};
export const UpdateSignature = async (signature) => {
    return PostRequest("/api/OutlookSignatureCloud/UpdateSignature/" + signature.id, signature);
};
export const UpdateAdConnectedStatus = async (consentGiven) => {
    return PostRequest("/api/OutlookSignatureCloud/SetConsentStatus/" + consentGiven);
};
export const GetClientId = async () => {
    return GetRequest("/api/OutlookSignatureCloud/GetClientId/");
};