import Chip from "./Chip";
import React from "react";

export default function SignatureCardLoading() {
    return (
        <div className="mb-5 mt-5 ">
            <div className="bg-white ml-1 shadow h-max rounded-lg p-10 h-full flex flex-col animate-pulse">
                <div className="flex">
                    <div className="flex-none rounded bg-gray-100 w-10 h-10 m-auto" />
                    <div className="flex-1"></div>
                    <div className="flex-none">
                        <Chip color="gray" text="..." />
                    </div>
                </div>
                <div className="flex">
                    <div className="flex-none mt-3 w-14 h-2 bg-gray-400 rounded"></div>
                    <div className="flex-1 text-gray-200 text-center">|</div>
                    <div className="flex-none mt-3 w-14 h-2 bg-gray-400 rounded"></div>
                </div>
                <div className="mb-5 mt-5">
                    <div className="w-24 h-3 bg-gray-400 rounded"></div>
                </div>
                <div className="mb-4 flex-1">
                    <div className="w-14 h-2 bg-gray-400 rounded"></div>
                </div>
                <div className="mt-4 rounded bg-gray-100 w-24 h-4" />
            </div>
        </div >
    );
}