/* This example requires Tailwind CSS v2.0+ */
import { useHistory } from "react-router-dom";
import ModalDialog from "../ModalDialog";
import React, { useState } from "react";
import ProductLinker from "./ProductLinker";

export default function ProductCard({ product }) {

    function CreateButton({onClickAction}) {
        
        return (
            <button onClick={onClickAction} className="flex-1 flex-shrink inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
                <product.icon className="w-4 h-4 text-gray-400" aria-hidden="true" />
                <span className="ml-3 text-sm">{product.buttonText}</span>
            </button>
        ) 
    }

    return (
        <div>
            <div key={product.title} className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                <div className="w-full flex items-center justify-between p-6 space-x-6">
                    <div className="flex-1 truncate">
                        <div className="flex items-center space-x-3">
                            <h3 className="text-gray-900 text-sm font-medium truncate">{product.title}</h3>
                            {product.blueBadgeText ? <span className="flex-shrink-0 inline-block px-2 py-0.5 text-dania-700 text-xs font-medium bg-dania-100 rounded-full">{product.blueBadgeText}</span> : null}
                            {product.redBadgeText ? <span className="flex-shrink-0 inline-block px-2 py-0.5 text-red-700 text-xs font-medium bg-red-100 rounded-full">{product.redBadgeText}</span> : null}
                            {product.yellowBadgeText ? <span className="flex-shrink-0 inline-block px-2 py-0.5 text-yellow-700 text-xs font-medium bg-yellow-100 rounded-full">{product.yellowBadgeText}</span> : null}
                            {product.greenBadgeText ? <span className="flex-shrink-0 inline-block px-2 py-0.5 text-green-700 text-xs font-medium bg-green-100 rounded-full">{product.greenBadgeText}</span> : null}
                        </div>
                        <p className="mt-1 text-gray-500 text-sm truncate">{product.subTitle}</p>
                    </div>
                </div>
                <div>
                    <div className="flex items-center justify-center  ">

                        {product.externalLink ? <a href={product.externalLink} target="_blank" rel="noreferrer" className="flex-1 flex-shrink inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
                            <product.icon className="w-4 h-4 text-gray-400" aria-hidden="true" />
                            <span className="ml-3 text-sm">{product.buttonText}</span>
                        </a> :
                            <ProductLinker product={product} Button={CreateButton} />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
