import PageHeader from "../../../Components/PageHeader";
import React from "react";
import NotificationBar from "../../../Components/NotificationBar";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import DownloadList from "../../../Components/Lists/DownloadList";
import { GetDocumentation } from "../../../ApiClients/DynamicTemplateClient";
import { useState, useEffect } from "react";

export default function Documentation() {
	const [data, setData] = useState([]);
	const [error, setError] = useState();

	useEffect(() => {
		try {
			//Fetch install files from API
			GetDocumentation().then((response) => {
				if (response.error === undefined) {
					setData(response);
				} else {
					setError(response.error.message);
				}
			});
		} catch {
			setError("Unable to fetch documentation data");
		}
	}, []);

	const mapItem = (files) => {
		return files.map((x) => {
			//Map object
			return {
				name: x.name,
				downloadUrl: x.downloadUrl,
				description: x.description,
				archived: false,
				fileName: x.filename,
			};
		});
	};

	return (
		<div className="w-full">
			{
				<div>
					<PageHeader headLine="Documentation for DynamicTemplate Web"></PageHeader>
					{error ? (
						<div className="pt-1 pb-6">
							<NotificationBar color="red" text={error} icon={ExclamationCircleIcon} />
						</div>
					) : (
						<div>
							{data.length > 0 ? (
								data.map((x, index) => {
									return <DownloadList key={x.groupName + index} headLine={"Documentation for " + x.groupName} applications={mapItem(x.files)} showMoreText="View more"></DownloadList>;
								})
							) : (
								<div>
											<DownloadList headLine={"Documentation"} applications={[]} showMoreText="View more"></DownloadList>
								</div>
							)}
						</div>
					)}
				</div>
			}
		</div>
	);
}