/* This example requires Tailwind CSS v2.0+ */
import authService from "../api-authorization/AuthorizeService";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import IconCard from "../Components/IconCard";
import { StarIcon, ViewGridAddIcon, UsersIcon, SupportIcon, UserIcon, XCircleIcon } from "@heroicons/react/outline";
import { PlayIcon } from "@heroicons/react/solid";
import ActionGrid from "../Components/Grids/ActionGrid";
import Player from "../Components/player";

export default function Home() {
	const [userName, setUserName] = useState("");
	const [playerUrl, setPlayerUrl] = useState(undefined);
	const history = useHistory();

	useEffect(() => {
		authService.getUser().then((u) => {
			if (u.name) {
				setUserName(u.name);
			} else {
				setUserName(u.email);
			}
		});
	}, []);

	const cardItems = [
		{
			text: "Intro to the site",
			subText: "1:01",
			icon: <StarIcon className="h-8 w-8 text-dania-700" />,
			url: "https://vimeo.com/684634393/d419eabc2a",
			bgColor: "bg-dania-100",
		},
		// {
		// 	text: "Intro to the Team-section",
		// 	subText: "3:28",
		// 	icon: <UsersIcon className="h-8 w-8 text-dania-700" />,
		// 	url: "https://player.vimeo.com/video/438889680?h=9ad9f67318&title=0&byline=0&portrait=0&playsinline=0&autoplay=1&autopause=0&app_id=122963",
		// 	bgColor: "bg-dania-100",
		// },
	];
	const actions = [
		{
			title: "Apps",
			text: "Download software, assets and tools from the Apps-section.",
			onClick: () => history.push("/products"),
			icon: ViewGridAddIcon,
			iconForeground: "text-dania-700",
			iconBackground: "bg-dania-50",
		},
		{
			title: "Team",
			text: "Manage your team and invite new members from the Team-section.",
			onClick: () => history.push("/team"),
			icon: UsersIcon,
			iconForeground: "text-yellow-700",
			iconBackground: "bg-yellow-50",
		},
		{
			title: "Account",
			text: "Manage your account and profile information from the Account-section",
			onClick: () => history.push("/account"),
			icon: UserIcon,
			iconForeground: "text-purple-700",
			iconBackground: "bg-purple-50",
		},
		{
			title: "Help",
			text: "Need help? Navigate to the Help-section to get assistance.",
			onClick: () => history.push("/help"),
			icon: SupportIcon,
			iconForeground: "text-green-700",
			iconBackground: "bg-green-50",
		},
	];

	const showPlayer = (url) => {
		setPlayerUrl(url);
	};
	const Wrapper = ({ children }) => (
		<div className="h-full w-full">
			<span className="cursor-pointer text-dania-700 hover:text-dania-900" onClick={() => setPlayerUrl(undefined)}>
				<XCircleIcon className="h-8 w-8" />
			</span>
			{children}
		</div>
	);
	return (
		<div className="mr-5">
			<div className="text-3xl text-gray-900 font-bold">Welcome, {userName} 👋</div>

			<div className="mb-5 mt-5">
				<div className="bg-white ml-1 shadow  sm:rounded-md h-max  rounded-t">
					<div className="m-5 p-5">
						<p className="font-bold text-gray-700">Let’s get started!</p>
						<p className="text-gray-500">Thank you for signing up. To get you started quickly, here’s a brief rundown of the 5 major sections, that the site is divided in:</p>
						<ul className="list-disc text-gray-500 ml-5 mt-5">
							<li>
								<span className="text-gray-500 font-bold">Home,</span> is where you are right now.
							</li>
							<li>
								<span className="text-gray-500 font-bold">Apps,</span> is where you access your software and all its associated tools, assets, and documents.
							</li>
							<li>
								<span className="text-gray-500 font-bold">Team, </span> is where you invite colleagues to your team and manage your team information and billing.
							</li>
							<li>
								<span className="text-gray-500 font-bold">Help,</span> is where you can send us a message, should you require assistance.{" "}
							</li>
							<li>
								<span className="text-gray-500 font-bold">Account,</span> is where you manage all information regarding your account, such as email, password, name, etc.
							</li>
						</ul>
						<p className="text-gray-500 mt-5">Have a look around - we hope you enjoy the site! Should you need any help, please do reach out - we’re only a message away.</p>
					</div>
				</div>
			</div>

			<hr className="border border-gray-200 mb-6 " />

			{/* Ud kommenteret til vi har de korrekte videoer*/}

			<div className="text-gray-900 text-lg font-semibold">Video guides to get you off to a flying start</div>

			<div className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4">
				{cardItems.map((item, index) => {
					return (
						<IconCard item={item} key={index}>
							<div className="cursor-pointer" onClick={() => showPlayer(item.url)}>
								<p className="text-gray-900 font-medium hover:text-gray-600 mb-3">{item.text}</p>
								<p className="text-gray-500 flex ">
									<PlayIcon className="h-4 w-4 mr-2 mt-0.5 "></PlayIcon>
									<span className="inline-block align-text-top ">{item.subText}</span>
								</p>
							</div>
						</IconCard>
					);
				})}
			</div>
			<hr className="border border-gray-200 mb-6 mt-6" />
			<div className="pb-6">
				<div className="text-gray-900 text-lg font-semibold">Quick access</div>
				<ActionGrid className="mb-6" actions={actions}></ActionGrid>
			</div>
			{playerUrl ? <Player url={playerUrl} OnCloseClick={() => setPlayerUrl(undefined)} /> : null}
		</div>
	);
}
