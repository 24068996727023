/* This example requires Tailwind CSS v2.0+ */
import { Transition } from "@headlessui/react";
import React, { Fragment } from "react";

export default function NotificationBarWithTransition({ text, color, show,Icon }) {

    function replaceColor(newColor, classes) {
        return newColor ? classes.replaceAll("dania", newColor) : classes;
    }
    return (
        <div>
            <Transition
                show={show}
                as={Fragment}
                enter="transition ease-out duration-500"
                enterFrom="transform opacity-0 scale-100"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-500"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-100"
            >
                <div className={replaceColor(color, "rounded-md bg-dania-50 mb-6 p-4 w-full")}>
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <Icon className={replaceColor(color, "h-5 w-5 text-dania-400")} aria-hidden="true" />
                        </div>
                        <div className="ml-3 flex-1 md:flex md:justify-between">
                            <p className={replaceColor(color, "text-sm font-medium text-dania-700")}>{text}</p>
                        </div>
                    </div>
                </div>
            </Transition>
        </div>
    );
}