import { useEffect, useState } from "react";
import PageHeader from "../Components/PageHeader";
import PhoneNumber from "../Components/Input/PhoneNumber";
import { GetUserInfo, SetUserInfo, ChangeEmail, ChangePassword, DeleteCurrentUser, ConfirmChangeEmail } from "../ApiClients/UserAccountClient";
import { GetTeamInfo } from "../ApiClients/TeamClient";
import NotificationBar from "../Components/NotificationBar";
import TextBox from "../Components/Input/TextBox";
import Overlay from "../Components/Overlays/Overlay";
import { ExclamationIcon } from "@heroicons/react/outline";
import authService from "../api-authorization/AuthorizeService";
import { useHistory } from "react-router-dom";

export default function Account() {
    const history = useHistory();
    const [personalInfo, setPersonalInfo] = useState();
    const [message, setMessage] = useState();
    const [passwordMessage, setPasswordMessage] = useState();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showUnableToDelete, setShowUnableToDelete] = useState(false);

    let newEmail = "";
    let password = {};

    useEffect(() => {
        const loadInfo = async () => {
            var teamInfo = await GetTeamInfo();
            var userInfo = await GetUserInfo();
            var isAccountOwner = await authService.isAccountOwner();
            userInfo.company = teamInfo.name;
            userInfo.isAccountOwner = isAccountOwner;

            setPersonalInfo(userInfo);
        };

        const params = new URLSearchParams(window.location.search);
        if (params.has("code") && params.has("email")) {
            ConfirmChangeEmail({ Email: params.get('email'), Code: params.get("code").replace(/ /g, '+') }).then((data) => {
                if (data.status === 200) {
                    setMessage({ color: "green", text: "Email has been changed." });
                } else {
                    setMessage({ color: "red", text: "An error occurred when trying to change the email." });
                }
                loadInfo();
            })
        } else {
            loadInfo();
        }

    }, []);

    function Info(props) {
        return (
            <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">{props.headline}</h3>
                        <p className="mt-1 text-sm text-gray-500">{props.description} </p>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                        <div className="space-y-6">{props.children}</div>
                    </div>
                </div>
            </div>
        );
    }

    function ChangeHandler(e, name) {
        personalInfo[name] = e.target.value;
    }

    //Update email
    const handleSubmitEmail = (e) => {
        e.preventDefault();
        ChangeEmail({ email: newEmail }).then((response) => {
            if (response && response.error) {
                let errorObj = JSON.parse(response.error);
                setMessage({ color: "red", text: errorObj.type === 2 ? errorObj.msg : "Unable to update email." });
            } else {
                setMessage({ color: "green", text: "You will receive a verification email shortly." });
            }
        });
    };

    //Update password
    const handleSubmitPassword = (e) => {
        e.preventDefault();
        if (password.newPassword === password.confirmPassword && password.currentPassword) {
            ChangePassword(password).then((response) => {
                if (response && response.error) {
                    setPasswordMessage({ color: "red", text: "Unable to change password. " + response.error });
                } else {
                    setPasswordMessage({ color: "green", text: "Your password has been saved." });
                }
            });
        } else {
            setPasswordMessage({ color: "red", text: "Unable to change password. Verify input" });
        }
    };

    //Update personal info
    const handleSubmitPersonalInfo = (e) => {
        e.preventDefault();
        SetUserInfo(personalInfo).then((response) => {
            if (response && response.error) {
                setMessage({ color: "red", text: "Unable to save personal information." });
            } else {
                setMessage({ color: "green", text: "Your personal information has been saved." });
            }
        });
    };

    const MessageBar = () => {
        return message ? (
            <div className="mb-5">
                <NotificationBar color={message.color} text={message.text}></NotificationBar>
            </div>
        ) : null;
    };

    const PasswordMessageBar = () => {
        return passwordMessage ? (
            <div className="mb-5">
                <NotificationBar color={passwordMessage.color} text={passwordMessage.text}></NotificationBar>
            </div>
        ) : null;
    };

    const LoadingPlaceholder = (
        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6 h-48 ">
            <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                    <h3 className="text-lg font-medium leading-6 text-gray-900 animate-pulse">
                        <div>
                            <span className="text-sm font-medium text-gray-900 truncate mr-2">
                                <div className="h-2 bg-gray-400 rounded mb-2"></div>
                            </span>
                            <div className="h-2 bg-gray-400 rounded mb-6"></div>
                        </div>
                    </h3>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2 animate-pulse">
                    <div className="md:hidden sm:block animate-pulse">
                        <p className="flex animate-pulse text-sm text-gray-500 mt-2"></p>
                    </div>
                    <div>
                        <span className="text-sm font-medium text-gray-900 truncate mr-2">
                            <div className="h-2 bg-gray-400 rounded mb-2"></div>
                        </span>
                        <div className="h-2 bg-gray-400 rounded mb-6"></div>
                    </div>
                </div>
            </div>
        </div>
    );

    const SubmitButton = (props) => {
        return (
            <button
                className="inline-flex mt-6 items-center px-4 py-2 font-medium border border-transparent text-sm rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dania-500 text-white bg-dania-500 hover:bg-dania-800"
                type="submit"
            >
                {props.children}
            </button>
        );
    };

    const DeleteAccount = () => {
        let redirectUrl = window.location.origin + "/identity/account/AccountDeleted";

        DeleteCurrentUser()
            .then(window.location.replace(redirectUrl))
            .catch((x) => {
                setMessage({ color: "red", text: "Unable to delete account." });
            });
    };

    const ShowDeleteClick = () => {
        if (personalInfo.isAccountOwner) {
            setShowUnableToDelete(!showUnableToDelete);
        } else {
            setShowDeleteDialog(true);
        }
    };

    return (
        <div className="mr-4">
            <PageHeader headLine="Account settings"></PageHeader>

            <div className="mt-12">
                <MessageBar></MessageBar>

                {personalInfo === undefined ? (
                    LoadingPlaceholder
                ) : (
                    <div>
                        <Info headline="Personal information" description="Update your personal information">
                            <form onSubmit={handleSubmitPersonalInfo}>
                                <div className="grid grid-cols-2 gap-4">
                                    <TextBox onChange={(e) => ChangeHandler(e, "firstName")} defaultValue={personalInfo.firstName} label="First name" fieldName="first-name" autoComplete="first-name" />
                                    <TextBox onChange={(e) => ChangeHandler(e, "lastName")} defaultValue={personalInfo.lastName} label="Last name" fieldName="last-name" autoComplete="family-name" />
                                    <TextBox readOnly defaultValue={personalInfo.company} label="Company" fieldName="company" autoComplete="company" propName="company" />
                                    <TextBox onChange={(e) => ChangeHandler(e, "title")} defaultValue={personalInfo.title} label="Position/Title" fieldName="title" autoComplete="title" propName="title" />
                                    <div className="col-span-2 sm:col-span-1">
                                        <label htmlFor="phone-code" className="block text-sm font-medium text-gray-700">
                                            Phone number
                                        </label>
                                        <PhoneNumber defaultValue={personalInfo.phone ? personalInfo.phone : ""} onChange={(e) => ChangeHandler(e, "phone")} />
                                    </div>
                                </div>
                                <SubmitButton>Save</SubmitButton>
                            </form>
                        </Info>

                        <div className="mt-8">
                            <Info className="m-5" headline="Email" description="Change your email if needed. You will receive a verification email shortly.">
                                <form onSubmit={handleSubmitEmail}>
                                    <div className="grid grid-cols-2 gap-4">
                                        <TextBox readOnly defaultValue={personalInfo.email} label="Current email" fieldName="email" autoComplete="email" />
                                        <div></div>
                                        <TextBox onChange={(e) => (newEmail = e.target.value)} label="New email" fieldName="newEmail" autoComplete="email" />
                                    </div>
                                    <SubmitButton>Update email</SubmitButton>
                                </form>
                            </Info>
                        </div>

                        <div className="mt-8">
                            <PasswordMessageBar />
                            <Info headline="Password" description="Change your password if needed.">
                                <form onSubmit={handleSubmitPassword}>
                                    <div className="grid grid-cols-2 gap-4">
                                        <TextBox onChange={(e) => (password.currentPassword = e.target.value)} type="password" label="Current password" fieldName="currentPassword" autoComplete="currentPassword" />
                                        <div></div>
                                        <TextBox onChange={(e) => (password.newPassword = e.target.value)} type="password" label="New password" fieldName="newPassword" autoComplete="newPassword" />
                                        <div></div>
                                        <TextBox onChange={(e) => (password.confirmPassword = e.target.value)} type="password" label="Confirm password" fieldName="confirmPassword" autoComplete="confirmPassword" />
                                    </div>
                                    <SubmitButton>Update password</SubmitButton>
                                </form>
                            </Info>
                        </div>
                        <Overlay show={showDeleteDialog}>
                            <div className="min-h-48 bg-white rounded-md shadow-md">
                                <div className="m-6 p-6 text-left">
                                    <div className="flex flex-row max-w-md">
                                        <div className="basis-1/4 pr-5">
                                            <ExclamationIcon className="h-12 b-12 text-red-600 bg-red-100 rounded-3xl p-2" />
                                        </div>
                                        <div className="flex-1 ">
                                            <div className="text-gray-900 text-lg font-medium pb-3">Delete account</div>
                                            <div className="text-gray-500 text-sm font-normal">Are you sure you want to delete your account? All of your data will be permanently removed from our servers. This action cannot be undone.</div>
                                            <div className="relative mt-8">
                                                <div className="float-right">
                                                    <button
                                                        onClick={() => setShowDeleteDialog(false)}
                                                        className="inline-flex mr-6 mt-6 items-center px-4 py-2 font-medium border border-transparent text-sm rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 border-gray-200 text-gray-700 focus:ring-gray-100 bg-white hover:bg-gray-100"
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button
                                                        onClick={() => DeleteAccount()}
                                                        className="inline-flex mt-6 items-center px-4 py-2 font-medium border border-transparent text-sm rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 text-white focus:ring-red-700 bg-red-600 hover:bg-red-700"
                                                    >
                                                        Delete account
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Overlay>

                        <div className="relative mt-10 min-h-full p-4">
                            {showUnableToDelete ? (
                                <div onClick={() => setShowUnableToDelete(false)} className="rounded-md mb-5 border border-1 cursor-pointer border-gray-100 bg-white shadow-2xl max-w-md absolute bottom-2 right-6 text-left p-4 pt-6">
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                            <ExclamationIcon className="h-5 w-5 text-gray-700" aria-hidden="true" />
                                        </div>
                                        <div className="ml-3">
                                            <h3 className="text-sm font-medium text-gray-700">Attention needed</h3>
                                            <div className="mt-2 text-sm text-gray-700">
                                                <p>You are unable to delete your account.</p>
                                                <p className="mt-1">This is because of:</p>
                                                <p>a) you are the administrator of a team with more than 1 member or </p>
                                                <p>b) you are the only member of a team that is in an active billing cycle.</p>
                                                <p className="mt-1">Contact us for assistance.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                            <div className="absolute right-0 b-5">
                                <button
                                    onClick={() => ShowDeleteClick()}
                                    className="inline-flex mb-10 mt-6 items-center px-4 py-2 font-medium border border-transparent text-sm rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 text-red-700 focus:ring-red-700 bg-red-100 hover:bg-red-200"
                                >
                                    Delete account
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
