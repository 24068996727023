import { GetRequest, PostRequest, DeleteRequest } from "./ApiClientHelper";

export const GetInstallFiles = async () => {
    return GetRequest("/api/DynamicTemplate/InstallFiles/");
};

export const GetLatestStableInstallFile = async () => {
    return GetRequest("/api/DynamicTemplate/InstallFiles/LatestStable");
};

export const GetAssets = async () => {
    return GetRequest("/api/DynamicTemplate/Assets/");
};

export const GetDocumentation = async () => {
    return GetRequest("/api/DynamicTemplate/Documentation/");
};

export const GetTools = async () => {
    return GetRequest("/api/DynamicTemplate/Tools/");
};

export const GetReleaseNotes = async () => {
    return GetRequest("/api/DynamicTemplate/ReleaseNotes/");
};

export const GetRoadMap = async () => {
    return GetRequest("/api/DynamicTemplate/RoadMap/");
};

export const GetLicenses = async () => {
    return GetRequest("/api/DynamicTemplate/Licenses/");
};

export const GetPrerequisites = async () => {
    return GetRequest("/api/DynamicTemplate/Prerequisites/");
};
export const GetClientId = async () => {
    return GetRequest("/api/DynamicTemplate/ClientId/");
};
export const AddConsent = async (name, tenantId, clientId) => {
    var data = { name: name, tenantId: tenantId, clientId: clientId };
    return PostRequest("/api/DynamicTemplate/Consents/", data);
};

export const GetConsents = async () => {
    return GetRequest("/api/DynamicTemplate/Consents");
};

export const DeleteConsent = async (tenantId) => {
    return DeleteRequest(`/api/DynamicTemplate/Consent/${tenantId}`);
};
