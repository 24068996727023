/* This example requires Tailwind CSS v2.0+ */
import { useState, useEffect, Fragment } from 'react'
import { Transition } from '@headlessui/react'
import InfoHeader from './InfoHeader';
import TrialProduct from './TrialProduct';
import { MapProducts } from "../../Views/Products";

export default function AvailableTrialsPrompt({onClose}) {
  const [trials, setTrials] = useState([{}, {}]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    
    MapProducts().then((response) => {
      if (response !== undefined) {
        var availableTrialProducts = response.trialProducts.filter((x) => x.teaserName);
        setTrials(availableTrialProducts);
        setLoading(false);
      } else {
          onClose();
      }
    }).catch((x) => {
      onClose();
    });
    
  }, [])

  function ContentContainer() {

      return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-y-0 min-h-64">
            {trials.map((trial, index) => {
              return <div className={(index % 2 == 0 ? "md:border-r-2 md:pr-10" : "md:pl-10") + " border-gray-100"}>
                  <TrialProduct product={trial} loading={loading} />
                </div>
            })}
        </div>
      );
  }

  function Submit(e) {
    
    e.preventDefault();

    // close
    onClose();
  }

  return (
    <Transition appear={true} show={true}
          enter="transform transition duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100" 
          leave="transform transition duration-150" 
          leaveFrom="opacity-100" 
          leaveTo="opacity-0"
    >
      <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">

        <div className="text-center my-6">
          <InfoHeader header="Available trials" text="" />
        </div>

        <form onSubmit={(e) => {Submit(e)}}>
        
          <div className="px-4 pb-4 sm:px-12 sm:pb-4">
              <ContentContainer />
          </div>

          <div className="bg-gray-50 px-4 py-3 sm:px-6 flex flex-col-reverse sm:flex-row sm:justify-end">
            
            <button
              type="submit"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dania-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              Skip
            </button>
          </div>
        </form>
      </div>
    </Transition>
  )
}