import { GetRequest } from './ApiClientHelper'

export const GetInstallFiles = async () => {
	return GetRequest("/api/OutlookSignature/InstallFiles/");
};

export const GetLatestStableInstallFile = async () => {
	return GetRequest("/api/OutlookSignature/InstallFiles/LatestStable");
};

export const GetAssets = async () => {
	return GetRequest("/api/OutlookSignature/Assets/");
};

export const GetDocumentation = async () => {
	return GetRequest("/api/OutlookSignature/Documentation/");
};

export const GetTools = async () => {
	return GetRequest("/api/OutlookSignature/Tools/");
};

export const GetReleaseNotes = async () => {
	return GetRequest("/api/OutlookSignature/ReleaseNotes/");
};

export const GetRoadMap = async () => {
	return GetRequest("/api/OutlookSignature/RoadMap/");
};

export const GetPrerequisites = async () => {
	return GetRequest("/api/OutlookSignature/Prerequisites/");
};