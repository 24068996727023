/*
  This component requires Tailwind CSS v2.0+ 
  This component requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require("@tailwindcss/forms"),
    ],
  }
  ```

  menuItems example:
  ```
    var menuItems = [
        {id: 0, label: "Option 1", value: "ValueA"},
        {id: 1, label: "Option 2", value: "ValueB"},
        {id: 2, label: "Option 3", value: "ValueC"},
    ];
  ```
*/

import { useState } from 'react'
import { CheckIcon, LinkIcon } from '@heroicons/react/solid'
import { Combobox } from '@headlessui/react'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export default function SimpleCombobox({ menuItems, label, allowLinking, defaultValue, onValueChange, onLabelChange, placeholder, className }) {
    const [query, setQuery] = useState('');
    const [selectedItem, setSelectedItem] = useState();
    const [showLabelField, setShowLabelField] = useState(false);

    // Use initial value as default field content, if available.
    if ((selectedItem === '' || selectedItem === undefined) && defaultValue !== '') {
        setSelectedItem({ id: -1, label: '', value: defaultValue });
    }

    const filteredItems =
        query === ''
            ? menuItems
            : getFilteredMenuItemsOrAllItems();

    function getFilteredMenuItemsOrAllItems() {
        // Search by value
        var filteredByValue = menuItems.filter((item) => {
            return item.value.toLowerCase().includes(query.toLowerCase())
        });

        // Search by label
        var filteredByLabel = menuItems.filter((item) => {
            return item.label.toLowerCase().includes(query.toLowerCase())
        });

        // Combine search results
        const mergedArray = [...filteredByValue, ...filteredByLabel];

        // Remove duplicates (if any) by comparing label values.
        const filtered = [...mergedArray.reduce((map, obj) => map.set(obj.label, obj), new Map()).values()];

        // Return all menu items if no matches were found.
        return (filtered === '' || filtered === undefined || filtered.length === 0)
            ? menuItems
            : filtered;
    }

    function toggleLabel() {
        if (onLabelChange !== undefined) {
            setShowLabelField(!showLabelField);
        }
    }

    function ComboboxChange(selectedItem) {
        setSelectedItem(selectedItem)
        onValueChange({ target: { value: selectedItem.value } });
    }

    return (
        <Combobox as="div" value={selectedItem} onChange={ComboboxChange} className={className}>

            {/* ---- Label (including edit) start ---- */}
            {!showLabelField ?
                <div onClick={toggleLabel} className="cursor-pointer">
                    <Combobox.Label className="block text-sm font-medium text-gray-700">{label}</Combobox.Label>
                </div>
                : null}
            {
                onLabelChange !== undefined && showLabelField ?
                    <input
                        type="text"
                        name={label + "-name"}
                        id={label + "-id"}
                        className="mt-1 focus:ring-dania-500 focus:border-dania-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        placeholder={placeholder}
                        defaultValue={label ? label : ""}
                        autoFocus
                        onChange={(e) => {
                            if (onLabelChange) {
                                onLabelChange(e);
                            }
                        }}
                        onBlur={toggleLabel}
                    />
                    : null
            }
            {/* ---- Label (including edit) end ---- */}

            <div className="relative mt-1">
                {/* ---- Combobox value start ---- */}
                <Combobox.Input
                    className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-dania-500 focus:outline-none focus:ring-1 focus:ring-dania-500 sm:text-sm"
                    placeholder={ placeholder}
                    onChange={
                        (event) => {
                            // Use typed text as selected value
                            setSelectedItem({ id: -1, label: 'Custom value', value: event.target.value });

                            // Search for matches in the menuItems list, show matches if any.
                            setQuery(event.target.value);

                            // Forward change event, if handler is provided.
                            if (onValueChange) { onValueChange(event); }
                        }
                    }
                    displayValue={(item) => item.value}
                />
                {!allowLinking ? null : 
                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <LinkIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </Combobox.Button>
                }
                {/* ---- Combobox value end ---- */}

                {/* ---- Combobox options start ---- */}
                {filteredItems.length > 0 && (
                    <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {filteredItems.map((item) => (
                            <Combobox.Option
                                key={item.id}
                                value={item}
                                className={({ active }) => classNames("relative cursor-default select-none py-2 pl-3 pr-9", active ? "bg-dania-500 text-white" : "text-gray-900")}
                            >
                                {({ active, selected }) => (
                                    <>
                                        <span className={classNames("block truncate", selected && "font-semibold")}>{item.label}</span>
                                        {selected && (
                                            <span className={classNames("absolute inset-y-0 right-0 flex items-center pr-4", active ? "text-white" : "text-dania-500")}>
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                        )}
                                    </>
                                )}
                            </Combobox.Option>
                        ))}
                    </Combobox.Options>
                )}
                {/* ---- Combobox options end ---- */}
            </div>
        </Combobox >
    )
}