import { useEffect, useState } from "react";

import PageHeader from "../../../Components/PageHeader";
import ActionGrid from "../../../Components/Grids/ActionGrid";
import CardWithList from "../../../Components/CardWithList";
import Button from "../../../Components/Buttons/Button";
import DownloadButton from "../../../Components/Buttons/DownloadButton";
import { GetLatestStableInstallFile } from "../../../ApiClients/OutlookSignatureCloudClient";

import { SupportIcon, DownloadIcon, NewspaperIcon, FlagIcon, UserAddIcon, LinkIcon } from "@heroicons/react/outline";
import { PencilIcon } from "@heroicons/react/solid";

import Help from "./Help";
import Downloads from "./InstallFiles";
import ReleaseNotes from "./ReleaseNotes";
import Roadmap from "./RoadMap";
import Settings from "./Settings"
import CreateAndManage from "./CreateAndManage"

export default function GetStarted(props) {
    const [steps, setSteps] = useState(undefined);
    const [isLinked, setIsLinked] = useState(false);


    useEffect(() => {
        const fetchData = async () => {
            var installFile = await GetLatestStableInstallFile();

            var localSteps = [];

            if (!isLinked) {
                localSteps.push(CreateRow(
                    "Link your Azure Active Directory",
                    "Start by hooking SignatureCloud to your organisation’s Azure Directory. If you do not have permission to do so, then invite IT to your team.",
                    <Button icon={LinkIcon} iconClasses="" text="Link AD" textColor="text-white" bgColor="bg-dania-600" hoverColor="bg-dania-700" onNavigation={() => props.onNavigation(<Settings />)} />
                ));
            }
            localSteps.push(CreateRow("Create and manage signatures", "Next, create new email signatures or manage existing ones.", <Button icon={PencilIcon} iconClasses="" text="Create" textColor="text-white" bgColor="bg-dania-600" hoverColor="bg-dania-700" onNavigation={() => props.onNavigation(<CreateAndManage />)} />))
            localSteps.push(CreateRow("Download and deploy", "Download the manifest for the cloud Add-in and deploy in the Microsoft Admin Center.", <DownloadButton base64contents={installFile.content} fileName={installFile.filename} />))

            setSteps(localSteps);
        };

        var rowIndex = 0;
        function CreateRow(title, text, button) {
            rowIndex++;
            return (
                <div className="flex flex-row">
                    <div className="grid place-items-center min-h-full">
                        <div className="rounded-md h-10 w-10 bg-dania-50 p-2 text-dania-500 font-medium text-lg mr-5 pt-1 text-center border">{(rowIndex < 10 ? "0" : "") + rowIndex}</div>
                    </div>
                    <div className="flex flex-col flex-1">
                        <div className="text-lg font-medium">{title}</div>
                        <div className="text-sm">{text}</div>
                    </div>
                    <div className="flex items-center">{button}</div>
                </div>
            );
        }

        fetchData();
    }, []);

    const actions = [

        {
            title: "Link Active Directory",
            text: "Hook SignatureCloud to your Organisations Active Directory.",
            linkText: "Link",
            onClick: () => props.onNavigation(<Settings />),
            icon: LinkIcon,
            iconForeground: "text-green-700",
            iconBackground: "bg-green-50",
        },
        {
            title: "Create and manage signatures",
            text: "Start creating new email signatures or manage existing ones.",
            linkText: "Create and manage",
            onClick: () => props.onNavigation(<CreateAndManage />),
            icon: PencilIcon,
            iconForeground: "text-purple-700",
            iconBackground: "bg-purple-50",
        },
        {
            title: "Downloads",
            text: "Visit the download section to download older versions of SignatureCloud.",
            linkText: "Software",
            onClick: () => props.onNavigation(<Downloads />),
            icon: DownloadIcon,
            iconForeground: "text-dania-700",
            iconBackground: "bg-dania-50",
        },
        {
            title: "Help",
            text: "Having issues? Visit our help section to get your issues solved.",
            onClick: () => props.onNavigation(<Help />),
            icon: SupportIcon,
            iconForeground: "text-green-700",
            iconBackground: "bg-green-50",
        },
        {
            title: "Release notes",
            text: "Read the latest release notes, to see what's new.",
            onClick: () => props.onNavigation(<ReleaseNotes />),
            icon: NewspaperIcon,
            iconForeground: "text-red-700",
            iconBackground: "bg-red-50",
        },
        {
            title: "Roadmap",
            text: "Check out our roadmap to see upcoming features.",
            onClick: () => props.onNavigation(<Roadmap />),
            icon: FlagIcon,
            iconForeground: "text-blue-700",
            iconBackground: "bg-blue-50",
        },
    ];

    return (
        <div>
            <PageHeader headLine="Get started with SignatureCloud" />
            <CardWithList items={steps} headLine="Your quick start guide" />
            <ActionGrid actions={actions} />
        </div>
    );
}
