import SubmenuLayout from "../../../Components/SubmenuLayout";
import GetStarted from "./GetStarted";
import InstallFiles from "./InstallFiles";
import Assets from "./Assets";
import Documentation from "./Documentation";
import Tools from "./Tools";
import RoadMap from "./RoadMap";
import ReleaseNotes from "./ReleaseNotes";
import Help from "./Help";

import { useState } from "react";

export default function OutlookSignatureMain() {
	const [activeComponent, setActiveComponent] = useState(<GetStarted onNavigation={(e) => onNavigation(e)} />);

	const navigation = [
		{
			name: "Welcome",
			items: [{ name: "Get started", contentComponent: <GetStarted onNavigation={(e) => onNavigation(e)} /> }],
		},
		{
			name: "Downloads",
			items: [
				{ name: "Software", contentComponent: <InstallFiles onNavigation={(e) => onNavigation(e)} /> },
				{ name: "Tools", contentComponent: <Tools onNavigation={(e) => onNavigation(e)} /> },
				{ name: "Assets", contentComponent: <Assets onNavigation={(e) => onNavigation(e)} /> },
			],
		},
		{
			name: "Information",
			items: [
				{ name: "Documentation", contentComponent: <Documentation onNavigation={(e) => onNavigation(e)} /> },
				{ name: "Release notes", contentComponent: <ReleaseNotes onNavigation={(e) => onNavigation(e)} /> },
				{ name: "Roadmap", contentComponent: <RoadMap onNavigation={(e) => onNavigation(e)} /> },
				{ name: "Help", contentComponent: <Help onNavigation={(e) => onNavigation(e)} /> },
			],
		},
	];

	function onNavigation(activeComponent) {
		setActiveComponent(activeComponent);
	}

	return <SubmenuLayout navigation={navigation} contentComponent={activeComponent} product="OutlookSignature" backUrl="/products" onNavigation={(e) => onNavigation(e)} />;
}
