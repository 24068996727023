/* This example requires Tailwind CSS v2.0+ */
import { Switch } from "@headlessui/react";
import Chip from "../Chip";

export default function Dropdown(props) {
    return (
        <Switch.Group as="div" className="flex items-center justify-between">
            <div className="flex">
                <span className="flex-grow flex flex-col pr-10">
                    <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
                        {props.headLine} {props.proFeature ? <Chip text="Pro" color="dania" /> : null}
                    </Switch.Label>
                    <Switch.Description as="span" className="text-sm text-gray-500">
                        {props.description}
                    </Switch.Description>
                    <span>
                        {props.badges != undefined ?
                            props.badges.map((b) => (
                                (b.hideOnPro && props.proStatus) ?
                                    ""
                                    :
                                    <Chip text={b.name} color={b.color} marginRightTailWind="mr-1.5" />
                            ))
                            :
                            ""}
                    </span>
                </span>
            </div>
            {!props.disabled ?
                <div className="flex w-32">
                    <select
                        required
                        id={props.id}
                        name={props.id}
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-dania-500 focus:border-dania-500 sm:text-sm"
                        defaultValue={props.defaultValue}
                        onChange={(e) => { props.onChange(e) }}
                    >
                        {props.options.map((option, i) => {
                            return <option value={option.value}>{option.name}</option>;
                        })}

                    </select>
                </div>
                : null}
        </Switch.Group>
    );
}