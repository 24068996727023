import React, { useState, Fragment } from 'react';
import { Transition } from "@headlessui/react";
import { LockClosedIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";

export default function Lock({ element, locked }) {
    const [isShown, setIsShown] = useState(false);

    const proLock = {
        opacity: 1,
        zIndex: 10,
        width: "30px",
        height: "30px",
        borderRadius: "60px",
        backgroundColor: "#4DB2FF",
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        right: "0"
    };

    const disableDiv = {
        pointerEvents: "none",
        opacity: 0.4,
        zIndex: 1
    };

    const dropOutSize = {
        height: "104px",
        width: "220px"
    }

    return (
        locked ?
            <div className="relative cursor-not-allowed">
                <div style={disableDiv}>{element}</div>
                <div style={proLock} className="flex" onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>
                    <Transition
                        show={isShown}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <div className="absolute text-left rounded-md shadow-xl drop-shadow-xl border border-1 border-gray-200 bg-white z-40 bottom-10 -right-10" style={dropOutSize}>
                            <div className="text-sm text-center font-normal text-gray-700 flex items-center pl-4 pr-4 pb-2 pt-2 rounded-lg">
                                <div className="ml-2">
                                    <p>This is a pro feature, that is only available in our Pro-plan. <Link to="#" onClick={(e) => {
                                        window.location = "mailto:contact@daniasoftware.com";
                                        e.preventDefault();
                                    }} className="text-dania-500">Get in touch</Link> to upgrade.</p>
                                </div>
                            </div>
                        </div>
                    </Transition>
                    <LockClosedIcon className="h-5 w-5 text-white m-auto" />
                </div>
            </div> : element
    );
}