import { useState } from "react";

export default function PhoneNumber(props) {
    const [number, setNumber] = useState(props.defaultValue);
    const [isRequired, setIsRequired] = useState(props.isRequired);

    var countries = [
        {
            country: 'DK',
            prefix: '+45'
        },
        {
            country: 'NO',
            prefix: '+47'
        },
        {
            country: 'SE',
            prefix: '+46'
        },
        {
            country: 'UK',
            prefix: '+44'
        },
        {
            country: 'DE',
            prefix: '+49'
        },
        {
            country: 'BE',
            prefix: '+32'
        },
        {
            country: 'BG',
            prefix: '+359'
        },
        {
            country: 'CZ',
            prefix: '+420'
        },
        {
            country: 'EE',
            prefix: '+372'
        },
        {
            country: 'FO',
            prefix: '+298'
        },
        {
            country: 'IE',
            prefix: '+353'
        },
        {
            country: 'GR',
            prefix: '+30'
        },
        {
            country: 'ES',
            prefix: '+34'
        },
        {
            country: 'FR',
            prefix: '+33'
        },
        {
            country: 'HR',
            prefix: '+385'
        },
        {
            country: 'IT',
            prefix: '+39'
        },
        {
            country: 'CY',
            prefix: '+357'
        },
        {
            country: 'LV',
            prefix: '+371'
        },
        {
            country: 'LT',
            prefix: '+370'
        },
        {
            country: 'LU',
            prefix: '+352'
        },
        {
            country: 'HU',
            prefix: '+36'
        },
        {
            country: 'MT',
            prefix: '+356'
        },
        {
            country: 'NL',
            prefix: '+31'
        },
        {
            country: 'AT',
            prefix: '+43'
        },
        {
            country: 'PL',
            prefix: '+48'
        },
        {
            country: 'PT',
            prefix: '+351'
        },
        {
            country: 'RO',
            prefix: '+40'
        },
        {
            country: 'SI',
            prefix: '+386'
        },
        {
            country: 'SK',
            prefix: '+421'
        },
        {
            country: 'FI',
            prefix: '+358'
        },
        {
            country: 'IS',
            prefix: '+354'
        },
        {
            country: 'LI',
            prefix: '+423'
        },
        {
            country: 'CH',
            prefix: '+41'
        },
    ]

    return (
        <div className="mt-1 relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 flex items-center">
                <label htmlFor="country" className="sr-only">
                    Country
                </label>
                <select
                    id="country"
                    name="country"
                    autoComplete="country"
                    className="focus:ring-dania-500 focus:border-dania-500 h-full py-0 pl-3 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
                    onChange={(e) => { setNumber(e.target.value) }}
                >
                    {countries.map((country) => (
                        <option key={country.country} value={country.prefix}>{country.country}</option>
                    ))}
                </select>
            </div>
            <input
                type="text"
                name="phone-number"
                id="phone-number"
                autoComplete="tel"
                className="focus:ring-dania-500 focus:border-dania-500 block w-full pl-16 sm:text-sm border-gray-300 rounded-md"
                aria-describedby="country-optional"
                placeholder={countries[0].prefix}
                value={number}
                onChange={(e) => { setNumber(e.target.value); props.onChange(e) }}
                required={isRequired}
            />
        </div>
    );
}