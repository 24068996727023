import { graphConfig, loginRequest } from "./authConfig";
import { msalInstance } from "./login";

let groups = [];
export async function GetAzureToken(clientId) {

    const account = msalInstance(clientId).getActiveAccount();

    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const response = await msalInstance(clientId).acquireTokenSilent({
        ...loginRequest,
        account: account
    });
    let accessToken = response.accessToken;


    return accessToken;
}

export async function GetProfileData(fields, clientId) {

    const account = msalInstance(clientId).getActiveAccount();

    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const response = await msalInstance(clientId).acquireTokenSilent({
        ...loginRequest,
        account: account
    });
    let accessToken = response.accessToken;

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    var querystring = "me?$select="
    querystring = querystring + fields.join(',');


    return fetch(graphConfig.graphMeEndpoint + querystring, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

export async function GetProfilePicture(clientId) {

    const account = msalInstance(clientId).getActiveAccount();

    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const response = await msalInstance(clientId).acquireTokenSilent({
        ...loginRequest,
        account: account
    });
    let accessToken = response.accessToken;

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    var querystring = "me/photo/$value";
    var graphEndpoint = graphConfig.graphMeEndpoint + querystring;

    return fetch(graphEndpoint, options)
        .then(response => response.arrayBuffer())
        .catch(error => console.log(error));
}

export async function GetGroups(clientId) {

    const account = msalInstance(clientId).getActiveAccount();

    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const response = await msalInstance(clientId).acquireTokenSilent({
        ...loginRequest,
        account: account
    });
    let accessToken = response.accessToken;


    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return Test(graphConfig.graphMeEndpoint + "groups?$top=100&$select=id,displayName,groupTypes", options);
}

async function Test(url, options) {
    return FetchGroups(url, options).then((x) => {
        x.value.forEach(q => groups.push(q))
        let nextLink = x["@odata.nextLink"];
        if (nextLink != undefined) {
            return Test(nextLink, options);
        }
        return groups;
    });
}

async function FetchGroups(url, options) {
    return fetch(url, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}