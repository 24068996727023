import { Fragment, useEffect, useState } from "react";
import { GetConfig, SetConfig, GetLicense } from "../../../ApiClients/AssistClient";
import CardWithList from "../../../Components/CardWithList";
import ToggleButton from "../../../Components/Toggels";
import RadioButtons from "../../../Components/RadioButtons";
import NotificationBar from "../../../Components/NotificationBar";
import Dropdown from "../../../Components/Input/Dropdown";
import PageHeader from "../../../Components/PageHeader";
import { CheckIcon, ExclamationIcon } from "@heroicons/react/solid";
import Chip from "../../../Components/Chip"
import Lock from "../../../Components/Lock"

export default function Settings() {

    let defaultConfig = {
        activateColorContrastCheck: {},
        autoShowSidepanel: {},
        contrastColorStandardRequirements: {},
        isPdfaCompatible: {},
        setBitmapMissingFonts: {},
        showGroupsInSidepanel: {},
        showSaveAsPDFButton: {},
        showSaveAsHtmlButton: {},
        usersGroupName: {},
        validateDocumentBeforeSavingPDF: {},
        validateDocumentCompatibility: {},
        validateHiddenContent: {},
        validateLinkDisplayText: {},
        validateLinkToolTip: {},
        hideEmptyParagraph: {},
        pdfUA: {},
        pdfEngine: {},
        showOnHomeTabIfDtIsInstalled: {},
        requireTableHeaderPresent: {}
    }

    const [config, setConfig] = useState(defaultConfig);
    const [license, setLicense] = useState({});
    const [savingStatus, setSavingStatus] = useState("noChanges");
    const [focus, setFocus] = useState("");

    useEffect(() => {
        GetConfig().then((response) => {
            setConfig(response);
        });
        GetLicense().then((response) => {
            setLicense(response);
        })
    }, []);

    const onChange = (propName, value) => {
        updateConfigProperty(propName, value);

        SetConfig(config).then((response) => {
            setSavingStatus("saved");
        });
    };
    let timeoutId = null;

    const clearTimer = () => {
        clearTimeout(timeoutId);
        timeoutId = null;
    };

    const updateConfigProperty = (propName, value) => {
        //Ensure the input is keeping focus
        setFocus(propName);

        //Update config in state
        config[propName].value = value;
        setConfig(config);

        //Set saving status
        setSavingStatus("saving");
    };

    const saveTextChange = (propName, value) => {
        //Clear existing timer
        clearTimer();

        updateConfigProperty(propName, value.target.value);

        //Create timer for saving in 2 sek
        timeoutId = setTimeout(function () {
            clearTimer();
            SetConfig(config).then((response) => {
                if (timeoutId === null) {
                    setSavingStatus("saved");
                }
            });
        }, 2000);
    };

    const checkForLicense = (needed) => {
        return needed === 100 && license.licenseType !== 100;
    }

    const CreateToggleButton = (propName, headLine, description, disabled = false) => {
        return <Lock locked={checkForLicense(config[propName].licenseTypeNeed)} element={<ToggleButton active={config[propName].value} onChange={(value) => onChange(propName, value)} headLine={headLine} description={description} proFeature={config[propName].licenseTypeNeed === 100} hideButton={checkForLicense(config[propName].licenseTypeNeed)} disabled={disabled} badges={config[propName].badges} proStatus={config["pdfEngine"].value === 1 && license.licenseType === 100} ></ToggleButton>} />;
    };

    const GetProBadge = () => {
        return <Chip text="Pro" color="dania" />;
    }

    const CreateDropdown = (propName, headLine, description, options) => {
        return <Lock locked={checkForLicense(config[propName].licenseTypeNeed)} element={<Dropdown id={propName} headLine={headLine} description={description} defaultValue={config[propName].value} options={options} onChange={(e) => ChangeDropDownHandler(e, propName)} proFeature={config[propName].licenseTypeNeed === 100} disabled={checkForLicense(config[propName].licenseTypeNeed)} badges={config[propName].badges} proStatus={config["pdfEngine"].value === 1 && license.licenseType === 100} />} />;
    };

    const GeneralSettings = () => {
        let items = [
            CreateToggleButton("autoShowSidepanel", "Auto show sidepanel", "Turn on to always show the Assist" + (license.licenseType == 100 ? " Pro" : "") +" sidepanel when users boot Microsoft Excel, PowerPoint and Word."),
            <div className="flex items-center justify-between  large-phone:flex-col large-phone:items-start">
                <div className="flex-grow flex flex-col pr-10">
                    <div className="text-sm font-medium text-gray-900">Limit the use of Assist{(license.licenseType == 100 ? " Pro" : "")}</div>

                    <div className="text-sm text-gray-500">Enter the name of an Active Directory Group to limit who has access to Assist{(license.licenseType == 100 ? " Pro" : "")}. Leave blank to give everyone access.</div>
                    <span>
                        {config["usersGroupName"].badges != undefined ?
                            config["usersGroupName"].badges.map((b) => (
                                (b.hideOnPro && config["pdfEngine"].value === 1 && license.licenseType === 100) ?
                                    ""
                                    :
                                    <Chip text={b.name} color={b.color} marginRightTailWind="mr-1.5" />
                            ))
                            :
                            ""}
                    </span>
                </div>
                <div className="w-96 ">
                    <div className="m-1 large-phone:m-0 large-phone:mt-3 ">
                        <input
                            autoFocus={focus === "usersGroupName"}
                            key="inputField"
                            defaultValue={config["usersGroupName"].value}
                            onChange={(e) => saveTextChange("usersGroupName", e)}
                            type="text"
                            className="shadow p-2 focus:ring-dania-500 focus:border-dania-500 focus:border-3 block w-full sm:text-sm border border-gray-300 rounded-md"
                            placeholder="Everyone has access"
                            aria-describedby="Everyone has access"
                        />
                    </div>
                </div>
                
            </div>,
            CreateToggleButton("showOnHomeTabIfDtIsInstalled", "Show on Home-tab", "Enable this option to show an Assist" + (license.licenseType == 100 ? " Pro" : "") +"-group directly in the Home-tab, if DynamicTemplate is installed."),
        ];

        return <CardWithList disableTransition={true} headLine={"General settings"} items={items}></CardWithList>;
    };

    function ChangeDropDownHandler(e, propName) {
        onChange(propName, parseInt(e.target.value))
    }

    const SaveSettings = () => {
        let items = [
            CreateToggleButton("setBitmapMissingFonts", "Embed non-standard fonts", "Using special or non standard fonts can prove an issue, when the receiver does not have access to these. Enable this option to automatically embed non-standard fonts."),
            CreateToggleButton("isPdfaCompatible", "PDF/A Compatibility", "PDF/A differs from the standard PDF by disallowing features that can hinder long-term preservation, such as font linking, thus enhancing accessibility."),
            CreateToggleButton("showSaveAsPDFButton", "Save as PDF", "Enable this option to show a button directly in the Assist" + (license.licenseType == 100 ? " Pro" : "") +" sidepanel, that allows users to save as a PDF."),
            CreateDropdown("pdfUA", "Save as PDF/UA", "PDF/UA certifies that the document is User Accessible.", [{ "name": "Disable", "value": "0" }, { "name": "Enable", "value": "1" }, { "name": "Optional", "value": "2" }]),
            CreateDropdown("pdfEngine", "PDF engine", "Choose the engine for creating PDF documents. Standard is Office's built-in PDF engine.", [{ "name": "Standard", "value": "0" }, { "name": "Pro", "value": "1" }]),
            CreateToggleButton("showSaveAsHtmlButton", "Save as HTML", "Enable this option to show a button directly in the Assist" + (license.licenseType == 100 ? " Pro" : "") +" sidepanel, that allows users to save as a HMTL."),
        ];

        return <CardWithList disableTransition={true} headLine={"Save settings"} items={items}></CardWithList>;
    };
    const AuditSettings = () => {
        let items = [
            CreateToggleButton("activateColorContrastCheck", "Check for colour contrast", "Enable this option to allow Assist" + (license.licenseType == 100 ? " Pro" : "") +" to scan for colour contrast ratios. If any colour contrasts are below the recommended limit, users will be alerted in the audit section."),
            <ContrastRadioGroup disabled={!config.activateColorContrastCheck.value} />,
            CreateToggleButton("showGroupsInSidepanel", "Group shapes in audits", "Enable this option to group shapes in the list of audits."),
            CreateToggleButton("validateDocumentBeforeSavingPDF", "Validate document before saving as a PDF or as HTML", "Automatically run one last audit before the document is saved as a PDF or as HTML. Users will be alerted if any accessibility issues are found."),
            CreateToggleButton("validateDocumentCompatibility", "Validate document compatibility", "Make sure that the document is saved in a valid file format."),
            CreateToggleButton("validateHiddenContent", "Scan for hidden content", "Scan for hidden content, such as comments and track changes."),
            CreateToggleButton("validateLinkDisplayText", "Scan for text to display in links", "Make sure that links have the necessary text to display."),
            CreateToggleButton("validateLinkToolTip", "Scan for link tooltips", "Scan for tooltips in links, to make sure none are missing."),
            CreateDropdown("hideEmptyParagraph", "Scan for empty paragraphs", "Enable this option to allow Assist Pro to scan for empty paragraphs in your documents.", [{ "name": "Disabled", "value": "0" }, { "name": "Enable (Warning)", "value": "1" }, { "name": "Enabled (Error)", "value": "2" }]),
            CreateToggleButton("requireTableHeaderPresent", "Require table headers", "Enable this option to require tables to contain at least 1 column or row header.")
        ];

        return <CardWithList disableTransition={true} headLine={"Audit settings"} items={items}></CardWithList>;
    };

    const ContrastItems = [
        { name: "AAA", description: "The highest standard. Normal text must have at least a 7:1 ratio and large text a 4.5:1 ratio.", selected: config.contrastColorStandardRequirements.value === "AAA" },
        { name: "AA", description: "Acceptable to comply with WCAG. Normal text must have at least a 4.5:1 ratio and large text a 3:1 ratio.", selected: config.contrastColorStandardRequirements.value === "AA" },
    ];

    const ContrastRadioGroup = (props) => {
        return (
            <div>
                <div className={`${props.disabled ? "mb-5" : "hidden"}`}>
                    <NotificationBar icon={ExclamationIcon} color="yellow" text="Attention: To enable this option, you first need to turn on: Check for colour contrasts." />
                </div>
                <div disabled={props.disabled} className={`${props.disabled ? "cursor-not-allowed disabled:opacity-50 " : ""}`}>
                    <div className="mb-3">
                        <div className={`${props.disabled ? "cursor-not-allowed opacity-50 " : ""} text-sm font-medium text-gray-900 `}>Level of colour contrast check</div>
                        <div className={`${props.disabled ? "cursor-not-allowed opacity-50 " : ""} text-sm text-gray-500 `}>Choose which level of colour contrast Assist{(license.licenseType == 100 ? " Pro" : "")} should scan for. Be aware that it is required to turn on "Check for colour contrasts" for this setting to be enabled.</div>
                    </div>
                    <RadioButtons disabled={props.disabled} className="disabled:opacity-50" onChange={(e) => onChange("contrastColorStandardRequirements", e.name)} settings={ContrastItems} selected={config.contrastColorStandardRequirements.value} />
                    <span>
                        {config.activateColorContrastCheck.badges != undefined ?
                            config.activateColorContrastCheck.badges.map((b) => (
                                (b.hideOnPro && config.pdfEngine.value === 1 && license.licenseType === 100) ?
                                    ""
                                    :
                                    <Chip text={b.name} color={b.color} marginRightTailWind="mr-1.5" />
                            ))
                            :
                            ""}
                    </span>
                </div>
            </div>
        );
    };

    const Notification = () => {
        switch (savingStatus) {
            case "noChanges":
                return <NotificationBar text="Changes are saved automatically" />;
            case "saving":
                return <NotificationBar color="yellow" text="Saving" />;
            case "saved":
                return <NotificationBar icon={CheckIcon} color="green" text="Changes have been saved" />;
            default:
        }
    };

    return (
        <Fragment>
            <PageHeader headLine={"Configure Assist" + (license.licenseType == 100 ? " Pro" : "")} />

            <div className="mb-5 ml-1">
                <Notification />
            </div>
            <GeneralSettings />
            <SaveSettings />
            <AuditSettings />
        </Fragment>
    );
}
