import SubMenu from "./Submenu";
import SlideInLeft from "./SlideInLeft";
import { ChevronDoubleRightIcon } from '@heroicons/react/outline'
import { useState } from 'react'
import { ArrowNarrowLeftIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom"
export default function SubmenuLayout(props) {
    const [showSlideIn, setShowSlideIn] = useState(false)

    const subMenu = <SubMenu activeComponent={props.contentComponent} navigation={props.navigation} backUrl={props.backUrl}
        onNavigation={(e) => {
            setShowSlideIn(false);
            props.onNavigation(e);
        }}
    />;

    return (
        <div className="flex flex-row h-full w-full">

            {/* Mobile sub menu*/}
            <SlideInLeft open={showSlideIn} setOpen={() => setShowSlideIn(false)} header={props.product} content={subMenu} direction="left" />
            <div className="lg:hidden h-screen flex items-center fixed left-0 order-first p-1 border-r border-gray-200">
                <button type="button"
                    className="inline-flex items-center p-1 border border-transparent text-xs font-medium rounded text-dania-500 bg-dania-100 hover:bg-dania-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dania-500"
                    onClick={() => setShowSlideIn(true)} >
                    <ChevronDoubleRightIcon className="h-3 w-3" />
                </button>
            </div>

            {/* Desktop sub menu*/}
            <div className="hidden lg:block min-w-max w-80 pr-5 border-r overflow-y-auto pl-1">
                <div className="flex flex-row items-center pb-3 border-b border-gray-200">
                    <Link to={props.backUrl}>	<ArrowNarrowLeftIcon className="h-5 text-gray-400 py-0.5 px-3 hover:text-gray-500" aria-hidden="true"></ArrowNarrowLeftIcon></Link>

                    <div className="text-lg leading-9 font-bold text-gray-900">{props.product}</div>
                </div>
                {subMenu}
            </div>
            <div className="w-full ml-8 lg:m-0 lg:ml-5 pr-6 overflow-y-auto">
                {props.contentComponent}
            </div>
        </div>
    );
}
