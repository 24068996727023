/* This example requires Tailwind CSS v2.0+ */
import { useHistory } from "react-router-dom";
import ModalDialog from "../ModalDialog";
import React, { useState } from "react";

export default function ProductLinker({ product, Button }) {
    let navigate = useHistory();
    const [dialog, setDialog] = useState(product.dialog);

    const onClick = async (product) => {
        
        if (dialog) {
            setDialog(prevState => ({ ...prevState, show: true }));
        } else {
            doAction(product);
        }
    }

    const doAction = async (product) => {
        
        if (product.customAction) {
            //Execture custom actioen and then navigate to destination
            product.customAction().then(r => navigate.push(product.navigationUrl)).catch(error => console.log("Unable to execute custom action"));
        }
        else {
            //Navigate to destination
            navigate.push(product.navigationUrl);
        }
    }

    function closeDialog() {
        setDialog(prevState => ({ ...prevState, show: false }));
    }

    return (
        <div>
            {dialog ? <ModalDialog iconColor={dialog.iconColor} title={dialog.title} text={dialog.text} actionButtonAction={() => { closeDialog(); doAction(product); }} actionButtonColor={dialog.actionButtonColor} actionButtonText={dialog.actionButtonText} show={dialog.show} close={() => { closeDialog() }} /> : null}
            <Button onClickAction={() => onClick(product)} />
        </div>
    );
}
