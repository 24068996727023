/* This example requires Tailwind CSS v2.0+ */
import { useState } from "react";
import { Switch } from "@headlessui/react";
import Chip from "../Components/Chip"

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

export default function ToggleButton(props) {
    const [enabled, setEnabled] = useState(props.active);

    const onChange = (e) => {
        setEnabled(!enabled);
        if (props.onChange) {
            props.onChange(!enabled)
        }

    };

    return (
        <Switch.Group as="div" className={`${props.disabled ? "cursor-not-allowed opacity-50 " : ""} flex items-center justify-between`}>
            <span className="flex-grow flex flex-col pr-10">
                <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
                    {props.headLine} {props.proFeature ? <Chip text="Pro" color="dania" /> : null}
                </Switch.Label>
                <Switch.Description as="span" className="text-sm text-gray-500">
                    {props.description}
                </Switch.Description>
                <span>
                    {props.badges != undefined ?
                        props.badges.map((b) => (
                            (b.hideOnPro && props.proStatus) ?
                                ""
                                :
                                <Chip text={b.name} color={b.color} marginRightTailWind="mr-1.5" />
                        ))
                        :
                        ""}
                </span>
            </span>

            {!props.hideButton ?
                <Switch
                    disabled={props.disabled}
                    checked={enabled}
                    onChange={onChange}
                    className={classNames(
                        enabled ? "bg-dania-600" : "bg-gray-200",
                        props.disabled ? "cursor-not-allowed" : "cursor-pointer",
                        "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dania-500"
                    )}
                >
                    <span aria-hidden="true" className={classNames(enabled ? "translate-x-5" : "translate-x-0", "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200")} />
                </Switch>
                : null}
        </Switch.Group>
    );
}
