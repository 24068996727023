import { LinkIcon } from "@heroicons/react/outline";
import { CheckCircleIcon, CheckIcon, ExclamationCircleIcon, PencilIcon, TrashIcon } from "@heroicons/react/solid";
import React, {  useEffect, useState } from "react";
import { GetClientId, GetLicense, UpdateAdConnectedStatus } from "../../../ApiClients/OutlookSignatureCloudClient";
import PageHeader from "../../../Components/PageHeader";
import NotificationBarWithTransition from "../../../Components/NotificationBarWithTransition";
import { Transition } from "@headlessui/react";


export default function Settings() {

    const [notificationBar, setNotificationBar] = useState({ text: "", color: "", show: false, icon: CheckCircleIcon });
    const [isAdConnected, setIsAdConnted] = useState(false);
    const [loading, setloading] = useState(true);
    var newStateId = Math.floor(Math.random() * 10000) + 1;

    useEffect(() => {

        async function loadsettings() {


            const params = new URLSearchParams(window.location.search);
            if (params.has("admin_consent")) {

                var status = params.get("admin_consent");
                var state = params.get("state");

                if (status && validState(state) && (status === true || status === 'True')) {
                    await UpdateAdConnectedStatus(true);
                    setIsAdConnted(true);
                    clearState()                    
                }
            }

            if (params.has("error")) {
                var state = params.get("state");

                if (validState(state)) {
                    setNotificationBar({ text: "Unable to connect to Active Directory", color: "red", show: true, icon: ExclamationCircleIcon });
                    new Promise(x => setTimeout(x, 5000)).then(() => setNotificationBar(prevState => ({ ...prevState, show: false })));
                    clearState();
                }
            }

            if (!isAdConnected) {
                var data = await GetLicense()
                setIsAdConnted(data.adConnected);
            }

            setloading(false);
        }

        loadsettings();

    }, []);

    function validState(stateId) {
        return stateId === sessionStorage.getItem("consentState")
    }
    function clearState() {
        sessionStorage.removeItem("consentState")
    }

    function setState(stateId) {
        sessionStorage.setItem("consentState", stateId);
    }

    function notficationUpdate(text, color) {
        setNotificationBar({ text: text, color: color, show: true, icon: CheckCircleIcon });
        new Promise(x => setTimeout(x, 5000)).then(() => setNotificationBar(prevState => ({ ...prevState, show: false })));
    }

    function linkAdClicked() {
        GetClientId().then(x => {
            setState(newStateId);
            var returnurl = window.location.origin.toLowerCase() + window.location.pathname.toLowerCase() + '?tab=Settings'
            var uri = `https://login.microsoftonline.com/common/adminconsent?state=${newStateId}&client_id=${x.clientId}&redirect_uri=${returnurl}`
            window.location.href = uri
        });
    }


    function unlinkAdClicked() {
        UpdateAdConnectedStatus(false).then(x => console.log(x))
        setIsAdConnted(false);
        notficationUpdate("Success! Active Directory has been unlinked", "green");
    }

    function LoadingPlaceholder() {
        return <div className="mb-5 mt-5">
            <div className="border-dashed animate-pulse border-gray-400 border-2 text-center py-10 px-5 rounded-lg ">

                <div class=" flex space-x-4">

                    <div class="flex-1 space-y-6 py-1">
                        <div class="h-2 bg-gray-500 rounded"></div>
                        <div class="space-y-3">
                            <div class="grid grid-cols-3 gap-4">
                                <div class="h-2 bg-gray-500 rounded col-span-2"></div>
                                <div class="h-2 bg-gray-500 rounded col-span-1"></div>
                            </div>
                            <div class="h-2 bg-gray-500 rounded"></div>
                        </div>
                    </div>
                </div>

                <button type="button" className="inline-flex mt-4 items-center px-4 py-2 border border-transparent text-sm rounded-md shadow-sm text-gray-700 bg-gray-100 font-medium" onClick={() => { linkAdClicked() }}>

                    <div className="h-2 bg-gray-500 rounded w-16"></div>

                </button>
            </div>
        </div>
    }

    function LinkAdBox() {

        return (
            <Transition
                appear={true}
                show={true}
                enter="transition-opacity duration-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                {isAdConnected ?
                    <div className="mb-5 mt-5 ">
                        <div className="bg-white ml-1 shadow h-max rounded-lg p-10 h-full flex flex-col">
                            <div className="flex-none rounded bg-green-100 text-green-800 w-9 h-9 flex items-center"><CheckIcon className="h-6 w-6 m-auto" /></div>
                            <div className="mb-5 mt-5"><div className="text-gray-900 text-lg font-semibold">Active Directory</div></div>
                            <div className="text-gray-500 mb-4 flex-1"><span className="text-base">Your organisation's Azure Active Directory has been linked and is now active.</span></div>
                            <div className="flex gap-4">
                                <button type="button" className="inline-flex items-center px-4 py-2 border border-transparent text-sm rounded-md shadow-sm text-dania-500 bg-dania-100 w-min font-medium" onClick={() => { window.location.href = "?tab=CreateAndManage"; }}>
                                    <PencilIcon className="h-4 w-4 mr-3" />
                                    Create
                                </button>
                                <button type="button" className="inline-flex items-center px-4 py-2 border border-transparent text-sm rounded-md shadow-sm text-gray-500 bg-white w-min font-medium" onClick={() => { unlinkAdClicked() }}>
                                    <TrashIcon className="h-4 w-4 mr-3" />
                                    <span>Unlink</span>
                                </button>
                            </div>
                        </div>
                    </div >
                :
                <div className="mb-5 mt-5">
                    <div className="border-dashed border-gray-400 border-2 text-center py-10 px-5 rounded-lg ">
                        <div className="rounded bg-dania-100 text-dania-700 w-10 h-10 text-center align-middle m-auto"><LinkIcon className="m-auto text-dania-500 w-8 pt-1" /></div>
                        <div className="mb-5 mt-5"><div className="text-gray-900 text-lg font-semibold">Connect Active Directory</div></div>
                        <div className="text-gray-500"><span className="text-base">Before you can start creating signatures, you need to connect SignatureCloud to your organisation's Azure Active Directory.</span></div>
                        <button type="button" className="inline-flex mt-4 items-center px-4 py-2 border border-transparent text-sm rounded-md shadow-sm text-dania-500 bg-dania-100 font-medium" onClick={() => { linkAdClicked() }}>

                            Grant consent
                        </button>
                    </div>
                </div>
                }
                
            </Transition>
        )
    }

    return (
        <div>
            <PageHeader headLine="Settings"></PageHeader>
            
            <NotificationBarWithTransition show={notificationBar.show} color={notificationBar.color} text={notificationBar.text} Icon={notificationBar.icon} />
            <Transition
                appear={true}
                show={true}
                enter="transition-opacity duration-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="pb-3 mb-5"><div className="text-gray-900 text-lg font-semibold">Connect your Active Directory</div></div>
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 border-b border-gray-200">
                    {
                        loading ? <LoadingPlaceholder /> : <LinkAdBox />
                    }
                </div>
            </Transition>
        </div>
    );
}