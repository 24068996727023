import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Layout from "./Components/Layout";
import DynamicTemplateMain from "./Views/Products/DynamicTemplate/Main";
import DynamicTemplateWebMain from "./Views/Products/DynamicTemplateWeb/Main";
import AssistMain from "./Views/Products/Assist/Main";
import Home from "./Views/Home";
import TeamMain from "./Views/Team/Main";
import Products from "./Views/Products";
import AuthorizeRoute from "./api-authorization/AuthorizeRoute";
import { ApplicationPaths } from "./api-authorization/ApiAuthorizationConstants";
import AuthorizeService from "./api-authorization/AuthorizeService";
import ApiAuthorizationRoutes from "./api-authorization/ApiAuthorizationRoutes";
import Help from "./Views/Help";
import Privacy from "./Views/Privacy";
import OutlookSignatureMain from "./Views/Products/OutlookSignature/Main";
import OutlookSignatureCloudMain from "./Views/Products/OutlookSignatureCloud/Main";
import Account from "./Views/Account";
import Logo from "../src/Assets/dynamictemplatecubes.svg";

function App() {

    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const [isAuthFlow, setIsAuthFlow] = useState(false);

    useEffect(() => {
        let authFlow = window.location.pathname.split("/").filter(path => "/" + path == ApplicationPaths.ApiAuthorizationPrefix).length > 0;
        setIsAuthFlow(authFlow);
        if (!userLoggedIn && !authFlow) {
            AuthorizeService.isAuthenticated().then((authResponse) => {
                if (authResponse) {
                    setUserLoggedIn(true);
                }
                else {
                    AuthorizeService.signIn()
                        .then(response => setUserLoggedIn(true))
                        .catch(error => setUserLoggedIn(false));
                }
            });
        }
    }, [userLoggedIn])

    return (
        (userLoggedIn || isAuthFlow) ?
            <div className="h-screen">
                <Router>
                    <Switch>
                        <Route
                            path={ApplicationPaths.ApiAuthorizationPrefix}
                            component={ApiAuthorizationRoutes}
                        />
                        <AuthorizeRoute
                            path="/products/dynamictemplate"
                            rolsRequired="DynamicTemplate"
                            component={(props) => (
                                <Layout active="/products" data={DynamicTemplateMain}></Layout>
                            )}
                        />
                        <AuthorizeRoute
                            path="/products/dynamictemplateweb"
                            rolsRequired="DynamicTemplateWeb"
                            component={(props) => (
                                <Layout active="/products" data={DynamicTemplateWebMain}></Layout>
                            )}
                        />
                        <AuthorizeRoute
                            path="/products/assist"
                            rolsRequired="Assist"
                            component={(props) => (
                                <Layout active="/products" data={AssistMain}></Layout>
                            )}
                        />
                        <AuthorizeRoute
                            path="/products/OutlookSignature"
                            component={(props) => (
                                <Layout active="/products" data={OutlookSignatureMain}></Layout>
                            )}
                        />
                        <AuthorizeRoute
                            path="/products/SignatureCloud"
                            rolsRequired="SignatureCloud"
                            component={(props) => (
                                <Layout active="/products" data={OutlookSignatureCloudMain}></Layout>
                            )}
                        />
                        <AuthorizeRoute
                            path="/products"
                            component={(props) => (
                                <Layout {...props} active="/products" data={Products}></Layout>
                            )}
                        />
                        <AuthorizeRoute
                            path="/home"
                            component={(props) => <Layout active="/home" data={Home}></Layout>}
                        />
                        <AuthorizeRoute
                            path="/team"
                            component={(props) => (
                                <Layout active="/team" data={TeamMain}></Layout>
                            )}
                        />
                        <AuthorizeRoute
                            path="/help"
                            component={(props) => <Layout active="/help" data={Help}></Layout>}
                        />
                        <AuthorizeRoute
                            path="/privacy"
                            component={(props) => (
                                <Layout active="/privacy" data={Privacy}></Layout>
                            )}
                        />
                        <AuthorizeRoute
                            path="/Account"
                            component={(props) => (
                                <Layout active="/account" data={Account}></Layout>
                            )}
                        />
                        <AuthorizeRoute
                            path="/"
                            component={(props) => <Layout active="/home" data={Home}></Layout>}
                        />
                    </Switch>
                </Router>
            </div> :
            <div className="h-screen w-screen flex items-center justify-center">
                <div className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200 animate-pulse">
                    <div className="flex-1 flex flex-col p-8">
                        <img className="w-24 h-24 flex-shrink-0 mx-auto" src={Logo} alt="Logo" />
                        <h3 className="mt-6 text-gray-900 text-sm font-medium">Redirecting</h3>
                        <dl className="mt-1 flex-grow flex flex-col justify-between">
                            <dd className="text-gray-500 text-sm">Loading...</dd>
                        </dl>
                    </div>
                </div>
            </div>
    );
}

export default App;
