export default function ProductListLoading() {
	return (
		<ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
			<li className="col-span-1 w-80 bg-white rounded-lg shadow divide-y divide-gray-200">
				<div className="w-full animate-pulse  flex items-center justify-between p-6 space-x-6">
					<div className="flex-1 truncate">
						<div className="h-2 bg-gray-400 rounded mb-6"></div>
						<div className="h-2 bg-gray-400 rounded w-5/6"></div>
					</div>
				</div>
				<div>
					<div className="flex  animate-pulse items-center justify-center  ">
						<span href="#" className="flex-1 flex-shrink inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500">
							<div className="h-2 bg-gray-400 rounded w-5/6"></div>
						</span>
					</div>
				</div>
			</li>			
		</ul>
	);
}
