export default function CheckBox({ label, id, value, checked, onChange, isDisabled }) {
    const getInputStyle = () => {
        return !isDisabled ? "form-check-input appearance-none h-4 w-4 border border-dania-300 rounded-sm bg-white checked:bg-dania-500 checked:border-dania-500 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer" : "text-gray-400 form-check-input appearance-none h-4 w-4 border border-dania-300 rounded-sm bg-white checked:bg-dania-500 checked:border-dania-500 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2";
    };

    const getLabelStyle = () => {
        return !isDisabled ? "form-check-label inline-block text-gray-800 cursor-pointer font-sm" : "form-check-label inline-block text-gray-800 font-sm";
    };

    function handleClick(e) {
        onChange(!checked);
    }

    return (
        <div className="form-check">
            <input className={getInputStyle()} type="checkbox" value={value} id={"checkbox" + id} checked={checked} onChange={handleClick} disabled={isDisabled} />
            <label className={getLabelStyle()} htmlFor={"checkbox" + id}>
                {label}
            </label>
        </div>
    );
}