import { Transition } from "@headlessui/react";
import Card from "./Card";

export default function CardWithList(props) {

    var itemKey = 1;
    const content = <div>

        {props.disableTransition ?
           <> { props.items ? props.items.map((item) => <CreateRow key={itemKey++} item={item} />) : null } </>:
            <Transition
                appear={true}
                show={true}
                enter="transition-opacity duration-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                {props.items ? props.items.map((item) => <CreateRow key={itemKey++} item={item} />) : null}
            </Transition>
        }
    </div>

    function CreateRow(props) {
        return (
            props.item ?
                <div className="block w-full border-gray-200 border-b">
                    <div className="flex items-center px-4 py-4 sm:px-6">
                        <div className="w-full flex-1 flex items-center">
                            <div className="flex-1">
                                {props.item}
                            </div>
                        </div>
                    </div>
                </div>
                : null
        );
    }

    function CreateLoadingRows() {
        return (
            <div>
                <CreateRow item={CreateLoadingContent()} />
                <CreateRow item={CreateLoadingContent()} />
                <CreateRow item={CreateLoadingContent()} />
            </div>
        );
    }

    function CreateLoadingContent() {
        return (
            <div className="min-w-0 animate-pulse flex-1">
                <div>
                    <div>
                        <span className="text-sm font-medium text-gray-900 truncate mr-2">
                            <div className="h-2 bg-gray-400 rounded mb-2"></div>
                        </span>
                        <div className="h-2 bg-gray-400 rounded mb-6"></div>
                    </div>
                </div>
            </div>
        );
    }

    if (props.items !== undefined && props.items.length > 0)
        return (
            <Card headLine={props.headLine} content={content} disableTransition={props.disableTransition ? true : false} button={props.button} />
        );
    else
        // Loading 
        return (
            <Card headLine={props.headLine} content={CreateLoadingRows()} />
        );
}
