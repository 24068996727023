/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { useState } from "react";
import CountryDropdown from "../Input/CountryDropdown";
import InfoHeader from "./InfoHeader";

export default function TeamInfo(props) {
    const [teamInfo] = useState(props.teamInfo);

    function ChangeHandler(e, propName) {
        teamInfo[propName] = e.target.value;
    }

    return (
        <>
            <InfoHeader header="Create your team" >
                A team is the company / organisation you are working for. Software licenses and trials are issued to teams not individual accounts.
                You are the first from <b>{teamInfo.name}</b> to sign up, and will therefore become the administrator of the team.
            </InfoHeader>
            <div className="mt-5 md:mt-0 md:col-span-2">
                <div className="py-5 bg-white">
                    <div className="grid grid-cols-2 gap-6">

                        <div className="col-span-2 sm:col-span-1">
                            <label htmlFor="team-name" className="block text-sm font-medium text-gray-700">
                                Team name
                            </label>
                            <input
                                type="text"
                                name="team-name"
                                id="team-name"
                                className="mt-1 focus:ring-dania-500 focus:border-dania-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md bg-gray-200"
                                value={teamInfo.name}
                                readOnly
                            />
                        </div>

                        <div className="col-span-2 sm:col-span-1">
                            <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                                Country
                            </label>
                            <CountryDropdown id="country" name="country" autoComplete="country-name" defaultValue={teamInfo.country} onChange={(e) => { ChangeHandler(e, "country") }} />
                        </div>

                        <TextBox label="Street address" fieldName="street-address" autoComplete="" propName="address" />
                        <TextBox label="City" fieldName="address-level2" autoComplete="" propName="city" />
                        <TextBox label="ZIP / Postal" fieldName="postal-code" autoComplete="" propName="postalCode" />

                    </div>
                </div>
            </div>
        </>
    )

    function TextBox(props) {
        return (
            <div className="col-span-2 sm:col-span-1">
                <label htmlFor={props.fieldName} className="block text-sm font-medium text-gray-700">
                    {props.label}
                </label>
                <input
                    required
                    type="text"
                    name={props.fieldName}
                    id={props.fieldName}
                    autoComplete={props.autoComplete}
                    className="mt-1 focus:ring-dania-500 focus:border-dania-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    onChange={(e) => { props.customOnChange ? props.customOnChange(e) : ChangeHandler(e, props.propName) }}
                />
            </div>
        );
    }

    function OptionalTextbox(props) {
        return (
            <div className="col-span-2 sm:col-span-1">
                <Optional>
                    <label htmlFor={props.fieldname} className="block text-sm font-medium text-gray-700">
                        {props.label}
                    </label>
                </Optional>

                <input
                    type="text"
                    name={props.fieldname}
                    id={props.fieldname}
                    autoComplete={props.fieldname}
                    className="mt-1 focus:ring-dania-500 focus:border-dania-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    defaultValue={teamInfo[props.propName]}
                    onChange={(e) => { ChangeHandler(e, props.propName) }}
                />
            </div>
        );
    }

    function Optional(props) {
        return (
            <div className="flex flex-row justify-between">
                {props.children}
                <div className="text-sm text-gray-400">
                    Optional
                </div>
            </div>
        );
    }
}