import { useEffect, useState } from "react";

import NotificationBar from "../../../Components/NotificationBar";
import PageHeader from "../../../Components/PageHeader";
import ActionGrid from "../../../Components/Grids/ActionGrid";
import CardWithList from "../../../Components/CardWithList";
import Button from "../../../Components/Buttons/Button";
import DownloadButton from "../../../Components/Buttons/DownloadButton";
import { GetLatestStableInstallFile, GetLicense } from "../../../ApiClients/AssistClient";
import { SupportIcon, CodeIcon, DownloadIcon, NewspaperIcon, FlagIcon, UserAddIcon, ClipboardCopyIcon } from "@heroicons/react/outline";
import Help from "./Help";
import Documentation from "./Documentation";
import Downloads from "./InstallFiles";
import ReleaseNotes from "./ReleaseNotes";
import RoadMap from "./RoadMap";
import License from "./License";
import { CopyToClipboard } from "react-copy-to-clipboard";
import authService from "../../../api-authorization/AuthorizeService";
import { ProxyUrl } from "../../../Helpers/DaniaProxy"

export default function GetStarted(props) {
    const [keyCopied, setKeyCopied] = useState(false);
    const [steps, setSteps] = useState([]);
    const [license, setLicense] = useState({});

    useEffect(() => {
        GetLatestStableInstallFile().then((installData) => {
            GetLicense().then((licenseData) => {
                setLicense(licenseData);
                let proText = licenseData.licenseType == 100 ? " Pro" : "";
                authService.isAccountOwner().then((result) => {
                   
                    var localSteps = [];

                    if (result) {
                        localSteps.push(CreateRow(
                            "Invite team members",
                            "Invite your colleagues to your team. If you do not have permission to download and install software, remember to invite IT.",
                            <Button icon={UserAddIcon} iconClasses="" text="Invite user" textColor="text-dania-500" bgColor="bg-dania-100" hoverColor="bg-dania-200" onNavigation={() => (window.location.href = "/team?tab=members")} />
                        ));
                    }

                    localSteps.push(CreateRow("Download", "Download the latest stable version of Assist" + proText + " directly.", <DownloadButton downloadUrl={installData.downloadUrl} fileName={installData.filename} />))
                    localSteps.push(CreateRow(
                        "Install",
                        <span>
                            Run the Assist{proText} installer and input your license key when prompted. Simply press the button to copy the key or find it under{" "}
                            <button className="text-dania-500 cursor-pointer" onClick={() => props.onNavigation(<License />)}>
                                License
                            </button>
                            .
                        </span>,
                        <div className="flex flex-col">
                            <div className="flex-1">
                                <CopyToClipboard text={licenseData.id} onCopy={() => setKeyCopied(true)}>
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                        }}
                                        className="  inline-flex items-center px-4 py-2 font-medium border border-transparent text-sm rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dania-500 hover:bg-dania-700 bg-dania-600 text-white group"
                                    >
                                        <ClipboardCopyIcon te className="h-4 w-4 mr-2" />
                                        <div className="relative flex flex-col items-center group ">
                                            <span className="mr-1">Copy key</span>
                                            <div className="absolute bottom-0 -ml-5 pb-1 flex-col items-center w-40 mb-6 border-1 hidden group-hover:flex ">
                                                <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-gray-500 rounded-md shadow-lg">
                                                    <span>Your key will be copied to the clipboard.</span> <br /> <span>Use "CTRL + V" to insert the key</span>
                                                </span>
                                                <div className="transform w-3 h-3 -mt-2 rotate-45 bg-gray-500"></div>
                                            </div>
                                        </div>
                                    </button>
                                </CopyToClipboard>
                            </div>
                        </div>
                    ))
                    localSteps.push(<NotificationBar icon={SupportIcon} text={"If you’re having issues, remember to whitelist the following URL's: " + ProxyUrl + ". If your issues persist, then please refer to our help section."} />);

                    setSteps(localSteps);
                })


            })
        });


    }, []);

    var rowIndex = 0;
    function CreateRow(title, text, button) {
        rowIndex++;
        return (
            <div className="flex flex-row">
                <div className="grid place-items-center min-h-full">
                    <div className="rounded-md h-10 w-10 bg-dania-50 p-2 text-dania-500 font-medium text-lg mr-5 pt-1 text-center border">{(rowIndex < 10 ? "0" : "") + rowIndex}</div>
                </div>
                <div className="flex flex-col flex-1">
                    <div className="text-lg font-medium">{title}</div>
                    <div className="text-sm">{text}</div>
                </div>
                <div className="flex items-center">{button}</div>
            </div>
        );
    }

    //if (data.license && data.installFile) {
    //    var installFile = data.installFile;
    //    var license = data.license;

    //    authService.isAccountOwner().then((result) => {

    //        var localSteps = [];

    //        if (result) {
    //            localSteps.push(CreateRow(
    //                "Invite team members",
    //                "Invite your colleagues to your team. If you do not have permission to download and install software, remember to invite IT.",
    //                <Button icon={UserAddIcon} iconClasses="" text="Invite user" textColor="text-dania-500" bgColor="bg-dania-100" hoverColor="bg-dania-200" onNavigation={() => (window.location.href = "/team?tab=members")} />
    //            ));
    //        }

    //        localSteps.push(CreateRow("Download", "Download the latest stable version of Assist directly.", <DownloadButton downloadUrl={installFile.downloadUrl} fileName={installFile.filename} />))
    //        localSteps.push(CreateRow(
    //            "Install",
    //            <span>
    //                Run the Assist installer and input your license key when prompted. Simply press the button to copy the key or find it under{" "}
    //                <button className="text-dania-500 cursor-pointer" onClick={() => props.onNavigation(<License />)}>
    //                    License
    //                </button>
    //                .
    //            </span>,
    //            <div className="flex flex-col">
    //                <div className="flex-1">
    //                    <CopyToClipboard text={license.id} onCopy={() => setKeyCopied(true)}>
    //                        <button
    //                            onClick={(e) => {
    //                                e.preventDefault();
    //                            }}
    //                            className="  inline-flex items-center px-4 py-2 font-medium border border-transparent text-sm rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dania-500 hover:bg-dania-700 bg-dania-600 text-white group"
    //                        >
    //                            <ClipboardCopyIcon te className="h-4 w-4 mr-2" />
    //                            <div className="relative flex flex-col items-center group ">
    //                                <span className="mr-1">Copy key</span>
    //                                <div className="absolute bottom-0 -ml-5 pb-1 flex-col items-center w-40 mb-6 border-1 hidden group-hover:flex ">
    //                                    <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-gray-500 rounded-md shadow-lg">
    //                                        <span>Your key will be copied to the clipboard.</span> <br /> <span>Use "CTRL + V" to insert the key</span>
    //                                    </span>
    //                                    <div className="transform w-3 h-3 -mt-2 rotate-45 bg-gray-500"></div>
    //                                </div>
    //                            </div>
    //                        </button>
    //                    </CopyToClipboard>
    //                </div>
    //            </div>
    //        ))
    //        localSteps.push(<NotificationBar icon={SupportIcon} text={"If you’re having issues, remember to whitelist the following URL: " + whitelistUrl + ". If your issues persist, then please refer to our help section."} />);

    //        setSteps(localSteps);
    //    })
    //}

    const actions = [
        {
            title: "Help",
            text: "Having issues? Visit our help section to get your issues solved.",
            onClick: () => props.onNavigation(<Help />),
            icon: SupportIcon,
            iconForeground: "text-green-700",
            iconBackground: "bg-green-50",
        },
        {
            title: "Documentation",
            text: "Read the documentation for Assist" + (license.licenseType == 100 ? " Pro" : "") +", to better understand its features.",
            onClick: () => props.onNavigation(<Documentation />),
            icon: CodeIcon,
            iconForeground: "text-purple-700",
            iconBackground: "bg-purple-50",
        },
        {
            title: "Downloads",
            text: "Visit the download section to download older versions of Assist" + (license.licenseType == 100 ? " Pro" : "") +".",
            linkText: "Software",
            onClick: () => props.onNavigation(<Downloads />),
            icon: DownloadIcon,
            iconForeground: "text-dania-700",
            iconBackground: "bg-dania-50",
        },
        {
            title: "Release notes",
            text: "Read the latest release notes, to see what's new.",
            onClick: () => props.onNavigation(<ReleaseNotes />),
            icon: NewspaperIcon,
            iconForeground: "text-red-700",
            iconBackground: "bg-red-50",
        },
        {
            title: "Roadmap",
            text: "Check out our roadmap to see upcoming features.",
            onClick: () => props.onNavigation(<RoadMap />),
            icon: FlagIcon,
            iconForeground: "text-blue-700",
            iconBackground: "bg-blue-50",
        },
    ];

    const KeyStatus = () => {
        return keyCopied ? (
            <div className="mb-3">
                <NotificationBar color="green" text={"Your key has been copied to the clipboard. Use 'CTRL + V' to insert the key."} />
            </div>
        ) : null;
    };

    return (
        <div>
            <PageHeader headLine={"Get started with Assist" + (license.licenseType == 100 ? " Pro" : "")} />
            <KeyStatus />
            <CardWithList disableTransition={true} items={steps} headLine="Your quick start guide" />
            <ActionGrid actions={actions} />
        </div>
    );
}
