import { GetAsset, GetAssets } from "../../../ApiClients/OutlookSignatureCloudClient";
import { MinusCircleIcon, UploadIcon, FolderOpenIcon, PencilIcon, PhotographIcon, CheckIcon, UserIcon } from "@heroicons/react/outline";
import { PostFormRequest } from "../../../ApiClients/ApiClientHelper";
import { Transition } from "@headlessui/react";
import Button from "../../Buttons/Button";
import NotificationBar from "../../NotificationBar";
import Overlay from "../../Overlays/Overlay";
import React, { useState, useEffect, Fragment } from "react";
import SelectionBox from "../AssetsComponents/SelectionBox";
import TextBox from "../../Input/TextBox";
import * as graphClient from "../../Azure/Graph";

export default function ImageActionBar(props) {
    const [showDialog, setShowDialog] = useState(false);
    const [mode, setMode] = useState("upload");
    const [fileForm, setFileForm] = useState(null);
    const [asset, setAsset] = useState(null);
    const [settingsDialogError, setSettingsDialogError] = useState(null);
    const [assetList, setAssetList] = useState({ files: [], storageQuotaExceeded: false, storageQuotaMB: 0.0, storageUsedMB: 0.0 });
    const [showAssetFileList, setShowAssetFileList] = useState(false);
    const hiddenFileInput = React.useRef(null);
    const [fileSizeToBig, setFileSizeToBig] = useState(false);
    const [fileTypeNotSupported, setFileTypeNotSupported] = useState(false);
    const [showQuotaOverlay, setShowQuotaOverlay] = useState(false);
    const [profilePicURL, setProfilePicURL] = useState(null);

    useEffect(() => {
        // Load image if asset id is given
        if (props.data.assetId && props.data.assetId !== "00000000-0000-0000-0000-000000000000") {
            GetAsset(props.data.assetId)
                .then((data) => {
                    setAsset(data);
                })
                .catch((data) => console.log(data));
        }

        // Load list of assets (and quota information).
        GetAssets()
            .then((data) => {
                setAssetList(data);
            })
            .catch((data) => console.log(data));
    }, [props.data.assetId]);

    var data = props.data;
    if (!data) data = {};

    const showFileDialog = () => {
        hiddenFileInput.current.click();
    };

    const onUploadClick = () => {
        fileForm.append("description", props.data.description);
        PostFormRequest("/api/OutlookSignatureCloud/AddAsset", fileForm)
            .then((x) => x.json())
            .then((x) => {
                data.assetId = x.newAssetId;
                if (props.data.profilePicture != undefined) { props.data.profilePicture.useProfilePicture = false; }
                props.onChange(data);
                setSettingsDialogError(null);
                setShowDialog(false);
            })
            .catch((x) => {
                setSettingsDialogError("Unable to upload image");
            });
    };

    const onEditClick = (logo) => {
        setMode("edit");
        setShowDialog(true);
    };

    const OnEditSave = () => {
        props.onChange(data);
        setSettingsDialogError(null);
        setShowDialog(false);
    }

    const onLimitationChanged = (field, limits) => {
        var filteredList = props.data.limitations.filter((x) => x.fieldName !== field.name);
        var temp = { fieldName: field.name, limitTo: limits };
        filteredList.push(temp);
        props.data.limitations = filteredList;
    };

    const getLimitsListByField = (fieldName) => {
        var targetFields = props.data.limitations.filter((x) => x.fieldName === fieldName);
        var targetField = targetFields[0];
        return (targetField === undefined) ? [] : targetField.limitTo;
    };

    /*
        Save selected file reference in state and show setting overlay
    */
    const onFileInputChange = (event) => {

        const formData = new FormData();
        let _validFileExtensions = ["bmp", "jpg", "jpeg", "png", "gif", "dib", "jfif", "jpe"];
        const fileExtension = event.target.files[0].name.split('.').pop().toLowerCase();

        if (_validFileExtensions.includes(fileExtension)) {
            if (event.target.files[0].size < 5242880) {
                setFileSizeToBig(false);
                setFileTypeNotSupported(false);
                formData.append("nameusedinFormData", event.target.files[0]);
                setFileForm(formData);
                setMode("upload");
                setShowDialog(true);
            } else {
                setFileSizeToBig(true);
            }
        } else {
            setFileTypeNotSupported(true);
        }
    };

    /*
          Create element with SelectionBox for each field in dataset
    */
    const LimitSectionElement = () => {
        return props.data.default ? null : (
            <div key={props.data.id}>
                <div className="text-lg font-medium text-gray-900 mb-3"> Set limitations </div>
                <div className="text-sm  text-gray-500 w-96">
                    Choose any of the following Azure Active Directory fields to limit the asset to. If choosing multiple values, e.g. a department and a title - then the signature will be available to users that meet either one of the criteria.
                </div>
                <div className="overflow-auto max-h-52 mb-4">
                    {props.limitFields ? props.limitFields.map((x, index) => <SelectionBox key={x.id} field={x} onSelectionChange={(limitList) => onLimitationChanged(x, limitList)} limitList={getLimitsListByField(x.name)} className="max-w-1/2"></SelectionBox>) : null}
                </div>
            </div>
        );
    };

    const onDescriptionChanged = (e) => {
        props.data.description = e.target.value;
    };

    const onAssetListSelect = (asset) => {
        console.log(props.data);
        if (props.data.profilePicture != undefined)
            props.data.profilePicture.useProfilePicture = false;
        props.data.assetId = asset.id;
        props.onChange(props.data);
    };

    function getQuotaOverlay() {
        return <>
            <div className="relative" show onMouseEnter={() => { setShowQuotaOverlay(!showQuotaOverlay) }} onMouseLeave={() => { setShowQuotaOverlay(!showQuotaOverlay) }}>
                <div className="flex items-center justify-center">
                    <label>
                        <div className="flex items-center">
                            <UploadIcon className="h-4 w-4 text-gray-300 cursor-not-allowed" aria-hidden="true" />
                        </div>
                    </label>
                </div>

                <Transition
                    show={showQuotaOverlay}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <div className="absolute text-left rounded-md shadow-xl drop-shadow-xl border border-1 border-gray-200 -ml-5 bg-white z-40 pt-2 pb-2">
                        <div className="text-sm font-normal text-gray-900 flex items-center p-2 rounded-lg">
                            <div className="ml-2 w-36">
                                <p className="font-semibold">Quota exceeded</p>
                            </div>
                        </div>
                        <div className="ml-2 text-sm font-normal text-gray-900 flex items-center p-2 rounded-lg">
                            <p>You are using {assetList.storageUsedMB} MB of the allowed {assetList.storageQuotaMB} MB.</p>
                        </div>
                        <div className="ml-2 text-sm font-normal text-gray-900 flex items-center p-2 rounded-lg">
                            <p>Please remove some assets and try again.</p>
                        </div>
                    </div>
                </Transition>
            </div>
        </>
    }

    const AssetFileListElement = () => {
        return showAssetFileList ? (
            <div className="no-flex relative flex-1">
                <div className="w-full min-h-12 border border-gray-200 shadow-sm  bg-white absolute -left-98  z-50 rounded-md p-2">
                    {assetList && assetList.files && assetList.files.length ? (
                        assetList.files.map((listItem) => (
                            <div key={listItem.id} className="flex hover:bg-gray-200 cursor-pointer mb-2" onClick={() => onAssetListSelect(listItem)}>
                                <div className="flex-initial mr-1">
                                    <div className="flex justify-center items-center rounded-full h-6 w-6 bg-gray-300 mr-4">
                                        <img className="h-4 w-4" src={listItem.downloadUrl} alt=""></img>
                                    </div>
                                </div>
                                <div className={`flex-auto mr-1 text-gray-900 text-sm truncate ${asset && asset.id === listItem.id ? "font-bold" : "font-normal"}`}>{listItem.filename}</div>
                                <div className="flex-initial w-6 mr-1  ml-1">{asset && asset.id === listItem.id ? <CheckIcon className="h-6 w-6 text-center text-dania-500" aria-hidden="true" /> : null} </div>
                            </div>
                        ))
                    ) : (
                        <div>No assets found</div>
                    )}
                </div>
            </div>
        ) : null;
    };

    const PortraitButtonState = () => {
        return DoDataContainProfilePicture(true) ? (
            <div>
                <div className="flex items-center justify-center">
                    <UserIcon className="h-4 w-4 text-white" aria-hidden="true" />
                </div>
            </div>
        ) : (
            <div>
                <div className="flex items-center justify-center">
                    <UserIcon className="h-4 w-4 text-gray-500" aria-hidden="true" />
                </div>
            </div>
        )
    };

    const ProfilePictureURL = async (showPortrait) => {
        if (showPortrait) {
            let adPhotoArray = await graphClient.GetProfilePicture(props.azureClientId);
            let adPhotoInt8Array = new Uint8Array(adPhotoArray);
            let adPhotoBlob = new Blob([adPhotoInt8Array.buffer], { type: "image/png" });
            let imageUrl = URL.createObjectURL(adPhotoBlob);

            setProfilePicURL(imageUrl.toString());
        }
    }


    function DoDataContainProfilePicture(checkIfActive = false) {
        if (checkIfActive) {
            return props.data.profilePicture != undefined && props.data.profilePicture.useProfilePicture;
        }
        return props.data.profilePicture != undefined;
    }


    useEffect(() => {
        if (DoDataContainProfilePicture())
            ProfilePictureURL(props.data.profilePicture.useProfilePicture);
    }
        , [props.data.profilePicture])

    const ToggleProfilePicture = () => {
        props.data.profilePicture.useProfilePicture = !props.data.profilePicture.useProfilePicture;
        props.onChange(props.data);
        ProfilePictureURL(props.data.profilePicture.useProfilePicture);
    }

    const ChangeProfilePictureStyle = (event) => {
        props.data.profilePicture.pictureStyle = event.target.value;
        props.onChange(props.data);
    }

    function GetProfilePictureStyling(selectedStyling) {
        let returnStr = "h-8 w-8";
        if (selectedStyling == "circular") {
            returnStr += " rounded-full";
        }
        else if (selectedStyling == "rounded") {
            returnStr += " rounded-md";
        }

        return returnStr;
    }

    const ImagePortraitState = () => {
        return DoDataContainProfilePicture(true) ? (
            <div>
                <div>
                    <img className={GetProfilePictureStyling(props.data.profilePicture.pictureStyle)} src={profilePicURL} alt="" />
                </div>
                <div className="text-sm text-gray-500" style={{ fontSize: "50%" }}>Portrait</div>
            </div>
        ) : (
            <div>
                <div className="flex justify-center items-center">
                    <PhotographIcon className="h-4 w-4 text-center text-gray-500" aria-hidden="true" />
                </div>
                <div className="text-sm text-gray-500" style={{ fontSize: "50%" }}>No image</div>
            </div>
        )
    };

    return (
        <div className="mt-3">
            <Overlay show={showDialog}>
                <div className=" bg-white rounded-md p-5">
                    <div className="flex">
                        <div className="flex-none w-14 h-14">
                            <div className="flex-none w-14 h-14 pt-2">
                                <div className=" flex w-10 h-10 rounded-full bg-dania-100 justify-center items-center">
                                    {mode === "upload" ?
                                        <UploadIcon className="h-4 w-4 text-dania-500 cursor-pointer" aria-hidden="true" />
                                        :
                                        <FolderOpenIcon className="h-4 w-4 text-dania-500 cursor-pointer" aria-hidden="true" />
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="flex-auto text-left flex flex-col" style={{ width: "500px" }}>
                            <div className="text-lg font-medium text-gray-900 mb-3 flex-1"> {props.headLine} </div>
                            <div hidden={mode === "edit"} className="flex-1">
                                <div className="text-sm font-medium text-gray-500 mb-3"> Description </div>
                                <div>
                                    <textarea onChange={onDescriptionChanged} defaultValue={props.data.description} className="border-gray-300 w-96 h-px75 mt-1 focus:ring-dania-500 focus:border-dania-500 block shadow-sm sm:text-sm rounded-md"></textarea>
                                </div>
                            </div>
                            <LimitSectionElement />
                            <div className="flex justify-end mb-3 flex-1 mt-5">
                                <span className="mr-3">
                                    <Button onNavigation={() => setShowDialog(false)} className="mr-3" bgColor="bg-white" textColor="text-gray-700" text="Cancel" />
                                </span>
                                <Button bgColor="bg-dania-500" textColor="text-white"
                                    text={mode === "upload" ? "Upload" : "Save"}
                                    onNavigation={() => mode === "upload" ? onUploadClick() : OnEditSave()}
                                />
                            </div>
                            {settingsDialogError ? <NotificationBar text={settingsDialogError} color="red"></NotificationBar> : null}
                        </div>
                    </div>
                </div>
            </Overlay>
            {fileSizeToBig ? <NotificationBar text="File size cannot exceed 5MB." color="red" /> : null}
            {fileTypeNotSupported ? <NotificationBar text="File type is not supported. The following file types are allowed: .png, .bmp, .jpg, .jpeg, .gif, .dib, .jfif, .jpe." color="red" /> : null}
            <input type="file" accept="image/*" onChange={onFileInputChange} style={{ display: "none" }} ref={hiddenFileInput} />
            <div className="flex items-center justify-center">
                {asset && asset.url && !DoDataContainProfilePicture(true) ? (
                    <div className="flex justify-left items-left rounded-full h-12 w-12 bg-gray-100 mr-4">
                        <img className="h-8 w-8" src={asset.url} alt="" />
                    </div>
                ) : (
                    <div className="flex-direction: row mr-4 justify-left items-left">
                        <div>
                            <ImagePortraitState />
                        </div>
                    </div>
                )}
                {assetList.storageQuotaExceeded ? null :
                    <button
                        onClick={showFileDialog}
                        type="button"
                        className="mt-3 mb-3 mr-2 items-center text-justify px-3 py-2 font-medium border border-transparent text-base rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 bg-white text-gray-500 hover:bg-gray-200"
                    >
                        <div className="flex items-center justify-center">
                            <label>
                                <UploadIcon className="h-4 w-4  text-gray-500 cursor-pointer" aria-hidden="true" />
                            </label>
                        </div>
                    </button>
                }
                {!assetList.storageQuotaExceeded ? null :
                    <button type="button" className="mt-3 mb-3 mr-3 items-center text-justify px-3 py-2 font-medium border border-transparent text-base rounded-md shadow-sm text-gray-400 cursor-not-allowed">
                        {getQuotaOverlay()}
                    </button>
                }

                <button
                    onClick={() => setShowAssetFileList(!showAssetFileList)}
                    onBlur={
                        () =>
                            setTimeout(() => {
                                setShowAssetFileList(false);
                            }, 200) //Add delay before closing panel to ensure selection is handled
                    }
                    type="button"
                    className="mt-3 mb-3 mr-2 items-center text-justify px-3 py-2 font-medium border border-transparent text-base rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 bg-white text-gray-500 hover:bg-gray-200"
                >
                    <div className="flex items-center justify-center">
                        <FolderOpenIcon className="h-4 w-4 text-gray-500" aria-hidden="true" />
                    </div>
                </button>

                {DoDataContainProfilePicture() ?
                    <button
                        onClick={() => ToggleProfilePicture()}
                        type="button"
                        className="mt-3 mb-3 items-center text-justify px-3 py-2 font-medium border border-transparent text-base rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 bg-white text-gray-500 hover:bg-gray-200"
                        style={{ backgroundColor: DoDataContainProfilePicture(true) ? "#0091ff" : "white" }}
                    >
                        <div>
                            <PortraitButtonState />
                        </div>
                    </button>
                    : ""}

                {props.showEdit ? <PencilIcon className="h-4 w-4 ml-3 text-gray-500 cursor-pointer" aria-hidden="true" onClick={() => onEditClick(props.data)} /> : null}
                <div>
                    <MinusCircleIcon className="h-4 w-4 ml-3 text-gray-500 cursor-pointer" aria-hidden="true" onClick={() => props.removeAction()} />
                </div>
            </div>
            <div>
                <AssetFileListElement />
            </div>

            <div>
                {DoDataContainProfilePicture(true) ? (
                    <div>
                        <label className="text-gray-500 text-sm">Style</label>
                        <div className="flex items-center justify-center">
                            <div>
                                <input className="mr-1" type="radio" value="circular" name="style" checked={props.data.profilePicture.pictureStyle === "circular"} onChange={ChangeProfilePictureStyle} style={{ backgroundColor: props.data.profilePicture.pictureStyle === "circular" ? "#0091ff" : "white" }} />
                                <label className="text-sm mr-3 text-gray-500">Circular</label>
                            </div>
                            <div>
                                <input className="mr-1" type="radio" value="rounded" name="style" checked={props.data.profilePicture.pictureStyle === "rounded"} onChange={ChangeProfilePictureStyle} style={{ backgroundColor: props.data.profilePicture.pictureStyle === "rounded" ? "#0091ff" : "white" }} />
                                <label className="text-sm mr-3 text-gray-500">Rounded</label>
                            </div>
                            <div>
                                <input className="mr-1" type="radio" value="square" name="style" checked={props.data.profilePicture.pictureStyle === "square"} onChange={ChangeProfilePictureStyle} style={{ backgroundColor: props.data.profilePicture.pictureStyle === "square" ? "#0091ff" : "white" }} />
                                <label className="text-sm mr-3 text-gray-500">Square</label>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="flex justify-between">
                            <label htmlFor={props.fieldName} className="block text-sm text-gray-500">
                                Logo link
                            </label>
                            <span className="text-sm text-gray-500" id="email-optional">
                                Optional
                            </span>
                        </div>
                        <TextBox
                            defaultValue={data.link}
                            onChange={(e) => {
                                props.data.link = e.target.value;
                                props.onChange(props.data);
                            }}
                        ></TextBox>
                    </div>
                )}
            </div>

        </div>
    );
}