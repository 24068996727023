import { TrashIcon } from "@heroicons/react/outline";
import Chip from "../Components/Chip";
import React from "react";

import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, BanIcon, PencilIcon, CheckCircleIcon, DuplicateIcon } from '@heroicons/react/solid'
import DateFormat from "../Helpers/DateFormater";

const signatureTypes = [
    "External emails",
    "Reply emails",
    "Internal emails"
]

export default function SignatureCard({ active, showButtons, department, title, date, signatureType, description, deleteAction, editAction, toggleAction, duplicateAction }) {
    return (
        <div className="mb-5 mt-5 ">
            <div className="bg-white ml-1 shadow h-max rounded-lg p-10 h-full flex flex-col">
                <div className="flex">
                    <div className="flex-none rounded bg-dania-100 text-dania-700 w-10 h-10 text-center m-auto"><span className="align-middle text-lg leading-10 p-auto w-full font-medium">{department == "" ? "EV" : department.replace(" ", "").substring(0, 2).toUpperCase()}</span></div>
                    <div className="flex-1"></div>
                    <div className="flex-none">
                        {active ? <Chip color="green" text="Active" /> : <Chip color="red" text="Inactive" />}
                    </div>
                </div>
                <div className="flex">
                    <div className="flex-none text-gray-500"><span className="text-sm">{signatureTypes[signatureType]}</span></div>
                    <div className="flex-1 text-gray-200 text-center">|</div>
                    <div className="flex-none text-gray-500"><span className="text-sm">{DateFormat(date, "mm dd, yyyy")}</span></div>
                </div>
                <div className="text-gray-500 mt-5"><span className="text-base">{department}</span></div>
                <div className="mb-5"><div className="text-gray-900 text-lg font-semibold">{title}</div></div>
                <div className="text-gray-500 mb-4 flex-1"><span className="text-base">{description}</span></div>
                {showButtons ?
                    <div className="flex">
                        <OptionMenu status={active} deleteAction={deleteAction} editAction={editAction} toggleAction={toggleAction} duplicateAction={duplicateAction} />
                    </div>
                    : null}
            </div>
        </div >
    );
}


export function OptionMenu({ status, deleteAction, editAction, duplicateAction, toggleAction }) {
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button className="inline-flex items-center px-4 py-2 font-medium border border-transparent text-sm rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dania-500 text-dania-500 bg-dania-100 hover:bg-dania-200">
                    <PencilIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                    Edit
                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                </Menu.Button>
            </div>

            <Transition
                appear={true}
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                    <div className="py-1">
                        <Menu.Item>
                            {({ active }) => (
                                <div
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'group flex items-center px-4 py-2 text-sm cursor-pointer'
                                    )}
                                    onClick={() => { editAction() }}
                                >
                                    <PencilIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                    Edit
                                </div>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <div
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'group flex items-center px-4 py-2 text-sm cursor-pointer'
                                    )}
                                    onClick={() => { duplicateAction() }}
                                >
                                    <DuplicateIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                    Duplicate
                                </div>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <div
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'group flex items-center px-4 py-2 text-sm cursor-pointer'
                                    )}
                                    onClick={() => { toggleAction() }}
                                >
                                    {status ? <BanIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" /> : <CheckCircleIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />}
                                    {status ? "Deactivate" : "Activate"}
                                </div>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <div
                                    className={classNames(
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'group flex items-center px-4 py-2 text-sm cursor-pointer'
                                    )}
                                    onClick={() => { deleteAction() }}
                                >
                                    <TrashIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                    Delete
                                </div>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}