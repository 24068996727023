import { Transition } from "@headlessui/react";
import { useState } from "react";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export default function Button(props) {
	const [rendered, setRendered] = useState(false);
	function cursor() {
		return props.disabled === true ? "cursor-not-allowed" : "";
	}
	return (
		<button
			onClick={() => {
				setRendered(true);
				props.onNavigation();
			}}
			disabled={props.disabled}
			type="button"
			className={classNames(
				"inline-flex items-center px-4 py-2 font-medium border border-transparent text-sm rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dania-500",
				props.textColor,
				props.bgColor,
				"hover:" + props.hoverColor,
				cursor()
			)}
		>
			{props.iconClasses ? (
				<CreateIcon classes={props.iconClasses} />
			) : (
				<Transition appear={!rendered} show={true} enter="transition ease-in-out duration-1000" enterFrom="opacity-0" enterTo="opacity-100" leave="transition duration-150" leaveFrom="opacity-100" leaveTo="opacity-0">
					<CreateIcon classes={""} />
				</Transition>
			)}

			{props.text}

			{props.iconClasses ? (
				<CreateRightIcon classes={props.iconClasses} />
			) : (
				<Transition appear={!rendered} show={true} enter="transition ease-in-out duration-1000" enterFrom="opacity-0" enterTo="opacity-100" leave="transition duration-150" leaveFrom="opacity-100" leaveTo="opacity-0">
					<CreateRightIcon classes={""} />
				</Transition>
			)}
		</button>
	);

	function CreateIcon(e) {
		return props.icon ? <props.icon className={classNames(e.classes, "h-4 w-4 mr-2")} /> : null;
	}
	function CreateRightIcon(e) {
		return props.rightIcon ? <props.rightIcon className={classNames(e.classes, "h-4 w-4 ml-2")} /> : null;
	}
}
