import { XCircleIcon } from "@heroicons/react/outline";
import { AnnotationIcon, ArrowRightIcon, CheckCircleIcon, ColorSwatchIcon, ExclamationCircleIcon, PhotographIcon, TemplateIcon, UserIcon } from "@heroicons/react/solid";
import { useCallback, useEffect, useRef, useState } from "react";
import { AddSignature, GetPredefinedSignatures, GetSignature, UpdateSignature, GetClientId } from "../../ApiClients/OutlookSignatureCloudClient";
import Button from "../../Components/Buttons/Button";
import * as graphClient from "../Azure/Graph";
import * as azureLogin from "../Azure/login";
import ModalDialog from "../ModalDialog";
import NotificationBar from "../NotificationBar";
import Assets from "./Assets";
import Details from "./Details";
import General from "./General";
import HtmlPreviewer from "./HtmlPreviewer";
import CodeEditor from "./CodeEditor";
import Styling from "./Styling";
import { DragDropContext } from "react-beautiful-dnd";

export default function SignatureBuilderModal({ id, show, closeAction, successAction, isAdConnected, allSignatures }) {
    var tabs = [General, Details, Styling, Assets];
    const [tab, setTab] = useState({ content: tabs[0], index: 0 });
    const [errorMessage, setErrorMessage] = useState(null);
    const [predefinedSignatures, setPredefinedSignatures] = useState([{ name: "Template 1", preview: {}, PreviewSelected: {}, id: "" }]);
    const [errorOnTemplateChange, setErrorOnTemplateChange] = useState(false);
    const [dialog, setDialog] = useState({ show: false });
    const [saveButtonText, setSaveButtonText] = useState("Create and activate signature");
    const [azureSignedIn, setAzureSignedIn] = useState(false);
    const [azureClientId, setAzureClientId] = useState(null);
    const [azureGroups, setAzureGroups] = useState([]);
    const [disableDropArea, setDisableDropArea] = useState(false);

    let defaultSignature = {
        id: "00000000-0000-0000-0000-000000000000",
        limitToGroup: "",
        name: "",
        description: "",
        signatureType: 0,
        deactivated: false,
        logoWidth: 77,
        campaignWidth: 200,
        profilePictureBase64: "",
        fields: [
            {
                allowOverwrite: false,
                disabled: false,
                id: 0,
                name: "First name",
                value: "%%userprofile_firstname%%",
                custom: false,
            },
            {
                allowOverwrite: false,
                disabled: false,
                id: 1,
                name: "Last name",
                value: "%%userprofile_lastname%%",
                custom: false,
            },
            {
                allowOverwrite: false,
                disabled: false,
                id: 2,
                name: "Position / Title",
                value: "%%userprofile_title%%",
                custom: false,
            },
        ],
        images: [
            {
                assetId: "5bdb1b46-1445-49d6-8375-cca9ab0c6959",
                default: false,
                description: "Alternative logo",
                id: 2,
                limitations: [
                    {
                        fieldName: "",
                        limitTo: ["", "", ""],
                    },
                ],
                link: "https://b.dk",
                profilePicture:
                {
                    pictureStyle: "circular",
                    useProfilePicture: false,
                    base64Encoded: ""
                }
            },
        ],
        customAssets: [
            {
                assetId: "5bdb1b46-1445-49d6-8375-cca9ab0c6959",
                default: false,
                description: "customAssets",
                id: 2,
                limitations: [
                    {
                        fieldName: "",
                        limitTo: ["", "", ""],
                    },
                ],
                link: "https://b.dk",
                profilePicture:
                {
                    pictureStyle: "circular",
                    useProfilePicture: false,
                    base64Encoded: ""
                }
            },
        ],
        markUp: {
            contentType: 0,
            html: "<div></div>",
            style: {
                accentColor: "",
                bodyColor: "",
                font: "",
                fontSize: "",
                headerColor: "",
                headerWeight: "",
                soMeColor: "",
            },
        },
        soMeLinks: [
            {
                allowOverwrite: false,
                description: "Facebook link",
                id: 0,
                imageUri: "http://www.google.com",
                linkUri: "http://www.test.dk",
                disabled: false,
            },
            {
                allowOverwrite: false,
                description: "Twitter link",
                id: 0,
                imageUri: "http://www.google.com",
                linkUri: "http://www.test.dk",
                disabled: false,
            },
            {
                allowOverwrite: false,
                description: "Linkedin link",
                id: 0,
                imageUri: "http://www.google.com",
                linkUri: "http://www.test.dk",
                disabled: false,
            },
        ],
        campaigns: [
            {
                assetId: "6f353c95-5826-492a-8dda-d715e9b63f36",
                default: true,
                id: 0,
                limitations: [
                    {
                        fieldName: "",
                        limitTo: ["", "", ""],
                    },
                ],
                link: "http://www.test.dk",
            },
            {
                assetId: "6f353c95-5826-492a-8dda-d715e9b63f36",
                default: false,
                id: 1,
                limitations: [
                    {
                        fieldName: "",
                        limitTo: ["", "", ""],
                    },
                ],
                link: "http://www.test.dk - Alternative",
            },
        ],
    };
    const [signature, setSignature] = useState(defaultSignature);
    const [comparePoint, setComparePoint] = useState(""); //Hold initial loaded signature to keep track of changes
    const [signatureChanged, setSignatureChanged] = useState(false);
    const isEditMode = id !== "";

    useEffect(() => {
        GetPredefinedSignatures().then((data) => {
            setPredefinedSignatures(data);
            if (isEditMode) {
                GetSignature(id).then((data) => {
                    setComparePoint(JSON.stringify(data));
                    setSignature(data);
                    setSaveButtonText("Save changes");
                    setErrorOnTemplateChange(true);
                });
            } else {
                setComparePoint(JSON.stringify(data[0].signature));
                setSignature(data[0].signature);
            }
        });
    }, []);

    useEffect(() => {
        var changed = JSON.stringify(signature) !== comparePoint;
        setSignatureChanged(changed);
    }, [signature, comparePoint]);

    useEffect(() => {
        if (isAdConnected) {
            async function loadAd() {
                var cliedIdResponse = await GetClientId();
                setAzureClientId(cliedIdResponse.clientId);
                var account = azureLogin.msalInstance(cliedIdResponse.clientId).getActiveAccount();

                if (account) {
                    setAzureSignedIn(true);
                    graphClient
                        .GetGroups(azureClientId)
                        .then((x) => {
                            var output = x.map((row, key) => {
                                return { id: key, label: row.displayName, value: row.displayName };
                            });
                            setAzureGroups(output);
                        })
                        .catch((err) => console.error(err));
                } else {
                    ShowSignInDialog();
                }
            }

            loadAd();
        }
    }, [isAdConnected]);

    function showTab(index) {
        setTab({ content: tabs[index], index: index });
        setErrorOnTemplateChange(true);
    }

    function isSelectedTab(index) {
        return index === tab.index;
    }

    function CloseWindow() {
        setSignatureAsDefault();
        showTab(0);
        setErrorOnTemplateChange(false);
        closeAction();
    }

    function CheckBeforeSave() {
        let numberOfDuplicates = 0;
        let shouldCheck = true;

        allSignatures.forEach(function (item) {
            if (item.name.toLowerCase() === signature.name.toLowerCase()) {
                numberOfDuplicates++;

                if (isEditMode) {
                    if (item.id != signature.id) {
                        shouldCheck = false;
                    }
                }

            } else if (item.name.toLowerCase().startsWith(`${signature.name.toLowerCase()} (`)) {
                numberOfDuplicates++;
            }

        });

        if (signature.id === undefined && numberOfDuplicates > 0) {
            showDuplicateSignatureDialog(signature.name, numberOfDuplicates);
        }
        else if (numberOfDuplicates > 0 && !shouldCheck) {
            showDuplicateSignatureDialog(signature.name, numberOfDuplicates);
        }
        else {
            Save();
        }
    }

    function showDuplicateSignatureDialog(signatureName, numberOfDuplicates) {
        setDialog({
            iconColor: "dania",
            title: "Name already exists",
            text: `Another signature is already named '${signatureName}'. Do you want to name it '${signatureName} (${numberOfDuplicates})' instead? If not, cancel and try another name.`,
            actionButtonAction: () => {
                signature.name = `${signature.name} (${numberOfDuplicates})`
                Save();
            },
            actionButtonColor: "dania",
            actionButtonText: "Rename",
            show: true,
        });
    }

    function Save() {
        if (signature.id && signature.id !== "00000000-0000-0000-0000-000000000000") {
            UpdateSignature(signature).then((x) => {
                if (x.error) {
                    setErrorMessage(x.error);
                } else {
                    successAction();
                    setSignatureChanged(false);

                    //Update comparePoint to handle change state in the signature
                    setComparePoint(JSON.stringify(signature));
                }
            });
        } else {
            AddSignature(signature).then((x) => {
                if (x.error) {
                    setErrorMessage(x.error);
                } else {
                    setSignatureAsDefault();
                    showTab(0);
                    successAction();
                }
            });
        }
    }

    function DisableSaveCreateButton() {
        //Dont hide button if in createmode or if signature have changed
        if ((isEditMode === false || signatureChanged) && signature.name) {
            return false;
        } else {
            return true;
        }
    }

    function ChangeTemplate(index) {
        if (signature.markUp.basedOnPredefinedId !== index) {
            let oldState = {};
            if (predefinedSignatures.filter((x) => x.id === index)[0].signature.markUp.contentType == 1) {
                oldState = { ...signature };
                setErrorOnTemplateChange(true);
                setSignature({
                    ...predefinedSignatures.filter((x) => x.id === index)[0].signature,
                    id: oldState.id,
                    limitToGroup: oldState.limitToGroup,
                    name: oldState.name,
                    description: oldState.description,
                    signatureType: oldState.signatureType,
                    fields: [...oldState.fields],
                    soMeLinks: [...oldState.soMeLinks],
                    images: [...oldState.images],
                    customAssets: [...oldState.customAssets],
                    logoWidth: oldState.logoWidth,
                    campaignWidth: oldState.campaignWidth,
                    campaigns: [...oldState.campaigns],
                    markUp: { ...predefinedSignatures.filter((x) => x.id === index)[0].signature.markUp, html: oldState.markUp.html, style: { ...oldState.markUp.style } },
                });
            } else {
                if (errorOnTemplateChange) {
                    showDeleteSignatureDialog(index);
                } else {
                    oldState = { ...signature };
                    setSignature({ ...predefinedSignatures.filter((x) => x.id === index)[0].signature, id: oldState.id, limitToGroup: oldState.limitToGroup, name: oldState.name, description: oldState.description, signatureType: oldState.signatureType });
                }
            }
        }
    }

    function showDeleteSignatureDialog(index) {
        setDialog({
            iconColor: "red",
            title: "Signature will be reset",
            text: "Any changes to the signature will be removed, and you will have to set up the signature anew.",
            actionButtonAction: () => {
                let oldState = { ...signature };
                setSignature({ ...predefinedSignatures.filter((x) => x.id === index)[0].signature, id: oldState.id, limitToGroup: oldState.limitToGroup, name: oldState.name, description: oldState.description, signatureType: oldState.signatureType });
                closeDialog();
            },
            actionButtonColor: "red",
            actionButtonText: "Change signature",
            show: true,
        });
    }

    function showDiscardDialog() {
        setDialog({
            iconColor: "yellow",
            title: "Are you sure?",
            text: "Are you sure you want to close the template? All unsaved changes will be discarded.",
            actionButtonAction: () => {
                closeAction();
            },
            actionButtonColor: "yellow",
            actionButtonText: "Yes - discard changes",
            closeButtonText: "No, take me back",
            show: true,
        });
    }

    function closeDialog() {
        setDialog((prevState) => ({ ...prevState, show: false }));
    }

    function setSignatureAsDefault() {
        if (predefinedSignatures.length > 0) {
            setSignature(predefinedSignatures[0].signature);
        } else {
            setSignature(defaultSignature);
        }
    }

    function AzureLogin() {
        azureLogin
            .msalInstance(azureClientId)
            .loginPopup({
                redirectUri: `${window.location.origin.toLowerCase()}${window.location.pathname.toLowerCase()}`,
            })
            .then((x) => {
                setAzureSignedIn(true);
            })
            .catch((err) => console.error(err));
    }

    function AzureSignOut() {
        azureLogin
            .msalInstance(azureClientId)
            .logoutPopup()
            .then((x) => setAzureSignedIn(false));
    }

    function AzureSigninStatusBar() {
        if (isAdConnected) {
            return azureSignedIn ? (
                <div className="mr-20">
                    {" "}
                    <NotificationBar color="dania" text="You are logged in to your Microsoft Account and can preview signatures with live profile data." onClick={() => AzureSignOut()} linkText="Sign out" />
                </div>
            ) : (
                <div className="mr-20">
                    {" "}
                    <NotificationBar color="yellow" text="If you log in to your Microsoft Account, you can preview signatures with live profile data." onClick={() => AzureLogin()} linkText="Sign in" />
                </div>
            );
        } else {
            return null;
        }
    }

    function ShowSignInDialog() {
        //If user have dismissed then dont show again in this session
        if (sessionStorage.getItem("signinDismissed") === null) {
            setDialog({
                icon: UserIcon,
                iconColor: "dania",
                title: "Sign in to Azure",
                text: "Get the best user experience by signing into azure.",
                actionButtonColor: "dania",
                actionButtonText: "Sign in",
                actionButtonAction: () => {
                    closeDialog();
                    AzureLogin();
                },
                closeAction: () => {
                    sessionStorage.setItem("signinDismissed", true);
                    closeDialog();
                },
                show: true,
            });
        }
    }

    function onDragStart(result) {
        if (result.source.droppableId != "droppable") {
            setDisableDropArea(true);
        } else {
            setDisableDropArea(false);
        }
    }

    function onDragEnd(result) {
        // Dropped outside the list
        if (!result.destination) {
            return;
        }

        if (result.destination.droppableId == "ThisDropCode") {
            var input = document.getElementById("codeArea");
            var inputContent = input.value.length;

            var inputData = getInputSelection(input);

            let markUpClone = { ...signature.markUp };

            if (result.source.droppableId.startsWith("droppable")) {
                markUpClone.html = input.value.substring(0, inputData.start) + "<Field id='" + signature.fields[result.source.index].id + "' />" + input.value.substring(inputData.start, inputContent);
            } else if (result.source.droppableId.startsWith("soMeDropArea")) {
                markUpClone.html = input.value.substring(0, inputData.start) + "<SoMe id='" + result.source.index + "' height='16' style='height:16px' />" + input.value.substring(inputData.start, inputContent);
            } else if (result.source.droppableId.startsWith("logoDropArea")) {
                markUpClone.html = input.value.substring(0, inputData.start) + "<Logo style='max-width:77px; max-height:77px;' />" + input.value.substring(inputData.start, inputContent);
            } else if (result.source.droppableId.startsWith("campaignDropArea")) {
                markUpClone.html = input.value.substring(0, inputData.start) + "<Campaign />" + input.value.substring(inputData.start, inputContent);
            } else if (result.source.droppableId.startsWith("customAssetsDropArea")) {
                markUpClone.html = input.value.substring(0, inputData.start) + "<Asset customAssetId='" + signature.customAssets[result.source.index].id + "' />" + input.value.substring(inputData.start, inputContent);
            }

            let elem = document.getElementById("codeArea");
            elem.setSelectionRange(inputData.start + 2, inputData.start + 2);

            setSignature((prevState) => ({ ...prevState, markUp: markUpClone }));
            return;
        }

        if (result.destination.droppableId == result.source.droppableId) {
            const itemsOrder = reorder([...signature.fields], result.source.index, result.destination.index);

            setSignature((prevState) => ({ ...prevState, fields: itemsOrder }));
        }
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    function getInputSelection(el) {
        var start = 0,
            end = 0,
            normalizedValue,
            range,
            textInputRange,
            len,
            endRange;

        if (typeof el.selectionStart == "number" && typeof el.selectionEnd == "number") {
            start = el.selectionStart;
            end = el.selectionEnd;
        } else {
            range = document.selection.createRange();

            if (range && range.parentElement() == el) {
                len = el.value.length;
                normalizedValue = el.value.replace(/\r\n/g, "\n");

                // Create a working TextRange that lives only in the input
                textInputRange = el.createTextRange();
                textInputRange.moveToBookmark(range.getBookmark());

                // Check if the start and end of the selection are at the very end
                // of the input, since moveStart/moveEnd doesn't return what we want
                // in those cases
                endRange = el.createTextRange();
                endRange.collapse(false);

                if (textInputRange.compareEndPoints("StartToEnd", endRange) > -1) {
                    start = end = len;
                } else {
                    start = -textInputRange.moveStart("character", -len);
                    start += normalizedValue.slice(0, start).split("\n").length - 1;

                    if (textInputRange.compareEndPoints("EndToEnd", endRange) > -1) {
                        end = len;
                    } else {
                        end = -textInputRange.moveEnd("character", -len);
                        end += normalizedValue.slice(0, end).split("\n").length - 1;
                    }
                }
            }
        }

        return {
            start: start,
            end: end,
        };
    }

    const handleKeyPress = useCallback((event) => {
        if (event.key === "Escape") {
            if (signatureChanged) {
                showDiscardDialog();
            } else {
                closeAction();
            }
        }
        else if (event.ctrlKey && event.key === "s") {
            var isBtnDisabled = document.getElementsByClassName("flex-none align-middle mb-5").item(0).firstChild.disabled;

            if (!isBtnDisabled)
                CheckBeforeSave();

            event.preventDefault();
        }
        else if (event.ctrlKey && event.key === "1") {
            showTab(0);
            event.preventDefault();
        }
        else if (event.ctrlKey && event.key === "2") {
            showTab(1);
            event.preventDefault();
        }
        else if (event.ctrlKey && event.key === "3") {
            showTab(2);
            event.preventDefault();
        }
        else if (event.ctrlKey && event.key === "4") {
            showTab(3);
            event.preventDefault();
        }
    });

    useEffect(() => {

        document.addEventListener("keydown", handleKeyPress);

        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };

    }, [handleKeyPress]);



    return (

        /* This example requires Tailwind CSS v2.0 + */
        <div className={"fixed z-10 inset-0 overflow-y-auto" + (show ? "" : " hidden")} aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <ModalDialog
                icon={dialog.icon}
                iconColor={dialog.iconColor}
                title={dialog.title}
                text={dialog.text}
                actionButtonAction={dialog.actionButtonAction}
                actionButtonColor={dialog.actionButtonColor}
                actionButtonText={dialog.actionButtonText}
                show={dialog.show}
                closeButtonText={dialog.closeButtonText}
                close={
                    dialog.closeAction
                        ? dialog.closeAction
                        : () => {
                            closeDialog();
                        }
                }
            />

            <div className="flex sm:block items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">
                <div className="block sm:hidden fixed inset-0 bg-gray-900 bg-opacity-80 transition-opacity backdrop-filter backdrop-blur" aria-hidden="true">
                    <div>
                        <div className="flex flex-col h-screen text-white inline-block align-middle h-screen items-center">
                            <div className="m-auto">
                                <div className="text-2xl font-bold tracking-wide">Mobile devices are yet to be supported</div>
                                <div className="text-lg shrink w-96 m-6 leading-tight tracking-wide">Creating signatures on mobile devices are unfortunately not supported. Please try again on a desktop or laptop computer.</div>
                                <div className="">
                                    <Button
                                        onNavigation={() => {
                                            closeAction();
                                        }}
                                        rightIcon={ArrowRightIcon}
                                        text="Go back"
                                        textColor="text-white"
                                        bgColor="bg-dania-500"
                                        hoverColor="bg-dania-600"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="hidden sm:block fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
                <div className="hidden sm:block fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                    &#8203;
                </span>



                <div className="hidden sm:inline-block bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all align-middle w-11/12 h-95">
                    <div className="flex h-full">
                        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                            <div className="flex-none">
                                <div className="flex flex-col h-full">
                                    <div className="flex-none">
                                        <div className="flex">
                                            <div className="flex-1" onClick={() => showTab(0)}>
                                                <div className={"flex w-px75 h-px75 cursor-pointer align-middle items-center" + (isSelectedTab(0) ? " bg-dania-100" : "")}>
                                                    <TemplateIcon className={"m-auto w-8 " + (isSelectedTab(0) ? "text-dania-500" : "text-gray-500")} />
                                                </div>
                                            </div>
                                            <div className="flex-1" onClick={() => showTab(1)}>
                                                <div className={"flex w-px75 h-px75 cursor-pointer align-middle items-center" + (isSelectedTab(1) ? " bg-dania-100" : "")}>
                                                    <AnnotationIcon className={"m-auto w-8 " + (isSelectedTab(1) ? "text-dania-500" : "text-gray-500")} />
                                                </div>
                                            </div>
                                            <div className="flex-1" onClick={() => showTab(2)}>
                                                <div className={"flex w-px75 h-px75 cursor-pointer align-middle items-center" + (isSelectedTab(2) ? " bg-dania-100" : "")}>
                                                    <ColorSwatchIcon className={"m-auto w-8 " + (isSelectedTab(2) ? "text-dania-500" : "text-gray-500")} />
                                                </div>
                                            </div>
                                            <div className="flex-1" onClick={() => showTab(3)}>
                                                <div className={"flex w-px75 h-px75 cursor-pointer align-middle items-center" + (isSelectedTab(3) ? " bg-dania-100" : "")}>
                                                    <PhotographIcon className={"m-auto w-8 " + (isSelectedTab(3) ? "text-dania-500" : "text-gray-500")} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-initial bg-gray-50 h-full w-px300 pl-3.5 pr-5 overflow-auto">
                                        <tab.content signature={signature} setSignature={setSignature} templates={predefinedSignatures} changeTemplateAction={ChangeTemplate} azureGroups={azureGroups} disableDropArea={disableDropArea} azureClientId={azureClientId} />
                                    </div>
                                </div>
                            </div>
                            <div className="flex-1 relative overflow-auto">
                                <div className="mt-5 ml-5">
                                    <AzureSigninStatusBar />
                                </div>
                                <div
                                    className="absolute top-5 right-5 cursor-pointer"
                                    title="Close"
                                    onClick={() => {
                                        if (signatureChanged) {
                                            showDiscardDialog();
                                        } else {
                                            closeAction();
                                        }
                                    }}
                                >
                                    <XCircleIcon className="m-auto w-8 h-8 text-gray-500 hover:text-gray-600" />
                                </div>
                                <div className="flex flex-col items-center px-10 pt-10">
                                    {signature.markUp.contentType == 1 ? (
                                        <div className="flex-1 min-w-full" style={{ maxWidth: "90%" }}>
                                            <CodeEditor signature={signature} setSignature={setSignature} />
                                        </div>
                                    ) : null}
                                    <div className="flex-1 min-w-full">
                                        <HtmlPreviewer signature={signature} azure={azureSignedIn} azureClientId={azureClientId} />
                                    </div>
                                    <div className="flex-none align-middle mb-5">
                                        <Button
                                            onNavigation={() => {
                                                CheckBeforeSave();
                                            }}
                                            icon={CheckCircleIcon}
                                            text={saveButtonText}
                                            textColor="text-white"
                                            bgColor={DisableSaveCreateButton() ? "bg-gray-300" : "bg-dania-500"}
                                            hoverColor={DisableSaveCreateButton() ? "bg-gray-300" : "bg-dania-600"}
                                            disabled={DisableSaveCreateButton()}
                                        />
                                    </div>
                                    {errorMessage ? <NotificationBar color="red" text={errorMessage} icon={ExclamationCircleIcon} /> : null}
                                </div>
                            </div>
                        </DragDropContext>
                    </div>
                </div>
            </div>
        </div>
    );
}