/* This example requires Tailwind CSS v2.0+ */
import { useEffect, useState, useRef } from 'react'
import Overlay from '../Overlays/Overlay'
import AccountInfoLayout from './AccountInfoLayout'
import AvailableTrialsPrompt from './AvailableTrialsPrompt'
import WelcomeMessage from './WelcomeMessage'
import { GetUserInfo, SetUserInfo } from '../../ApiClients/UserAccountClient'
import { GetTeamInfo, SetTeamInfo } from '../../ApiClients/TeamClient'

export default function AccountFinalizer() {
    const [open, setOpen] = useState(false)
    const defaultButtonRef = useRef(null)
    const [activeComponent, setActiveComponent] = useState(null);

    var firstUser = false;

    useEffect(() => {

        GetUserInfo().then((userInfo) => {

            if (userInfo.newUser) {
                // Show overlay
                setOpen(true);

                firstUser = userInfo.origin === 0; // FirstUser

                GetTeamInfo().then((teamInfo) => {
                    teamInfo.name = firstUser ? "" : teamInfo.name;
                    var accountInfoComponent = <AccountInfoLayout
                        firstUser={firstUser}
                        userInfo={userInfo}
                        teamInfo={teamInfo}
                        onClose={() => Finish(userInfo, firstUser ? teamInfo : null)}
                    />;

                    var welcomeComponent = <WelcomeMessage
                        userInfo={userInfo}
                        onContinue={() => setActiveComponent(accountInfoComponent)}
                        defaultButtonRef={defaultButtonRef} />;

                    setActiveComponent(welcomeComponent);

                });
            }
        });

    }, []);

    async function Finish(userInfo, teamInfo) {

        if (firstUser) {
            var trialsPrompt = <AvailableTrialsPrompt
                onClose={() => setOpen(false)}
            />;

            setActiveComponent(trialsPrompt)
        }
        else
            setOpen(false);

        userInfo.newUser = false;

        await SetUserInfo(userInfo);

        if (teamInfo)
            await SetTeamInfo(teamInfo)

    }

    return (
        <Overlay show={open} buttonRef={defaultButtonRef}>
            {activeComponent}
        </Overlay>
    )
}

