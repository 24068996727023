import React, { useState, useEffect } from "react";
import { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { ApplicationPaths, QueryParameterNames } from "./ApiAuthorizationConstants";
import authService from "./AuthorizeService";
import { GetProducts } from "../ApiClients/ProductsClient";

export default function AuthorizeRoute(props) {
    const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(window.location.href)}`;
    const { component: Component } = props;

    const [access, setAccess] = useState({
        ready: false,
        authenticated: false,
        authorized: false,
    });

    async function authorized() {
        const user = await authService.getUser()
        const products = await GetProducts();
        if (user && products) {
            var rolsRequired = props.rolsRequired;
            if (rolsRequired) {
                var isAuthorized = false;

                if (Array.isArray(user.role)) {
                    user.role.forEach((role) => {
                        if (rolsRequired.indexOf(role) > -1) {
                            isAuthorized = true;
                        }
                    });
                } else {
                    if (rolsRequired.indexOf(user.role) > -1) {
                        isAuthorized = true;
                    }
                }

                if (!isAuthorized) {
                    if (Array.isArray(products)) {
                        products.filter(x => x.trialStatus === 3 || x.trialStatus === 0).forEach((product) => {
                            if (rolsRequired.indexOf(product.name) > -1) {
                                isAuthorized = true;
                            }
                        })
                    }
                }
                return isAuthorized;
            } else {
                // No Roles required
                return true;
            }
        };
        return false;
    }

    useEffect(() => {
        setAccess({
            ready: false,
            authenticated: false,
            authorized: false,
        });
        populateAuthenticationState();
    }, [props.rolsRequired])

    function populateAuthenticationState() {
        authService.isAuthenticated().then((authenticatedResponse) => {
            authorized().then((authorizedResponse) => {
                setAccess({ ready: true, authenticated: authenticatedResponse, authorized: authorizedResponse })
            });
        });
    }

    return (

        !access.ready ?
            <div></div>
            :
            <Route>
                {access.authenticated ?
                    (
                        access.authorized ?
                            <Component {...props} /> :
                            <Redirect to={"/products"} />
                    ) :
                    <Redirect to={redirectUrl} />}
            </Route>
    )
}
