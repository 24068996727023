import PageHeader from "../../../Components/PageHeader";
import React from "react";
import NotificationBar from "../../../Components/NotificationBar";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import DownloadList from "../../../Components/Lists/DownloadList";
import { GetDocumentation, GetLicense } from "../../../ApiClients/AssistClient";
import { useState, useEffect } from "react";

export default function Documentation() {
    const [data, setData] = useState([]);
    const [error, setError] = useState();
    const [license, setLicense] = useState({});

    useEffect(() => {
        try {
            GetLicense()
                .then((data) => {
                    setLicense(data);
                });
            //Fetch install files from API
            GetDocumentation().then((response) => {
                if (response.error === undefined) {
                    setData(response);
                } else {
                    setError(response.error.message);
                }
            });
        } catch {
            setError("Unable to fetch documentation data");
        }
    }, []);

    const mapItem = (files) => {
        return files.map((x) => {
            //Map object
            return {
                name: x.name,
                downloadUrl: x.downloadUrl,
                description: x.description,
                archived: false,
                fileName: x.filename,
            };
        });
    };

    return (
        <div className="w-full">
            {
                <div>
                    <PageHeader headLine={"Documentation for Assist" + (license.licenseType == 100 ? " Pro" : "")}></PageHeader>
                    {error ? (
                        <div className="pt-1 pb-6">
                            <NotificationBar color="red" text={error} icon={ExclamationCircleIcon} />
                        </div>
                    ) : (
                        <div>
                            {data.length > 0 ? (
                                <DownloadList headLine={"Documentation for Assist" + (license.licenseType == 100 ? " Pro" : "")} applications={mapItem(data)} showMoreText="View more"></DownloadList>
                            ) : (
                                <div>
                                    <DownloadList headLine={"Documentation"} applications={[]} showMoreText="View more"></DownloadList>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            }
        </div>
    );
}
