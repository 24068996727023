import PageHeader from "../../Components/PageHeader";
import React, { useEffect, useState, Fragment } from "react";
import { GetBillingInfo, SetBillingInfo } from '../../ApiClients/TeamClient'
import AlertBar from "../../Components/AlertBar";
import CountryDropdown from "../../Components/Input/CountryDropdown";
import authService from "../../api-authorization/AuthorizeService";
import { Input } from "postcss";

export default function TeamBilling() {
    const [isAccountOwner, setIsAccountOwner] = useState(false);
    const [billingData, setBillingData] = useState({});
    const [alert, setAlert] = useState({
        visible: false,
        text: "",
        type: "error"
    });

    useEffect(() => {
        authService.isAccountOwner().then((result) => {
            setIsAccountOwner(result);
            setAlert({ visible: !result, text: "Only the admin of the team can update billing information.", type: "dania" });
        });

        GetBillingInfo().then((data) => {
            setBillingData(data);

            // Apply default-value in country selection dropdown:
            if (isAccountOwner) document.getElementById("country").value = data.country;
        });



    }, []);

    function ChangeHandler(e, propName) {
        billingData[propName] = e.target.value;
    }

    function InputValue(id) {
        return document.getElementById(id).value;
    }

    const SaveButton = () => (
        <div>
            <button type="submit" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-dania-600 text-base font-normal text-white hover:bg-dania-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dania-500 mt-5 sm:w-auto sm:text-sm">
                Save
            </button>
        </div>
    );

    const SaveButtonDisabled = () => (
        <div>
            <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-dania-50 text-base font-normal text-dania-400 focus:outline-none mt-5 sm:w-auto sm:text-sm cursor-not-allowed">
                Save
            </button>
        </div>
    );

    const CountrySelection = () => (
        <div className="col-span-1 sm:col-span-1">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                Country
            </label>
            <CountryDropdown id="country" name="country" autoComplete="country-name" defaultValue={billingData.country} onChange={(e) => { ChangeHandler(e, "country") }} />
        </div>
    );

    function Optional(props) {
        return (
            <div className="flex flex-row justify-between">
                {props.children}
                <div className="text-sm text-gray-400">
                    Optional
                </div>
            </div>
        );
    }

    function OptionalTextbox(props) {
        return (
            <div className="col-span-1 sm:col-span-1">
                <Optional>
                    <label htmlFor={props.fieldname} className="block text-sm font-medium text-gray-700">
                        {props.label}
                    </label>
                </Optional>
                <input
                    type="text"
                    name={props.fieldname}
                    id={props.fieldname}
                    autoComplete={props.fieldname}
                    className={props.readOnly ? "mt-1 focus:ring-dania-500 focus:border-dania-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md bg-gray-200" : "mt-1 focus:ring-dania-500 focus:border-dania-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"}
                    defaultValue={billingData[props.propName]}
                    placeholder={props.placeholderValue}
                    onChange={(e) => { ChangeHandler(e, props.propName) }}
                    readOnly={props.readOnly}
                />
            </div>
        );
    }

    function Textbox(props) {
        return (
            <div className="col-span-1 sm:col-span-1">
                <label htmlFor={props.fieldname} className="block text-sm font-medium text-gray-700">
                    {props.label}
                </label>
                <input
                    type="text"
                    name={props.fieldname}
                    id={props.fieldname}
                    autoComplete={props.fieldname}
                    className={props.readOnly ? "mt-1 focus:ring-dania-500 focus:border-dania-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md bg-gray-200" : "mt-1 focus:ring-dania-500 focus:border-dania-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"}
                    defaultValue={billingData[props.propName]}
                    placeholder={props.placeholderValue}
                    onChange={(e) => { ChangeHandler(e, props.propName) }}
                    readOnly={props.readOnly}
                />
            </div>
        );
    }

    function HandleSubmit(e) {
        e.preventDefault();

        var updateData = {
            name: InputValue("name"),
            address: InputValue("address"),
            postalCode: InputValue("postalCode"),
            city: InputValue("city"),
            country: InputValue("country"),
            vat: InputValue("vat"),
            invoiceEmail: InputValue("invoiceEmail"),
            invoiceComment: InputValue("invoiceComment"),
            EAN: InputValue("ean"),
            contactTitle: InputValue("contactTitle"),
        };

        SetBillingInfo(updateData).then((data) => {
            if (data.ok) {
                setAlert({ visible: true, text: "Success! The billing information has been saved.", type: "success" });
            } else {
                setAlert({ visible: true, text: "Error! Could not save the billing information.", type: "error" });
            }
        });
    }

    return (
        <div>
            <div>
                <PageHeader headLine="Billing" />
            </div>

            <div id="alertBar">
                {alert.visible ? <AlertBar header={alert.text} disableTransition="false" type={alert.type} /> : null}
            </div>
            <div className="mb-5 mt-5">
                <div className="bg-white ml-1 shadow sm:rounded-md h-max rounded-t">
                    <div className="mt-5 mb-5 p-5">
                        <form onSubmit={(e) => { HandleSubmit(e) }}>
                            <div className="flex">

                                <div className="flex-1">
                                    <p className="text-lg leading-6 font-medium">Billing Information</p>
                                    <p className="text-sm leading-5 font-normal">Update the information used for billing</p>
                                </div>

                                <div className="flex-auto">

                                    <div className="flex mb-5">
                                        <div className="flex-1 mr-5">
                                            <Textbox label="Name" fieldname="name" propName="name" value={billingData.name} readOnly={!isAccountOwner} />
                                        </div>
                                        <div className="flex-1">
                                            <OptionalTextbox label="Title" fieldname="contactTitle" propName="contactTitle" value={billingData.contactTitle} readOnly={!isAccountOwner} />
                                        </div>
                                    </div>

                                    <div className="flex mb-5">
                                        <div className="flex-1 mr-5">
                                            <Textbox label="Company / Team name" fieldname="company" propName="company" value={billingData.company} readOnly={true} />
                                        </div>
                                        <div className="flex-1">
                                            <Textbox label="Address" fieldname="address" propName="address" value={billingData.address} readOnly={!isAccountOwner} />
                                        </div>
                                    </div>

                                    <div className="flex mb-5">
                                        <div className="flex-1 mr-5">
                                            <Textbox label="ZIP / Postal" fieldname="postalCode" propName="postalCode" value={billingData.postalCode} readOnly={!isAccountOwner} />
                                        </div>
                                        <div className="flex-1">
                                            <Textbox label="City" fieldname="city" propName="city" value={billingData.city} readOnly={!isAccountOwner} />
                                        </div>
                                    </div>

                                    <div className="flex mb-5">
                                        <div className="flex-1 mr-5">
                                            {isAccountOwner ? <CountrySelection /> : <Textbox label="Country" fieldname="country" propName="country" value={billingData.country} readOnly={true} />}
                                        </div>
                                        <div className="flex-1">
                                            <Textbox label="Invoice email" fieldname="invoiceEmail" propName="invoiceEmail" value={billingData.invoiceEmail} readOnly={!isAccountOwner} />
                                        </div>
                                    </div>

                                    <div className="flex mb-5">
                                        <div className="flex-1 mr-5">
                                            <Textbox label="VAT Number" fieldname="vat" propName="vat" value={billingData.vat} readOnly={!isAccountOwner} />
                                        </div>
                                        <div className="flex-1">
                                            <OptionalTextbox label="EAN / PEPPOL" fieldname="ean" propName="ean" value={billingData.ean} readOnly={!isAccountOwner} />
                                        </div>
                                    </div>

                                    <div className="flex mb-5">
                                        <div className="flex-auto">
                                            <OptionalTextbox label="Invoice comments" fieldname="invoiceComment" propName="invoiceComment" value={billingData.invoiceComment} readOnly={!isAccountOwner} placeholderValue="E.g. for digital compliance" />
                                            {isAccountOwner ? <SaveButton /> : <SaveButtonDisabled />}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}