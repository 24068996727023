import { useState, useEffect } from "react";
import Card from "../../../Components/Card";
import MarkDown from "../../../Helpers/MarkDown";
import { GetPrerequisites } from "../../../ApiClients/DynamicTemplateClient";

export default function HelpInnerView(props) {
	const [data, setData] = useState("");

	useEffect(() => {
		GetPrerequisites()
			.then((response) => {
				setData(response[0].text);
			})
			.catch((e) => console.log(e));
		try {
			if (!props.default) {
				let item = document.querySelector(`[data-anchor="${props.anchor}"]`);
				item.scrollIntoView({ behavior: "smooth" });
			}
		} catch {}
	}, [props.anchor, props.default]);

	const HelpTopic = (e) => {
		return (
			<div {...e} className="p-6 transition-all transform duration-700 ease-in-out">
				<h3 className="font-medium mb-1">{e.headLine}</h3>
				<div className="text-gray-500">{e.children}</div>
			</div>
		);
	};

	const Contact = () => {
		return (
			<HelpTopic data-anchor="Contact" anchor="Contact" headLine="Contact information">
				{" "}
				<span className="text-gray-500">
					If you are having issues, feel free to contact us on:
					<ul className="list-disc ml-6 pt-4 pb-5">
						<li className="list-item">
							<a alt="email" className="text-dania-500" href="mailto:contact@daniasoftware.com">
								contact@daniasoftware.com
							</a>
						</li>
						<li>Tel.: +45 58503030</li>
					</ul>
					We do our best to answer support tickets within one business day. Reach us by phone Monday through Thursday from 08:00 - 16:00 and Friday from 08:00 - 15:00, Danish local time.
				</span>
			</HelpTopic>
		);
	};

	const Prerequisites = () => {
		return (
			<HelpTopic headLine="Prerequisites">
				<div className="mt-3 min-h-32">{MarkDown(data)}</div>
			</HelpTopic>
		);
	};

	return (
		<div data-anchor="Prerequisites">
			<div className="w-full m-2">
				<Card headLine="System requirements" content={<Prerequisites />}></Card>
				<Card id="Contact" headLine="Contact" content={<Contact />}></Card>
			</div>
		</div>
	);
}
