import { useState, useEffect } from "react";
import { GenerateHtmlForPreview } from "../../ApiClients/OutlookSignatureCloudClient";
import * as graphClient from "../Azure/Graph";

export default function HtmlPreviewer({ signature, azure, azureClientId }) {

    const [view, setView] = useState(0);
    const [html, sethtml] = useState("");
    const [timeoutId, setTimeoutId] = useState(0);

    useEffect(() => {

        //Indsætter dealy på 0.5 sek hver gang signature ændres.
        //Dette gøres for ikke at hente en ny html for hver eneste tegn ændring
        clearTimeout(timeoutId);
        setTimeoutId(setTimeout(GenereatePreview, 500));

    }, [signature]);

    function GenereatePreview() {
        graphClient.GetAzureToken(azureClientId).then((x => {
            GenerateHtmlForPreview(signature, x).then((data) => {
                if (data.html) {
                    sethtml(data.html);
                }
            })
        }))
    }

    function GetAzureAdFieldInSignature() {
        var azureAdFieldNames = [];

        signature.fields.forEach(x => {
            if (x.value.startsWith('%%userprofile_')) {
                var adfieldName = x.value
                adfieldName = adfieldName.replace('%%userprofile_', '')
                adfieldName = adfieldName.replace('%%', '')
                azureAdFieldNames.push(adfieldName)
            }
        })

        return azureAdFieldNames;
    }

    function getSize() {
        switch (view) {
            case 0:
                return "desktop-view";
                break;
            case 1:
                return "tablet-view";
                break;
            case 2:
                return "phone-view";
                break;
        }
    }

    return (
        <div>
            <div className="flex mx-auto w-min mt-10">
                <div className="inline-flex rounded-md shadow-sm" role="group">
                    <button onClick={() => { setView(0); }} type="button" className="h-12 w-28 py-2 px-4 text-sm font-medium text-gray-900 bg-white rounded-l-lg border border-gray-200 hover:bg-gray-100 hover:text-dania-700 border-y-indigo-500" style={{ borderBottom: view === 0 ? "2px solid #0091FF" : "" }}>
                        Computer
                    </button>
                    <button onClick={() => { setView(1); }} type="button" className="h-12 w-28 py-2 px-4 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-dania-700" style={{ borderBottom: view === 1 ? "2px solid #0091FF" : "" }}>
                        Tablet
                    </button>
                    <button onClick={() => { setView(2); }} type="button" className="h-12 w-28 py-2 px-4 text-sm font-medium text-gray-900 bg-white rounded-r-md border border-gray-200 hover:bg-gray-100 hover:text-dania-700" style={{ borderBottom: view === 2 ? "2px solid #0091FF" : "" }}>
                        Phone
                    </button>
                </div>
            </div>
            <div className={"-scale-50 mx-auto my-10 transition-all w-" + getSize()}>
                <div className="bg-white ml-1 shadow sm:rounded-md h-full rounded-t transition-all">
                    <div className="w-full h-11 relative rounded-t-lg bg-gray-700 flex overflow-hidden justify-start items-center space-x-1.5 px-2">
                        <div className="absolute w-full h-full inset-0"></div>
                        <span className="relative w-3 h-3 border-2 rounded-full border-red-600 bg-red-600"></span>
                        <span className="relative w-3 h-3 border-2 rounded-full border-yellow-600 bg-yellow-600"></span>
                        <span className="relative w-3 h-3 border-2 rounded-full border-green-600 bg-green-600"></span>
                    </div>
                    <div className="min-w-full">
                        <div className="block">
                            <div className="pl-6 pr-7 py-4">
                                <div className="min-w-full min-h-full">
                                    <div className="flex flex-col">
                                        <div className="flex w-full mb-2"><div className="flex-none bg-dania-100 text-dania-500 rounded px-7 py-1 text-sm h-7">To</div><div className="flex-1 border-b border-gray-200 border-solid pb-9"></div></div>
                                        <div className="flex w-full mb-6"><div className="flex-none bg-dania-100 text-dania-500 rounded px-7 py-1 text-sm h-7">Cc</div><div className="flex-1 border-b border-gray-200 border-solid pb-9"></div></div>
                                        <div className="min-w-0 flex-1 flex">
                                            <div className="min-w-fit flex-1">
                                                <div className="h-3 bg-gray-100 rounded mb-3 w-1/4"></div>
                                                <div className="h-3 bg-gray-100 rounded mb-3 w-2/4"></div>
                                                <div className="h-3 bg-gray-100 rounded mb-3 w-3/4"></div>
                                                <div className="h-3 bg-gray-100 rounded mb-6 w-3/4"></div>
                                            </div>
                                        </div>
                                        <div dangerouslySetInnerHTML={{ __html: html }} className="overflow-hidden"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}