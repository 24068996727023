/* This example requires Tailwind CSS v2.0+ */
import { Dialog } from '@headlessui/react'
import { BadgeCheckIcon } from '@heroicons/react/outline'

export default function WelcomeMessage(props) {
  
  const headerText = "Welcome to Dania Software!";

  // Derive text from user context
  var textLines;
  switch (props.userInfo.origin) {
    case 0: // FirstUser
      textLines = [
        "Thank you for signing up - your email has been verified. 🎉", 
        "There are just a few quick steps left to complete your account.",
      ];
      break;
    case 1: // Invited
      textLines = [
        "Your request to join your Organisation's team has been accepted. 🎉",
        "There are just a few quick steps left to complete your account.",
      ];
      break;
    case 2: // RequestedAccess
      textLines = [
        "Thank you for accepting the invitation to join your Organisation's team. 🎉 Let’s create your account!",
      ];
      break;
    default:
      console.log("Invalid user origin.");
      break;
  }

  return (
      <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div className="sm:flex sm:items-center sm:flex-col">
            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-14 w-14 rounded-full bg-green-100 sm:mx-0 sm:mb-5">
              <BadgeCheckIcon className="h-6 w-6 text-green-700" aria-hidden="true" />
            </div>
            <div className="mt-3 flex flex-col items-center text-center sm:mt-0 sm:ml-4 sm:text-left">
              <Dialog.Title as="h3" className="text-2xl leading-7 font-bold text-gray-900">
                {headerText}
              </Dialog.Title>
              <div className="mt-2 flex flex-col items-center text-center">
                {textLines ? textLines.map((line, index) => (
                  <p key={"WelcomeLine" + index} className="mt-4 text-sm leading-5 font-normal text-gray-500">
                    {line}
                  </p>
                )) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:justify-center">
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-dania-600 text-base font-normal text-white hover:bg-dania-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dania-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => props.onContinue()}
            ref={props.defaultButtonRef}
          >
            Continue
          </button>
        </div>
      </div>
  )
}