
const fonts = [
    "Arial",
    "Calibri",
    "Cambria",
    "Garamond",
    "Georgia",
    "Gill Sans",
    "Helvetica",
    "Inter",
    "Segoe UI",
    "Tahoma",
    "Times New Roman",
    "Verdana"];

const weight = [
    "Normal",
    "Bold",
];

const fontSize = [
    "4 px",
    "6 px",
    "8 px",
    "10 px",
    "12 px",
    "14 px",
    "16 px",
    "18 px",
    "20 px",
    "22 px",
    "24 px",
];


module.exports = {
    fonts: fonts,
    weight: weight,
    fontSize: fontSize
}



