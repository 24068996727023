import { PlusCircleIcon } from "@heroicons/react/solid";
import ImageActionBar from "../SignatureBuilder/AssetsComponents/ImageActionBar";
import SoMeLinkBox from "../SignatureBuilder/AssetsComponents/SoMeLinkBox";
import RangeSlider from "../Input/RangeSlider";
import React, { useEffect } from "react";
import { Droppable, Draggable } from "react-beautiful-dnd";

export default function Assets({ signature, setSignature, azureClientId }) {

    useEffect(() => {
    }, [signature])

    function UpdateSomeLink(index, Value) {
        signature.soMeLinks[index] = Value;
        setSignature((prevState) => ({ ...prevState, soMeLinks: signature.soMeLinks }));
    }
    function UpdateLogo(logo) {
        var index = signature.images.filter((prop) => prop.id !== logo.id);
        signature.images[index] = logo;
        setSignature((prevState) => ({ ...prevState, images: signature.images }));
    }

    function UpdateCampaign(campaign) {
        var index = signature.campaigns.filter((prop) => prop.id !== campaign.id);
        signature.campaigns[index] = campaign;
        setSignature((prevState) => ({ ...prevState, campaigns: signature.campaigns }));
    }

    function RemoveLogo(logoId) {
        var newImageList = signature.images.filter((prop) => prop.id !== logoId);
        setSignature((prevState) => ({ ...prevState, images: newImageList }));
    }

    function RemoveCampaign(campaignId) {
        var newCampaignsList = signature.campaigns.filter((prop) => prop.id !== campaignId);
        setSignature((prevState) => ({ ...prevState, campaigns: newCampaignsList }));
    }

    function AddLogo(defaultLogo) {
        var newEmptyImage = {
            assetId: "00000000-0000-0000-0000-000000000000",
            default: defaultLogo,
            description: "Logo",
            id: findFreeId(signature.images),
            limitations: [],
            link: "",
            profilePicture: {
                pictureStyle: "circular",
                useProfilePicture: false,
                base64Encoded: ""
            },
        };

        let list = [...signature.images];
        list.push(newEmptyImage);
        setSignature((prevState) => ({ ...prevState, images: list }));
    }

    function AddCampaign(defaultCampaign) {
        var newEmptyCampaign = {
            assetId: "00000000-0000-0000-0000-000000000000",
            default: defaultCampaign,
            id: findFreeId(signature.campaigns),
            limitations: [],
            link: "",
        };

        let list = [...signature.campaigns];
        list.push(newEmptyCampaign);
        setSignature((prevState) => ({ ...prevState, campaigns: list }));
    }

    function AddCustomAsset() {
        let list = signature.customAssets == null ? [] : [...signature.customAssets];

        var newEmptyAsset = {
            assetId: "00000000-0000-0000-0000-000000000000",
            default: false,
            id: findFreeId(list),
            limitations: [],
            link: "",
            profilePicture: {
                pictureStyle: "circular",
                useProfilePicture: false,
                base64Encoded: ""
            },
        };

        list.push(newEmptyAsset);
        setSignature((prevState) => ({ ...prevState, customAssets: list }));
    }

    const onSoMeLinkStatusChange = () => { };



    function UpdateLogoWidth(value) {
        setSignature(prevState => ({ ...prevState, logoWidth: value }));
    }

    function UpdateCampaignWidth(value) {
        setSignature(prevState => ({ ...prevState, campaignWidth: value }));
    }

    var defaultImage = signature.images.length && signature.images.find((x) => x.default === true);
    var defaultCampaign = signature.campaigns.length && signature.campaigns.find((x) => x.default === true);

    const getItemStyle = (isDragging, draggableStyle) => ({
        // Some basic styles to make the items look a bit nicer
        userSelect: "none",
        // Change background colour if dragging
        border: isDragging ? '2px dashed gray' : '',
        padding: '5px',

        // Styles we need to apply on draggables
        ...draggableStyle
    });

    //Remove custom asset
    function RemoveCustomAsset(customAssetId) {
        var newCustomAssetsList = signature.customAssets.filter((prop) => prop.id !== customAssetId);
        setSignature((prevState) => ({ ...prevState, customAssets: newCustomAssetsList }));
    }

    //Update custom asset
    function UpdateCustomAsset(customAsset) {
        var index = signature.customAssets.filter((prop) => prop.id !== customAsset.id);
        signature.customAssets[index] = customAsset;
        setSignature((prevState) => ({ ...prevState, customAssets: signature.customAssets }));
    }

    //const ShowAddCustomAssets = () => {
    //    return (
    //        <div>
    //            <div className="mt-3">
    //                <Droppable droppableId={"customAssetsArea"} isDropDisabled={true}>
    //                    {(provided, snapshot) => (
    //                        <div
    //                            {...provided.droppableProps}
    //                            ref={provided.innerRef}
    //                        >
    //                            <Draggable key={"customAssetsDrag-" + 1} draggableId={"customAssetsDrag-" + 1} index={1}>
    //                                {(provided, snapshot) => (
    //                                    <div
    //                                        ref={provided.innerRef}
    //                                        {...provided.draggableProps}
    //                                        style={getItemStyle(
    //                                            snapshot.isDragging,
    //                                            provided.draggableProps.style
    //                                        )}
    //                                    >
    //                                        <div className="mt-3">
    //                                            <div className="text-gray-900 text-lg font-semibold flex">
    //                                                Images in your signature
    //                                                {signature.markUp.contentType == 1 ?
    //                                                    <div className="flex-1 flex mt-2">
    //                                                        <div className="flex-1"></div>
    //                                                        <div className="flex-none" {...provided.dragHandleProps}>
    //                                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="gray" strokeWidth="2">
    //                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M 12 5 v 0.01 M 12 12 v 0.01 M 12 19 v 0.01 M 12 6 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z m 0 7 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z m 0 7 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z M 12 5 v 0.01 M 12 12 v 0.01 M 12 19 v 0.01 M 21 6 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z m 0 7 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z m 0 7 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z" />
    //                                                            </svg>
    //                                                        </div>
    //                                                    </div>
    //                                                    : null}
    //                                            </div>
    //                                        </div>
    //                                    </div>)}
    //                            </Draggable>
    //                        </div>)}
    //                </Droppable>
    //                <AddButton onClick={() => AddCustomAsset(false)}>Add custom asset</AddButton>
    //            </div>
    //        </div>)
    //};

    return (
        <div>
            <Droppable droppableId={"logoDropArea"} isDropDisabled={true}>
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        <Draggable key={"logoDrag-" + 1} draggableId={"logoDrag-" + 1} index={1}>
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                    )}
                                >
                                    <div className="mt-3">
                                        <div className="text-gray-900 text-lg font-semibold flex">
                                            Images in your signature
                                            {signature.markUp.contentType == 1 ?
                                                <div className="flex-1 flex mt-2">
                                                    <div className="flex-1"></div>
                                                    <div className="flex-none" {...provided.dragHandleProps}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="gray" strokeWidth="2">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M 12 5 v 0.01 M 12 12 v 0.01 M 12 19 v 0.01 M 12 6 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z m 0 7 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z m 0 7 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z M 12 5 v 0.01 M 12 12 v 0.01 M 12 19 v 0.01 M 21 6 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z m 0 7 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z m 0 7 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                                : null}
                                        </div>
                                    </div>
                                </div>)}
                        </Draggable>
                    </div>)}
            </Droppable>

            <div className="mt-3">
                <RangeSlider id="logoWidth" value={signature.logoWidth} min="10" max="500" label="Logo width" labelClassName="text-gray-900 text-sm font-medium" onValueChange={(value) => UpdateLogoWidth(value)} />
            </div>

            <div className="mt-3">
                <div className="text-gray-900 text-sm font-medium">Default image / portrait</div>
                {defaultImage ? (
                    <ImageActionBar headLine="Default image" limitFields={signature.fields} data={defaultImage} onChange={UpdateLogo} azureClientId={azureClientId} removeAction={() => RemoveLogo(defaultImage.id)} />
                ) : (
                    <AddButton onClick={() => AddLogo(true)}>Add default image</AddButton>
                )}
            </div>

            <div className="mt-3">
                <div className="text-gray-900 text-sm ">Alternative images</div>
                {signature.images.filter((x) => x.default === false).map((x, index) => (
                    <ImageActionBar key={index} headLine="Alternative image" limitFields={signature.fields} onChange={UpdateLogo} azureClientId={azureClientId} removeAction={() => RemoveLogo(x.id)} showEdit={true} data={x} />
                ))}
                <AddButton onClick={() => AddLogo(false)}>Add alternative image</AddButton>
            </div>

            <div className="mt-3">
                <div className="text-gray-900 text-lg font-semibold">Socials</div>
            </div>

            {signature.soMeLinks.map((linkData, index) => (
                <SoMeLinkBox key={index} onChange={UpdateSomeLink} index={index} data={linkData} enableDragAndDrop={signature.markUp.contentType == 1} onActiveClick={() => onSoMeLinkStatusChange(index)}></SoMeLinkBox>
            ))}

            <Droppable droppableId={"campaignDropArea"} isDropDisabled={true}>
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        <Draggable key={"campaignDrag-" + 1} draggableId={"campaignDrag-" + 1} index={1}>
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                    )}
                                >
                                    <div className="mt-3">
                                        <div className="text-gray-900 text-lg font-semibold flex">
                                            Campaigns
                                            {signature.markUp.contentType == 1 ?
                                                <div className="flex-1 flex mt-2">
                                                    <div className="flex-1"></div>
                                                    <div className="flex-none" {...provided.dragHandleProps}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="gray" strokeWidth="2">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M 12 5 v 0.01 M 12 12 v 0.01 M 12 19 v 0.01 M 12 6 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z m 0 7 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z m 0 7 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z M 12 5 v 0.01 M 12 12 v 0.01 M 12 19 v 0.01 M 21 6 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z m 0 7 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z m 0 7 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                                : null}
                                        </div>
                                    </div>
                                </div>)}
                        </Draggable>
                    </div>)}
            </Droppable>

            <div className="mt-3">
                <div className="text-gray-900 text-sm font-medium ">Secondary image / banner</div>
                {defaultCampaign ? <ImageActionBar data={defaultCampaign} onChange={UpdateCampaign} removeAction={() => RemoveCampaign(defaultCampaign.id)} /> : <AddButton onClick={() => AddCampaign(true)}>Add secondary image</AddButton>}
            </div>

            <div className="mt-3">
                <RangeSlider id="campaignWidth" value={signature.campaignWidth} min="10" max="500" label="Banner width" labelClassName="text-gray-900 text-sm font-medium" onValueChange={(value) => UpdateCampaignWidth(value)} />
            </div>

            <div className="mt-3">
                <div className="text-gray-900 text-sm ">Alternative banners</div>
                {signature.campaigns
                    .filter((x) => x.default === false)
                    .map((x, index) => (
                        <ImageActionBar key={index} headLine="Alternative banner" limitFields={signature.fields} onChange={UpdateCampaign} removeAction={() => RemoveCampaign(x.id)} showEdit={true} data={x} />
                    ))}
                <AddButton onClick={() => AddCampaign(false)}>Add secondary image</AddButton>
            </div>

            {/* customAssetsArea */}
            <div>
                {signature.markUp.contentType == 1 ?
                    <div>
                        <div className="mt-3">
                            <Droppable droppableId={"customAssetsDropArea"} isDropDisabled={true}>
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        <div className="text-gray-900 text-sm font-medium">Custom assets</div>
                                        {signature.customAssets != null ?
                                            signature.customAssets
                                                .map((x, index) => (
                                                    <Draggable key={"customAssetDrag-" + index} draggableId={"customAssetDrag-" + index} index={index}>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                style={getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style
                                                                )}
                                                            >
                                                                <div className="mt-3">
                                                                    <div className="text-gray-900 text-lg font-semibold flex">
                                                                        Asset {index + 1}
                                                                        <div className="flex-1 flex">
                                                                            <div className="flex-1"></div>
                                                                            <span className="" style={{ fontSize: 12, color: "grey" }}>#{x.id}</span>
                                                                        </div>
                                                                        <div className="flex mt-1">
                                                                            <div className="flex-1"></div>
                                                                            <div className="flex-none" {...provided.dragHandleProps}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="gray" strokeWidth="2">
                                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M 12 5 v 0.01 M 12 12 v 0.01 M 12 19 v 0.01 M 12 6 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z m 0 7 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z m 0 7 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z M 12 5 v 0.01 M 12 12 v 0.01 M 12 19 v 0.01 M 21 6 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z m 0 7 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z m 0 7 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z" />
                                                                                </svg>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <ImageActionBar key={index} headLine="Custom Assets" limitFields={signature.fields} onChange={UpdateCustomAsset} removeAction={() => RemoveCustomAsset(x.id)} showEdit={true} data={x} />
                                                            </div>)}
                                                    </Draggable>
                                                ))
                                            : ""}
                                    </div>)}
                            </Droppable>
                            <AddButton onClick={() => AddCustomAsset(false)}>Add custom asset</AddButton>
                        </div>
                    </div> : null}
            </div>

        </div>
    );

    function AddButton(props) {
        return (
            <button
                onClick={() => props.onClick()}
                type="button"
                className="mt-3 mb-3  w-full items-center text-justify px-4 py-2 font-medium border border-transparent text-base rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dania-500 bg-dania-100 text-dania-500 hover:bg-dania-200"
            >
                <div className="flex items-center justify-center">
                    <PlusCircleIcon className="h-4 w-4 mr-3 text-dania-500" aria-hidden="true" />
                    <div>{props.children}</div>
                </div>
            </button>
        );
    }

    function findFreeId(array) {
        const sortedArray = array
            .slice() // Make a copy of the array.
            .sort(function (a, b) {
                return a.id - b.id;
            }); // Sort it.
        let previousId = 0;
        for (let element of sortedArray) {
            if (element.id !== previousId + 1) {
                // Found a gap.
                return previousId + 1;
            }
            previousId = element.id;
        }
        // Found no gaps.
        return previousId + 1;
    }
}