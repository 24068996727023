/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { UsersIcon, CheckCircleIcon } from '@heroicons/react/outline'
import { useCallback, useState } from 'react';
import { InviteUser } from '../../ApiClients/CustomerClient';

export default function Invite(props) {
  const [currentEmail, setCurrentEmail] = useState("");
  const [people] = useState(props.inviteState);

  // Force Re-render
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  
  function HandleSubmit(e) {
    e.preventDefault();
    
    if (people.invitees)
    {
      if (!people.invitees.includes(currentEmail))
        people.invitees.push(currentEmail);
    }
    else
      people.invitees = [currentEmail];

    setCurrentEmail("");
    forceUpdate();

    InviteUser(currentEmail);
  }

  return (
    <div className="max-w-lg mx-auto mb-4">
      <div>
        <div className="text-center flex flex-col items-center mb-6">
          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-14 w-14 rounded-full bg-dania-100 sm:mx-0 sm:my-4">
            <UsersIcon className="h-8 w-8 text-dania-700" aria-hidden="true" />
          </div>
          <h2 className="text-lg font-medium text-gray-900">
            Invite team members
          </h2>
          <p className="mt-1 text-sm text-gray-500">
            Invite team members from your company / organisation to your team. All team members have access to the same trials,
            licenses and downloads. This is especially helpful if members of IT are required to install software.
          </p>
        </div>

        <form onSubmit={(e) => {HandleSubmit(e)}}>
          <div className="flex flex-row justify-center">
          
            <label htmlFor="email" className="sr-only">
              Email address
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className="shadow-sm focus:ring-dania-500 focus:border-dania-500 block w-full sm:w-80 sm:text-sm border-gray-300 rounded-md"
              placeholder="you@example.com"
              value={currentEmail ?? ""}
              onChange={(e) => setCurrentEmail(e.target.value)}
            />
            {currentEmail === "" || people.invitees?.includes(currentEmail) ?
              <button
                disabled
                type="submit"
                className="ml-4 flex-shrink-0 px-2 py-2 border border-transparent text-xs font-normal rounded-md shadow-sm text-gray-500 bg-grey-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dania-500"
              >
                Send invite
              </button>
            :
              <button
                type="submit"
                className="ml-4 flex-shrink-0 px-2 py-2 border border-transparent text-xs font-normal rounded-md shadow-sm text-dania-700 bg-dania-100 hover:bg-dania-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dania-500"
              >
                Send invite
              </button>
            }
          </div>
        </form>
      </div>
      {people?.invitees?.length > 0 ?
        <div className="mt-10">
          <h3 className="text-xs font-semibold text-gray-500 uppercase tracking-wide">
            Team members added
          </h3>
          <ul className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
            {people.invitees.map((person, personIdx) => (
              <li key={personIdx} className="py-4 flex items-center justify-between space-x-3">
                <div className="min-w-0 flex-1 flex items-center space-x-3">
                  <div className="min-w-0 flex-1">
                    <p className="text-sm font-medium text-gray-900 truncate">{person}</p>
                  </div>
                </div>
                <div className="flex-shrink-0">
                  <CheckCircleIcon className="h-4 w-4 text-dania-400" />
                </div>
              </li>
            ))}
          </ul>
        </div>
      : null }
    </div>
  )
}
