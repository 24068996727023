

// MSAL imports
import { EventType, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";


let _msalInstance = null;

export const msalInstance = (clientId) => {
    if (_msalInstance !== null) {        
        return _msalInstance;
    }

    var config = msalConfig;
    config.auth.clientId = clientId;
    _msalInstance = new PublicClientApplication(config);

    // Default to using the first account if no account is active on page load
    if (!_msalInstance.getActiveAccount() && _msalInstance.getAllAccounts().length > 0) {
        // Account selection logic is app dependent. Adjust as needed for different use cases.
        _msalInstance.setActiveAccount(_msalInstance.getAllAccounts()[0]);
    }



    // Optional - This will update account state if a user signs in from another tab or window
    _msalInstance.enableAccountStorageEvents();

    _msalInstance.addEventCallback((event) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
            const account = event.payload.account;
            _msalInstance.setActiveAccount(account);
        }
    });

    return _msalInstance
}