import PageHeader from "../../../Components/PageHeader";
import React from "react";
import { useState } from "react";
import Submenu from "../../../Components/Submenu";
import HelpInnerView from "./HelpInnerView";

export default function Help(params) {
	const [anchor, setAnchor] = useState("Prerequisites");
	const Default = <HelpInnerView onNavigation={(e) => params.onNavigation(e)} anchor={anchor} />;
	const CommonItem = (e) => <HelpInnerView onNavigation={(e) => params.onNavigation(e)} anchor={e.anchor} />;
	const [main] = useState(Default);

	const navigation = [
		{
			name: <span className="font-sm font-medium">System requirements</span>,
			items: [{ name: "Prerequisites", contentComponent: <CommonItem anchor="Prerequisites" /> }],
		},
		{
			name: <span className="font-sm font-medium">Contact</span>,
			items: [{ name: "Contact information", contentComponent: <CommonItem anchor="Contact" /> }],
		},
	];

	return (
		<div>
			<PageHeader headLine="Help for DynamicTemplate"></PageHeader>
			<div className="md:flex block">
				<div className="block md:flex-initial w-72 mr-5 mb-12">
					<Submenu
						activeComponent={Default}
						navigation={navigation}
						backUrl={null}
						onNavigation={(e) => {
							setAnchor(e.props.anchor);

							// Scroll to anchor
							let item = document.querySelector(`[data-anchor="${e.props.anchor}"]`);
							item.scrollIntoView({ behavior: "smooth" });

							// Activate color fade-in-out
							item.classList.add("bg-dania-100");
							setTimeout(() => {
								item.classList.remove("bg-dania-100");
							}, 500);
						}}
					></Submenu>
				</div>
				<div className="flex-1">{main}</div>
			</div>
		</div>
	);
}