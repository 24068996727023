import CheckBox from "../Input/CheckBox";
import SimpleCombobox from "../Input/SimpleCombobox";
import { MinusCircleIcon } from "@heroicons/react/outline";
import { PlusCircleIcon } from "@heroicons/react/solid";
import { Droppable, Draggable } from "react-beautiful-dnd";

export default function Details({ signature, setSignature, disableDropArea }) {

    function ChangeFieldValue(index, value) {
        let fieldsClone = [...signature.fields];
        fieldsClone.filter(x => x.id === index)[0].value = value.target.value;
        setSignature(prevState => ({ ...prevState, fields: fieldsClone }));
    }

    function ChangeFieldCheckBox(index, value) {
        let fieldsClone = [...signature.fields];
        fieldsClone.filter(x => x.id === index)[0].allowOverwrite = value;
        setSignature(prevState => ({ ...prevState, fields: fieldsClone }));
    }

    function RemoveField(id) {
        var newFieldsList = signature.fields.filter((prop) => prop.id !== id);
        setSignature(prevState => ({ ...prevState, fields: newFieldsList }));
    }

    function UpdateLabel(index, value) {
        let fieldsClone = [...signature.fields];
        fieldsClone.filter(x => x.id === index)[0].name = value.target.value;
        setSignature(prevState => ({ ...prevState, fields: fieldsClone }));
    }

    function AddButton(props) {
        return (
            <button
                onClick={() => {
                    var idAndNum = getIdAndNum();
                    var newField = {
                        allowOverwrite: false,
                        disabled: false,
                        id: idAndNum.id,
                        name: "Custom field " + idAndNum.num,
                        value: "",
                        custom: true,
                    };
                    let fieldsClone = [...signature.fields];
                    fieldsClone.push(newField);
                    setSignature(prevState => ({ ...prevState, fields: fieldsClone }));
                }}
                type="button"
                className="mt-3 mb-3  w-full items-center text-justify px-4 py-2 font-medium border border-transparent text-base rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dania-500 bg-dania-100 text-dania-500 hover:bg-dania-200"
            >
                <div className="flex items-center justify-center">
                    <div>
                        <PlusCircleIcon className="h-4 w-4 mr-3 text-dania-500" aria-hidden="true" />
                    </div>
                    <div>{props.children}</div>
                </div>
            </button>
        );
    }

    function getIdAndNum() {
        let fieldsClone = [...signature.fields];

        let idMax = fieldsClone.length > 0 ? Math.max.apply(Math, fieldsClone.map(function (o) { return o.id; })) + 1 : 0;
        let customFileds = fieldsClone.filter(x => x.custom && x.name.startsWith("Custom field "));
        let numberMax = firstMissingPositive(customFileds.map(function (o) { return parseInt(o.name.split(' ')[o.name.split(' ').length - 1]) }));

        return { id: idMax, num: numberMax };
    }

    let firstMissingPositive = function (A) {
        let j = 0;
        for (let i = 0; i < A.length; i++) {
            if (A[i] <= 0) {
                let temp = A[j]
                A[j] = A[i]
                A[i] = temp;
                j++;
            }
        }

        let arr = A.slice(j);

        for (let i = 0; i < arr.length; i++) {
            if (arr[Math.abs(arr[i]) - 1] > 0) {
                arr[Math.abs(arr[i]) - 1] = -arr[Math.abs(arr[i]) - 1]
            }
        }

        let k = 0;
        while (true) {
            if (k === arr.length || arr[k] > 0) {
                break;
            }
            k++
        }
        return ++k
    }

    // ----------- Drag and drop start

    const getItemStyle = (isDragging, draggableStyle) => ({
        // Some basic styles to make the items look a bit nicer
        userSelect: "none",

        // Change background colour if dragging
        border: isDragging ? '2px dashed gray' : '',
        padding: isDragging ? '5px' : '5px',
        marginBottom: isDragging ? '' : '1.25rem',

        // Styles we need to apply on draggables
        ...draggableStyle
    });

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "" : "",
    });

    var aadFieldList = [
        { id: 0, label: "AD / First name", value: "%%userprofile_givenName%%" },
        { id: 1, label: "AD / Last name", value: "%%userprofile_surname%%" },
        { id: 2, label: "AD / Full name", value: "%%userprofile_displayName%%" },
        { id: 3, label: "AD / Job title", value: "%%userprofile_jobTitle%%" },
        { id: 4, label: "AD / Mail", value: "%%userprofile_mail%%" },
        { id: 5, label: "AD / Mobile phone", value: "%%userprofile_mobilePhone%%" },
        { id: 6, label: "AD / Business phones", value: "%%userprofile_businessPhones%%" },
        { id: 7, label: "AD / Office location", value: "%%userprofile_officeLocation%%" },
    ];

    return <div className="flex flex-col">
        <div className="pb-3 pt-3"><div className="text-gray-900 text-lg font-semibold">Details of your signature</div></div>
        <Droppable droppableId="droppableTop" isDropDisabled={true}>
            {(provided, snapshot) => (
                <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                >
                    {signature.fields.filter(x => !x.custom).map((x, index) => (
                        <Draggable key={"field-" + x.id} draggableId={"field-" + x.id} index={index}>
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                    )}
                                >
                                    <div key={x.id}>
                                        <div className="flex">
                                            <div className="flex-1 flex">
                                                <SimpleCombobox menuItems={aadFieldList} label={x.name} allowLinking={x.allowLinking} fieldName={x.name} defaultValue={x.value} onValueChange={(value) => ChangeFieldValue(x.id, value)} />
                                                {signature.markUp.contentType == 1 ?
                                                    <p style={{ fontSize: 10, color: "grey" }}>#{x.id}</p>
                                                    :
                                                    null
                                                }
                                            </div>
                                            <div className="flex-none w-9 mb-2 flex flex-col">
                                                {signature.markUp.contentType == 1 ?
                                                    <div className="flex-none flex">
                                                        <div className="flex-1"></div>
                                                        <div className="flex-none" {...provided.dragHandleProps}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="gray" strokeWidth="2">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M 12 5 v 0.01 M 12 12 v 0.01 M 12 19 v 0.01 M 12 6 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z m 0 7 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z m 0 7 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z M 12 5 v 0.01 M 12 12 v 0.01 M 12 19 v 0.01 M 21 6 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z m 0 7 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z m 0 7 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z" />
                                                            </svg>
                                                        </div>
                                                    </div> : null}

                                                <div className="flex-1"></div>
                                                {!x.custom ? null :
                                                    <div title="Remove field" className="cursor-pointer" onClick={() => RemoveField(x.id)}>
                                                        <MinusCircleIcon className="h-5 w-5 ml-4 text-gray-500 align-bottom" aria-hidden="true" />
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <CheckBox label="Allow users to manually edit" id={x.id} value={x.allowOverwrite} checked={x.allowOverwrite} onChange={(value) => ChangeFieldCheckBox(x.id, value)} />
                                    </div>
                                </div>)}
                        </Draggable>
                    ))}
                </div>)}
        </Droppable>

        <Droppable droppableId="droppable" isDropDisabled={disableDropArea}>
            {(provided, snapshot) => (
                <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                >
                    {signature.fields.map((field, index) => (
                        <div key={"droppable-" + field.id}>
                            {field.custom ?
                                <Draggable key={"field-" + field.id} draggableId={"field-" + field.id} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                        >
                                            <div key={"field-" + field.id}>
                                                <div className="flex">
                                                    <div className="flex-1 flex">
                                                        <SimpleCombobox menuItems={aadFieldList} label={field.name} defaultValue={field.value} allowLinking={field.allowLinking} className="flex-1" onValueChange={(value) => ChangeFieldValue(field.id, value)} onLabelChange={(value) => UpdateLabel(field.id, value)} />
                                                        {signature.markUp.contentType == 1 ?
                                                            <p style={{ fontSize: 10, color: "grey" }}>#{field.id}</p>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                    <div className="flex-none w-9 mb-2 flex flex-col">
                                                        <div className="flex-none flex">
                                                            <div className="flex-1"></div>
                                                            <div className="flex-none" {...provided.dragHandleProps}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="gray" strokeWidth="2">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" d="M 12 5 v 0.01 M 12 12 v 0.01 M 12 19 v 0.01 M 12 6 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z m 0 7 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z m 0 7 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z M 12 5 v 0.01 M 12 12 v 0.01 M 12 19 v 0.01 M 21 6 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z m 0 7 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z m 0 7 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <div className="flex-1"></div>
                                                        {!field.custom ? null :
                                                            <div title="Remove field" className="cursor-pointer flex-none" onClick={() => RemoveField(field.id)}>
                                                                <MinusCircleIcon className="h-5 w-5 ml-4 text-gray-500 align-bottom" aria-hidden="true" />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <CheckBox label="Allow users to manually edit" id={field.id} value={field.allowOverwrite} checked={field.allowOverwrite} onChange={(value) => ChangeFieldCheckBox(field.id, value)} />
                                            </div>
                                        </div>
                                    )}
                                </Draggable> : null}
                        </div>
                    ))}

                    {provided.placeholder}

                </div>
            )}
        </Droppable>
        <AddButton>Add field</AddButton>
    </div>
}