/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { HomeIcon, MenuIcon, UserIcon, XIcon, ViewGridAddIcon, UsersIcon, SupportIcon, CogIcon, LogoutIcon } from "@heroicons/react/outline";
import dtLogo from "../Assets/dynamictemplatecubes.svg";
import { Link } from "react-router-dom";
import AccountFinalizer from "./Account/AccountFinalizer";
import { ApplicationPaths } from "../api-authorization/ApiAuthorizationConstants";


const navigation = [
    { name: "Home", href: "/home", icon: HomeIcon, toolTip: "Home" },
    { name: "Products", href: "/products", icon: ViewGridAddIcon, toolTip: "Apps" },
    { name: "Users", href: "/team", icon: UsersIcon, toolTip: "Team" },
];

export default function Layout(props) {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [accountMenu, setAccountMenu] = useState(false);
    const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
    return (
        <>
            <div className="h-full flex">
                {/* Mobile menu overlay*/}
                <Transition.Root show={mobileMenuOpen} as={Fragment}>
                    <Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={setMobileMenuOpen}>
                        <Transition.Child as={Fragment} enter="transition-opacity ease-linear duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="transition-opacity ease-linear duration-300" leaveFrom="opacity-100" leaveTo="opacity-0">
                            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                        </Transition.Child>
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white focus:outline-none">
                                <Transition.Child as={Fragment} enter="ease-in-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in-out duration-300" leaveFrom="opacity-100" leaveTo="opacity-0">
                                    <div className="absolute top-0 right-0 -mr-12 pt-4">
                                        <button type="button" className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" onClick={() => setMobileMenuOpen(false)}>
                                            <span className="sr-only">Close sidebar</span>
                                            <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div className="pt-5 pb-4">
                                    <div className="flex-shrink-0 flex items-center px-4">
                                        <img className="h-8 w-auto" src={dtLogo} alt="Dynamic Template Logo" />
                                    </div>
                                    <nav aria-label="Sidebar" className="mt-5">
                                        <div className="px-2 space-y-1">
                                            {navigation.map((item) => (
                                                <a key={item.name} href={item.href} className="group p-2 rounded-md flex items-center text-base font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900 has-tooltip">
                                                    <item.icon className="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                                    {item.name}
                                                </a>
                                            ))}
                                        </div>
                                    </nav>
                                </div>
                                <div className="flex-shrink-0 flex-col flex border-t border-gray-200 p-4">
                                    <a href="/Help" className="flex-shrink-0 group block pb-2">
                                        <div className="flex items-center">
                                            <div>
                                                <SupportIcon className="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-500" />
                                            </div>
                                            <div>
                                                <p className=" font-medium text-gray-600 hover:text-gray-900">Help</p>
                                            </div>
                                        </div>
                                    </a>
                                    <a href="/Account" className="flex-shrink-0 group pb-2 pt-2 block">
                                        <div className="flex items-center">
                                            <div>
                                                <UserIcon className="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-500" />
                                            </div>
                                            <div>
                                                <p className=" font-medium text-gray-600 hover:text-gray-900">Account Settings</p>
                                            </div>
                                        </div>
                                    </a>
                                    <Link tag={Link} to={logoutPath} className="flex-shrink-0 group pb-2 pt-2 block">
                                        <div className="flex items-center">
                                            <div>
                                                <LogoutIcon className="mr-4 h-6 w-6 text-gray-400 group-hover:text-gray-500" />
                                            </div>
                                            <div>
                                                <p className=" font-medium text-gray-600 hover:text-gray-900">Sign out</p>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </Transition.Child>
                        <div className="flex-shrink-0 w-14" aria-hidden="true">
                            {/* Force sidebar to shrink to fit close icon */}
                        </div>
                    </Dialog>
                </Transition.Root>

                {/* Static sidebar for desktop */}
                <div className="hidden lg:flex lg:flex-shrink-0">
                    <div className="flex flex-col w-18 border-r">
                        <div className="flex-1 flex flex-col min-h-0 overflow-y-auto bg-white">
                            <div className="flex-1">
                                <div className="bg-white py-4 flex items-center justify-center">
                                    <img className="h-8 w-auto" src={dtLogo} alt="Dynamic Template Logo" />
                                </div>
                                <nav aria-label="Sidebar" className="py-6 flex flex-col items-center space-y-3">
                                    {navigation.map((item, index) =>
                                        <div className="relative flex flex-col items-center group">
                                            {item.href === props.active ? (
                                                <Link to={item.href} key={item.name + index} className="flex items-center p-4 rounded-lg text-dania-500 bg-dania-100">
                                                    {" "}
                                                    <item.icon className="h-5 w-5" aria-hidden="true" />
                                                    <span className="sr-only">{item.name}</span>
                                                </Link>
                                            ) : (
                                                <Link to={item.href} key={item.name + index} className="flex items-center p-4 rounded-lg text-gray-500 hover:bg-dania-100">
                                                    {" "}
                                                    <item.icon className="h-5 w-5" aria-hidden="true" />
                                                    <span className="sr-only">{item.name}</span>
                                                </Link>
                                            )}
                                            <div className="absolute top-0 flex flex-col items-center -mt-4 hidden group-hover:flex">
                                                <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-gray-600 shadow-lg rounded-md">{item.toolTip}</span>
                                            </div>
                                        </div>
                                    )}
                                </nav>
                            </div>
                            <div className="flex-shrink-0 flex flex-col pb-5 border-t pt-4 mr-2 ml-2 justify-center">
                                
                                <div className="relative flex flex-col items-center group">
                                    <Link to="/help" className={`${"/help" === props.active ? "bg-dania-100   text-dania-500" : "hover:bg-dania-100  text-gray-500 "} flex items-center p-4 rounded-lg mb-2`}>
                                        <SupportIcon className="block mx-auto h-5 w-5 rounded-full" />
                                        <div className="sr-only">
                                            <p>Help</p>
                                        </div>
                                    </Link>
                                    <div className="absolute top-0 flex flex-col items-center -mt-4 hidden group-hover:flex">
                                        <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-gray-600 shadow-lg rounded-md">Help</span>
                                    </div>
                                </div>

                                <div className="realative">
                                    <div className="relative flex flex-col items-center group">
                                        <button className={`${"/Account" === props.active ? "bg-dania-100   text-dania-500" : "hover:bg-dania-100  text-gray-500 "} flex items-center p-4 rounded-lg  `} onClick={() => setAccountMenu(!accountMenu)}>
                                            <UserIcon className="block mx-auto h-5 w-5 rounded-full" />
                                        </button>
                                        <div className="absolute top-0 flex flex-col items-center -mt-4 hidden group-hover:flex">
                                            <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-gray-600 shadow-lg rounded-md">Account</span>
                                        </div>
                                    </div>

                                    <Transition
                                        show={accountMenu}
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <div className="absolute bottom-2 left-6 text-left rounded-md shadow-xl drop-shadow-xl border border-1 border-gray-200 ml-10 bg-white z-40 p-2">
                                            <Link to="/Account" onClick={() => setAccountMenu(!accountMenu)} className="hover:bg-dania-100  text-gray-500 flex items-center pl-6 pr-8 pb-2 pt-2 rounded-lg">
                                                <CogIcon className="block mx-auto h-5 w-5 rounded-full" />
                                                <div className="ml-2">
                                                    <p>Settings</p>
                                                </div>
                                            </Link>

                                            <Link tag={Link} to={logoutPath} className="hover:bg-dania-100  text-gray-500 flex items-center pl-6 pr-8 pb-2 pt-2 rounded-lg">
                                                <LogoutIcon className="block h-5 w-5 rounded-full" />
                                                <div className="ml-2">
                                                    <p>Sign out</p>
                                                </div>
                                            </Link>
                                        </div>
                                    </Transition>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <AccountFinalizer />

                <div className="flex-1 min-w-0 flex flex-col overflow-hidden">
                    {/* Mobile top navigation */}
                    <div className="lg:hidden">
                        <div className="bg-white py-2 px-4 flex items-center justify-between sm:px-6 lg:px-8">
                            <div>
                                <img className="h-8 w-auto" src={dtLogo} alt="Workflow" />
                            </div>
                            <div>
                                <button
                                    type="button"
                                    className="mr-3 h-12 w-12 inline-flex items-center justify-center bg-dania-100 rounded-md text-dania-500 hover:bg-dania-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                    onClick={() => setMobileMenuOpen(true)}
                                >
                                    <span className="sr-only">Open sidebar</span>
                                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                    </div>

                    <main className="flex-1 flex overflow-y-auto">
                        <div className="pl-6 pt-6 h-full w-full">
                            <props.data></props.data>
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
}
