import PageHeader from "../../../Components/PageHeader";
import React from "react";
import NotificationBar from "../../../Components/NotificationBar";
import { ExclamationCircleIcon, DownloadIcon } from "@heroicons/react/solid";
import ProductInformationList from "../../../Components/Lists/ProductInformationList";
import { GetReleaseNotes, GetLatestStableInstallFile } from "../../../ApiClients/DynamicTemplateClient";
import MarkDown from "../../../Helpers/MarkDown";
import { useState, useEffect } from "react";
import FileDownloader from "../../../Helpers/FileDownloader";

export default function ReleaseNotes() {
  const [data, setData] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    const mapItem = (x, index, latestInstallFile) => {
      return {
        //Map object
        content: CreateContent(x.text, index === 0, latestInstallFile),
        version: x.version,
        groupHeadLine: "What's new in " + x.version,
      };
    };

    const fetchData = async () => {
      try {
        //Fetch install files from API
        let response = await GetReleaseNotes();
        let latestInstallFile = await GetLatestStableInstallFile();

        if (response.error === undefined) {
          var mappedItems = response.map((x, index) => mapItem(x, index, latestInstallFile));
          setData(mappedItems);
        } else {
          setError(response.error.message);
        }
      } catch {
        setError("Unable to fetch release notes data");
      }
    };

    function CreateContent(markdown, showButton, latestInstallFile) {
      return (
        <div className="px-2">
          {MarkDown(markdown)}
          {showButton ? createButtonContent(latestInstallFile) : null}
        </div>
      );
    }

    const createButtonContent = (installFile) => {
      return (
        <button
          onClick={() => FileDownloader(installFile.downloadUrl, installFile.filename)}
          className="inline-flex items-center px-4 py-2 border font-medium border-transparent text-sm rounded-md shadow-sm text-white bg-dania-600 hover:bg-dania-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dania-500"
        >
          <DownloadIcon className="h-4 w-4 mr-2" />
          Download latest update
        </button>
      );
    };

    fetchData();
  }, []);

  return (
    <div className="w-full">
      {
        <div>
          <PageHeader headLine="Release notes for DynamicTemplate"></PageHeader>
          {error ? (
            <div className="pt-1 pb-6">
              <NotificationBar color="red" text={error} icon={ExclamationCircleIcon} />
            </div>
          ) : (
            <div>
              <ProductInformationList productInformation={data}></ProductInformationList>
            </div>
          )}
        </div>
      }
    </div>
  );
}
