import PageHeader from "../../../Components/PageHeader";
import React from "react";
import NotificationBar from "../../../Components/NotificationBar";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import ProductInformationList from "../../../Components/Lists/ProductInformationList";
import { GetRoadMap } from "../../../ApiClients/AssistClient";
import MarkDown from "../../../Helpers/MarkDown";
import { useState, useEffect } from "react";
import { GetLicense } from "../../../ApiClients/AssistClient";

export default function RoadMap() {
    const [data, setData] = useState([]);
    const [error, setError] = useState("");
    const [license, setLicense] = useState({});

    useEffect(() => {
        try {
            GetLicense()
                .then((data) => {
                    setLicense(data);
                });
            //Fetch install files from API
            GetRoadMap().then((response) => {
                if (response.error === undefined) {
                    var temp = response.map((x, index) => mapItem(x, index));
                    setData(temp);
                } else {
                    setError(response.error.message);
                }
            });
        } catch {
            setError("Unable to fetch roadmap data");
        }
    }, []);

    const mapItem = (x, index) => {
        return {
            //Map object
            content: MarkDown(x.text),
            version: x.version,
            groupHeadLine: "Roadmap",
        };
    };
    return (
        <div className="w-full">
            {
                <div>
                    <PageHeader headLine={"Roadmap for Assist" + (license.licenseType == 100 ? " Pro" : "")}></PageHeader>
                    {error ? (
                        <div className="pt-1 pb-6">
                            <NotificationBar color="red" text={error} icon={ExclamationCircleIcon} />
                        </div>
                    ) : (
                        <div>
                            <ProductInformationList productInformation={data}></ProductInformationList>
                        </div>
                    )}
                </div>
            }
        </div>
    );
}
