import { CalendarIcon, DownloadIcon, ArrowRightIcon } from "@heroicons/react/outline";
import BlobDownloader from "../../Helpers/BlobDownloader";
import { useState } from "react";

export default function DownloadList(props) {
    const [startIndex, setStartIndex] = useState(0);
    const [downloading, setDownloading] = useState("");

    const nextSlice = () => {
        setStartIndex(startIndex + getPageSize());
    };

    const getPageSize = () => {
        if (props.pageSize) {
            return props.pageSize;
        } else {
            return 10;
        }
    };

    const showNextSliceButton = () => {
        try {
            return startIndex + getPageSize() < props.applications.length;
        } catch {
            return false;
        }
    };

    function replaceColor(color, classes) {
        return color ? classes.replaceAll("dania", color) : classes;
    }

    function downloadBlob(base64content, fileName, identifier) {
        // Set downloading indicator
        setDownloading(identifier);

        BlobDownloader(base64content, fileName).then(() => setDownloading(""));
    }

    function Primary(props) {
        let application = props.application;
        let index = props.index;
        return (
            <li key={application.name + index}>
                <div className="block hover:bg-gray-50">
                    <div className="flex items-center px-4 py-4 sm:px-6">
                        <div className="min-w-0 flex-1 flex items-center">
                            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                <div>
                                    <div>
                                        <span className="text-sm font-medium text-gray-900 truncate mr-2">
                                            {application.name}{application.version ? " version " + application.version : null}
                                        </span>
                                        {application.badgeText ? <span className={replaceColor(application.badgeColor, "flex-shrink-0 inline-block px-2 py-0.5 text-dania-700 text-xs font-medium bg-dania-100 rounded-full")}>{application.badgeText}</span> : null}
                                    </div>

                                    <div className="mt-2 flex items-center text-sm text-gray-500">
                                        <div>{application.description}</div>
                                    </div>
                                </div>

                                {application.releaseDate ? (
                                    <div className="hidden md:block">
                                        <div>
                                            <p className="mt-2 flex items-center text-sm text-gray-400 ">
                                                <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5  text-gray-500" aria-hidden="true" />
                                                {application.releaseDate}
                                            </p>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        {application.downloadUrl ? (
                            <div>
                                <a href={application.downloadUrl} download={application.fileName} className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-dania-600 hover:bg-dania-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dania-500">
                                    <ResolveDownloadIcon identifier={props.key} />
                                    Download
                                </a>
                            </div>
                        ) : null}
                        {application.base64content ? (
                            <div>
                                <button onClick={() => downloadBlob(application.base64content, application.fileName, props.key)} type="button" className={application.archived ? archivedButtonStyle : currentButtonStyle}>
                                    <ResolveDownloadIcon identifier={props.key} />
                                    Download
                                </button>
                            </div>
                        ) : null}
                    </div>
                </div>
            </li>
        );
    }

    function ResolveDownloadIcon(e) {
        // Add animation if downloading
        return downloading === e.identifier ? <DownloadIcon className="animate-bounce delay-100 h-4 w-4 mr-2" /> : <DownloadIcon className="h-4 w-4 mr-2" />;
    }

    const archivedButtonStyle = "inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-dania-500 hover:text-white bg-dania-100 hover:bg-dania-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dania-500";
    const currentButtonStyle = "inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-dania-600 hover:bg-dania-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dania-500";

    function LoadingPlaceholder() {
        return (
            <div className="min-w-full">
                <div className="block hover:bg-gray-50">
                    <div className="flex items-center px-4 py-4 sm:px-6">
                        <div className="min-w-0 flex-1 flex items-center">
                            <div className="min-w-0 animate-pulse  flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                                <div>
                                    <div>
                                        <span className="text-sm font-medium text-gray-900 truncate mr-2">
                                            <div className="h-2 bg-gray-400 rounded mb-2"></div>
                                        </span>
                                        <div className="h-2 bg-gray-400 rounded mb-6"></div>
                                    </div>

                                    <div className="md:hidden sm:block animate-pulse">
                                        <p className="flex animate-pulse text-sm text-gray-500 mt-2">
                                            <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 " aria-hidden="true" />
                                        </p>
                                    </div>
                                </div>
                                <div className="hidden md:block animate-pulse">
                                    <div>
                                        <p className="flex animate-pulse text-sm text-gray-500 mt-2">
                                            <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 " aria-hidden="true" />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="animate-pulse">
                            <div className="w-28">
                                <div className="h-2 bg-gray-400 rounded mb-6"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="pb-8 w-full">
            <div className="bg-white shadow ml-1 sm:rounded-md h-max  rounded-t">
                <div className="px-4 py-5 sm:px-6 bg-gray-100 border-b rounded-t ">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">{props.headLine} </h3>
                </div>
                <ul className="divide-y divide-gray-200">{props.applications !== null ? props.applications.slice(0, startIndex + getPageSize()).map((application, index) => <Primary key={application.name + index} application={application} index={index} />) : null}</ul>
                {props.applications.length < 1 ? <LoadingPlaceholder /> : null}
            </div>

            {showNextSliceButton() ? (
                <div className="grid  place-content-center m-5 text-dania-700">
                    <button
                        onClick={() => nextSlice()}
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent font-medium text-sm rounded-md shadow-sm text-dania-500 hover:text-white bg-dania-100 hover:bg-dania-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dania-500"
                    >
                        {props.showMoreText}
                        <ArrowRightIcon className="h-4 w-4 ml-2" />
                    </button>
                </div>
            ) : null}
        </div>
    );
}