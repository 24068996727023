import { Transition } from "@headlessui/react";

export default function PageHeader(props) {
	return (
		<Transition
			appear={true}    
			show={true}
			enter="transition-opacity duration-500"
			enterFrom="opacity-0"
			enterTo="opacity-100"
			leave="transition-opacity duration-150"
			leaveFrom="opacity-100"
			leaveTo="opacity-0"
		>
			<div className="pb-3 mb-5 border-b border-gray-200">
				<h3 className="text-3xl leading-9 font-bold text-gray-900">{props.headLine}</h3>
			</div>
		</Transition>
	);
}
