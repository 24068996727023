import PageHeader from "../../../Components/PageHeader";
import React, { Fragment, useEffect } from "react";
import NotificationBar from "../../../Components/NotificationBar";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import CardWithList from "../../../Components/CardWithList";
import { useState } from "react";
import { GetLicense, ExportLicense } from "../../../ApiClients/AssistClient";
import DateFormat from "../../../Helpers/DateFormater";
import DownloadButton from "../../../Components/Buttons/DownloadButton";

export default function License(props) {
    const [error, setError] = useState();
    const [license, setLicense] = useState({});
    const [licenseExport, setlicenseExport] = useState();

    useEffect(() => {
        GetLicense()
            .then((data) => {
                if (data.error) {
                    setError("Unable to fetch data");
                }
                setLicense(data);
                if (data.offlineMode) {
                    ExportLicense().then((x) => {
                        setlicenseExport(x);
                    })
                }
            })
            .catch((error) => {
                setError("Unable to fetch data: " + error);
            });
    }, []);

    const CreateCard = (props) => {
        var licenseItems = [
            <CreateValueRow name="Name" value={props.license.name} />,
            <CreateValueRow name="Number of users" value={props.license.allowedUsers} />,
            <CreateValueRow name="License key" value={props.license.id} />,
            props.license.trial ? <CreateValueRow name="Expiration date" value={props.license.expirationDate ? DateFormat(props.license.expirationDate) : "-"} /> : null,
            <CreateValueRow name="License type" value={props.license.trial ? "Trial" : "Standard"} />,
            <CreateValueRow name="Support e-mail" value={props.license.supportMail ?? "-"} />,
        ];

        let button = licenseExport ? <DownloadButton base64contents={licenseExport.base64} fileName="assistlicense.key" text="Get license file" auth="true" /> : null;

        return <CardWithList headLine="License information" items={licenseItems} button={button} />;
    };

    const CreateValueRow = (props) => {
        return (
            <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-3 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-gray-500">{props.name}</dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{props.value}</dd>
                </div>
            </dl>
        );
    };
    return (
        <Fragment>
            <PageHeader headLine={"Assist" + (license.licenseType == 100 ? " Pro" : "") + " license"}></PageHeader>
            {error ? (
                // Error
                <NotificationBar color="red" text={error} icon={ExclamationCircleIcon} />
            ) : license ? (
                // Actual content
                <CreateCard license={license} />
            ) : (
                // Loading
                <CardWithList headLine="License information" />
            )}
        </Fragment>
    );
}
