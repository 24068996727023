import BlobDownloader from "./BlobDownloader";

export default function DownloadFile(fileUrl, fileName) {
    return fetch(fileUrl, {
        method: "GET",
        mode: "cors",
        redirect: "follow",
        responseType: "arraybuffer",
        headers: new Headers({
            "Content-Type": "arraybuffer",
        }),
    })
        .then((response) => response.arrayBuffer())
        .then((blob) => {
            BlobDownloader(blob, fileName);
        });
}