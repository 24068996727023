import { Transition } from "@headlessui/react";

export default function Card(props) {



    const CardBody =()=>  <div className="mb-5">
        <div className="bg-white ml-1 shadow  sm:rounded-md h-max  rounded-t">
            <div className="px-4 py-5 sm:px-6 bg-gray-100 border-b rounded-t flex justify-between items-center">
                <h3 className="text-lg leading-6 font-medium text-gray-900">{props.headLine}</h3>
                <div>{props.button}</div>
            </div>
            <div>
                {props.content}
            </div>
        </div>
    </div>
    
    if (props.content !== undefined && props.content !== null)
        return (            
                props.disableTransition ?<CardBody/>:
                    <Transition
                        appear={true}
                        show={true}
                        enter="transition-opacity duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-150"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <CardBody/>
                    </Transition>
             
    )
    else
        // Loading placeholder
        return (
            <div className="mb-5">
                <div className="bg-white ml-1 shadow sm:rounded-md h-max  rounded-t">
                    <div className="px-4 py-5 sm:px-6 bg-gray-100 border-b rounded-t ">
                        <div className="min-w-0 animate-pulse flex-1">
                            <div>
                                <div>
                                    <span className="text-sm font-medium text-gray-900 truncate mr-2">
                                        <div className="h-2 bg-gray-400 rounded mb-2"></div>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="min-w-full">
                        <div className="block hover:bg-gray-50">
                            <div className="flex items-center px-4 py-4 sm:px-6">
                                <div className="min-w-0 flex-1 flex items-center">
                                    <div className="min-w-0 animate-pulse flex-1">
                                        <div>
                                            <div>
                                                <span className="text-sm font-medium text-gray-900 truncate mr-2">
                                                    <div className="h-2 bg-gray-400 rounded mb-2"></div>
                                                </span>
                                                <div className="h-2 bg-gray-400 rounded mb-6"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
}