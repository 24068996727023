import ProductCard from "../Products/ProductCard";

export default function ProductList(props) {
    return (
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
            {props.products.map((product) => (
                <ProductCard key={product.title} product={product} />
            ))}
        </div>
    );
}