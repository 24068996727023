import { useEffect, useState } from "react";
import { RefreshIcon, TrashIcon } from "@heroicons/react/solid";
import { GetClientId } from "../../../ApiClients/DynamicTemplateClient";

export default function ConsentGrantedList({ grantedTenants, consentAction, deleteAction, }) {

    const [ClientIds, setClientIds] = useState(null);

    useEffect(() => {
        GetClientId().then((x) => {
            setClientIds(x);
        })
    }, [grantedTenants]);



    if (ClientIds != null && grantedTenants && grantedTenants.length > 0) {
        return (
            <div className="ml-1 mt-4">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-xl font-semibold text-gray-900">
                            Consent granted
                        </h1>
                        <p className="mt-2 text-sm text-gray-700">
                            This list shows the tenants where consent has been granted
                        </p>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
                </div>
                <div className="mt-8 flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                            >
                                                Name
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            >
                                                Id
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            >
                                                Consent type
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            >
                                                Renew
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                            >
                                                Remove
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        {grantedTenants.map((grant) => (
                                            <tr key={grant.tenantId}>
                                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                                    {grant.name}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {grant.tenantId}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                    {grant.clientId == ClientIds.allUsersClientId ? "All user profiles" : "Current user profile"}
                                                </td>
                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4  text-sm font-medium sm:pr-6">

                                                    <a
                                                        href="#"
                                                        className="text-dania-600 hover:text-dania-900 "
                                                        onClick={() => consentAction()}
                                                    >
                                                        <RefreshIcon alt="Renew" className="ml-3 h-4 w-4" />
                                                    </a>

                                                </td>
                                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4  text-sm font-medium sm:pr-6">
                                                    <a
                                                        href="#"
                                                        className="text-dania-600 hover:text-dania-900 "
                                                        onClick={() => deleteAction(grant.tenantId)}
                                                    >
                                                        <TrashIcon alt="Renew" className="ml-5 h-4 w-4" />
                                                    </a>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
}
