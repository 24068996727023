import React, { useEffect } from "react";
import TextBox from "../Input/TextBox";
import SimpleCombobox from "../Input/SimpleCombobox";

export default function General({ signature, setSignature, templates, changeTemplateAction, azureGroups }) {
	useEffect(() => {}, [signature, azureGroups]);

	function ChangeTemplate(index) {
		changeTemplateAction(index);
	}

	function onChangeValue(event) {
		setSignature((prevState) => ({ ...prevState, signatureType: parseInt(event.target.value) }));
	}

	function updateActiveDirectoryGroupText(event) {
		setSignature((prevState) => ({ ...prevState, limitToGroup: event.target.value }));
	}

	function updateNameText(event) {
		setSignature((prevState) => ({ ...prevState, name: event.target.value }));
	}

	function updateDescriptionText(event) {
		setSignature((prevState) => ({ ...prevState, description: event.target.value }));
	}

	return (
		<div className="flex flex-col">
			<div className="pb-3 pt-3">
				<div className="text-gray-900 text-lg font-semibold">General settings</div>
			</div>
			<div className="text-gray-500 mb-4 flex-1">
				<span className="text-sm">Assign the signature to an Active Directory group and give it a name and description. Next, select a template.</span>
			</div>

			{azureGroups && azureGroups.length > 0 ? (
				<SimpleCombobox
					className="mb-4"
					menuItems={azureGroups}
					label="Active Directory Group"
					fieldName="ActiveDirectoryGroupText"
					defaultValue={signature.limitToGroup}
					onValueChange={updateActiveDirectoryGroupText}
					placeholder="Leave blank for everyone"
				/>
			) : (
				<TextBox mb="4" label="Active Directory Group" fieldName="ActiveDirectoryGroupText" defaultValue={signature.limitToGroup} onChange={updateActiveDirectoryGroupText} placeholder="Leave blank for everyone" />
			)}

			<TextBox mb="4" label="Name" fieldName="NameText" defaultValue={signature.name} onChange={updateNameText} placeholder="Required" />
			<TextBox mb="4" label="Description" multiLine={true} fieldName="DescriptionText" defaultValue={signature.description} onChange={updateDescriptionText} />
			<div className="mt-4">Use signature for</div>
			<div className="flex" onChange={onChangeValue}>
				{/*Standard*/}
				<div className="form-check form-check-inline flex-1">
					<input
						className={
							"form-check-input form-check-input appearance-none rounded-full h-4 w-4 mt-1 mr-2 border border-dania-300 bg-white focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer focus:ring-dania-500" +
							(signature.signatureType === 0 ? " text-dania-500 border-dania-500" : "")
						}
						type="radio"
						name="inlineRadioOptions"
						id="inlineRadio10"
						value="0"
						checked={signature.signatureType === 0}
						onChange={() => {}}
					/>
					<label className="form-check-label inline-block text-gray-500 text-sm cursor-pointer" htmlFor="inlineRadio10">
						Standard
					</label>
				</div>

				{/*Replies*/}
				<div className="form-check form-check-inline flex-1">
					<input
						className={
							"form-check-input form-check-input appearance-none rounded-full h-4 w-4 mt-1 mr-2 ml-2 border border-dania-300 bg-white focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer focus:ring-dania-500" +
							(signature.signatureType === 1 ? " text-dania-500 border-dania-500" : "")
						}
						type="radio"
						name="inlineRadioOptions"
						id="inlineRadio20"
						value="1"
						checked={signature.signatureType === 1}
						onChange={() => {}}
					/>
					<label className="form-check-label inline-block text-gray-500 text-sm cursor-pointer" htmlFor="inlineRadio20">
						Replies
					</label>
				</div>

				{/*Internal*/}
				<div className="form-check form-check-inline flex-1">
					<input
						className={
							"form-check-input form-check-input appearance-none rounded-full h-4 w-4 mt-1 mr-2 ml-2 border border-dania-300 bg-white focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left cursor-pointer focus:ring-dania-500" +
							(signature.signatureType === 2 ? " text-dania-500 border-dania-500" : "")
						}
						type="radio"
						name="inlineRadioOptions"
						id="inlineRadio30"
						value="2"
						checked={signature.signatureType === 2}
						onChange={() => {}}
					/>
					<label className="form-check-label inline-block text-gray-500 text-sm cursor-pointer" htmlFor="inlineRadio30">
						Internal
					</label>
				</div>
			</div>

			<div className="pb-3 pt-3">
				<div className="text-gray-900 text-lg font-semibold">Select a template</div>
			</div>

			{templates.map((x) => {
				return (
					<div key={x.id}>
						<p className="text-sm pb-3">{x.name}</p>
						<img
							src={"data:image/svg+xml;base64," + (signature.markUp.basedOnPredefinedId === x.id ? x.previewSelectedBase64svgImage : x.previewBase64svgImage)}
							onClick={() => {
								ChangeTemplate(x.id);
							}}
							className="cursor-pointer mb-5"
							alt=""
						/>
					</div>
				);
			})}
		</div>
	);
}
