import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/outline";
import TextBox from "../../Input/TextBox";
import { Droppable, Draggable } from "react-beautiful-dnd";

export default function SoMeLinkBox({ onChange, index, data, onActiveClick, enableDragAndDrop }) {

    const getItemStyle = (isDragging, draggableStyle) => ({
        // Some basic styles to make the items look a bit nicer
        userSelect: "none",
        // Change background colour if dragging
        border: isDragging ? '2px dashed gray' : '',
        padding: '5px',

        // Styles we need to apply on draggables
        ...draggableStyle
    });

    return (
        <Droppable droppableId={"soMeDropArea-" + index} isDropDisabled={true}>
            {(provided, snapshot) => (
                <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                >
                    <div key={"droppable-" + index}>
                        <Draggable key={"soMeDrag-" + index} draggableId={"soMeDrag-" + index} index={index}>
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                    )}
                                >
                                    <div className="mt-4">
                                        <div className="block text-sm font-medium text-gray-700 flex">
                                            {data.description}
                                            {enableDragAndDrop ?
                                                <div className="flex-1 flex">
                                                    <div className="flex-1"></div>
                                                    <div className="flex-none" {...provided.dragHandleProps}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="gray" strokeWidth="2">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M 12 5 v 0.01 M 12 12 v 0.01 M 12 19 v 0.01 M 12 6 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z m 0 7 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z m 0 7 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z M 12 5 v 0.01 M 12 12 v 0.01 M 12 19 v 0.01 M 21 6 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z m 0 7 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z m 0 7 a 1 1 0 1 1 0 -2 a 1 1 0 0 1 0 2 z" />
                                                        </svg>
                                                    </div>
                                                </div>
                                                : null}
                                        </div>
                                        <div className="flex relative">
                                            <div className="flex-auto relative">
                                                {data.deactivated ? <div className="w-full min-h-full absolute -left-1 z-50 rounded-md p-2 cursor-not-allowed"></div> : null}
                                                <div className="m-3 ml-0 mb-1">
                                                    <div>
                                                        <TextBox
                                                            readOnly={data.deactivated}
                                                            defaultValue={data.linkUri}
                                                            onChange={(e) => {
                                                                data.linkUri = e.target.value;
                                                                onChange(index, data);
                                                            }}
                                                        ></TextBox>
                                                    </div>
                                                </div>

                                                <div className="hidden" aria-hidden="true">
                                                    <div className="relative flex items-start" >
                                                        <div className="flex items-center h-5">
                                                            <input
                                                                disabled={data.deactivated}
                                                                id={"SoMeLinkBox" + index}
                                                                aria-describedby="allowOverwrite-description"
                                                                name="allowOverwrite"
                                                                type="checkbox"
                                                                defaultChecked={data.allowOverwrite}
                                                                className={"focus:ring-dania-500 h-4 w-4 border-gray-300 rounded cursor-pointer " + (data.deactivated ? "text-gray-300" : "text-dania-500")}
                                                                onChange={(e) => {
                                                                    data.allowOverwrite = e.target.checked;
                                                                    onChange(index, data);
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="ml-3 text-sm">
                                                            <label htmlFor={"SoMeLinkBox" + index} className="font-sm text-gray-500 cursor-pointer">
                                                                Allow users to manually edit
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div
                                                className="flex-none w-10 h-10 pt-4 cursor-pointer"
                                                title={data.deactivated ? "Activate element" : "Disable element"}
                                                onClick={() => {
                                                    data.deactivated = !data.deactivated;
                                                    onChange(index, data);
                                                }}
                                            >
                                                {data.deactivated ? <PlusCircleIcon className="h-4 w-4 ml-4 text-gray-500" aria-hidden="true" /> : <MinusCircleIcon className="h-4 w-4 ml-4 text-gray-500" aria-hidden="true" />}
                                            </div>
                                        </div>
                                        <div></div>
                                    </div>
                                </div>)}
                        </Draggable>
                    </div>
                </div>)}
        </Droppable>
    );
}