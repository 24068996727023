import { GetInstallFiles } from "../../../ApiClients/OutlookSignatureCloudClient";
import DownloadList from "../../../Components/Lists/DownloadList";
import PageHeader from "../../../Components/PageHeader";
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import React from "react";
import NotificationBar from "../../../Components/NotificationBar";
import HelpNotificationBar from "../../../Components/HelpNotificationBar";
import ReleaseNotes from "./ReleaseNotes";
import { useState, useEffect } from "react";
import Help from "./Help";
import DateFormat from "../../../Helpers/DateFormater";

export default function InstallFiles(props) {
    const [data, setData] = useState([]);
    const [error, setError] = useState();

    useEffect(() => {
        const releaseNotes = (version) => {
            return (
                <div>
                    <span className="truncate">Read our</span>
                    <span className="text-dania-500 pl-1 pr-1 cursor-pointer" onClick={() => props.onNavigation(<ReleaseNotes version={version} />)}>
                        release notes
                    </span>
                    <span className="truncate">to see what´s new in this update</span>
                </div>
            );
        };
        const installFilesBadge = (x) => {
            if (x.latest) {
                return { color: "green", text: "Latest stable" };
            } else {
                return { color: "red", text: "Archived" };
            }
        };

        try {
            GetInstallFiles().then((response) => {
                if (response.error) {
                    setError(response.error.message);
                } else {
                    var temp = response.map((x) => {
                        // Resolve badge color and text
                        var badge = installFilesBadge(x);

                        // Map object
                        return {
                            name: x.name,
                            version: x.version,
                            releaseDate: DateFormat(x.date),
                            badgeText: badge.text,
                            badgeColor: badge.color,
                            base64content: x.content,
                            description: releaseNotes(x.version),
                            archived: !x.latest,
                            fileName: x.filename,
                        };
                    });

                    setData(temp);
                }
            });
        } catch {
            setError("Unable to fetch install files data");
        }
    }, [props]);

    return (
        <div className="w-full">
            {
                <div>
                    <PageHeader headLine="Download SignatureCloud"></PageHeader>
                    <div className="pt-1 pb-6">
                        <HelpNotificationBar onNavigation={() => props.onNavigation(<Help />)} />
                    </div>

                    {error ? (
                        <div className="pt-1 pb-6">
                            <NotificationBar color="red" text={error} icon={ExclamationCircleIcon} />
                        </div>
                    ) : (
                        <DownloadList applications={data} pageSize={5} headLine="Installation files" showMoreText="View older versions" />
                    )}
                </div>
            }
        </div>
    );
}
