export default function CountryDropdown(props) {

    return (
        <select
          required
          id={props.id}
          name={props.name}
          autoComplete={props.autoComplete}
          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-dania-500 focus:border-dania-500 sm:text-sm"
          defaultValue={props.defaultValue}
          onChange={(e) => {props.onChange(e)}}
          >
            <option value="">--- Select ---</option>
            
            <option>Austria</option>
            <option>Belgium</option>
            <option>Bulgaria</option>
            <option>Croatia</option>
            <option>Cyprus</option>
            <option>Czech Republic</option>
            <option>Denmark</option>
            <option>Estonia</option>
            <option>Faroe Islands</option>
            <option>Finland</option>
            <option>France</option>
            <option>Germany</option>
            <option>Greece</option>
            <option>Hungary</option>
            <option>Iceland</option>
            <option>Ireland</option>
            <option>Italy</option>
            <option>Latvia</option>
            <option>Liechtenstein</option>
            <option>Lithuania</option>
            <option>Luxembourg</option>
            <option>Malta</option>
            <option>Netherlands</option>
            <option>Norway</option>
            <option>Poland</option>
            <option>Portugal</option>
            <option>Romania</option>
            <option>Slovakia</option>
            <option>Slovenia</option>
            <option>Spain</option>
            <option>Sweden</option>
            <option>Switzerland</option>
            <option>United Kingdom</option>

        </select>
    );
}