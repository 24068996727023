/*  This example requires Tailwind CSS v2.0+ */
import { Transition } from "@headlessui/react";
import { ArrowSmRightIcon } from "@heroicons/react/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ActionGrid(props) {
  if (props.actions.length % 2 !== 0) {
    props.actions.push({});
  }

  return (
    <Transition
      appear={true}
      show={true}
      enter="transition-opacity duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-150"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="rounded-lg ml-1 bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px my-5">
        {props.actions.map((action, actionIdx) => (
          <div
            key={actionIdx}
            className={classNames(
              actionIdx === 0
                ? "rounded-tl-lg rounded-tr-lg sm:rounded-tr-none"
                : "",
              actionIdx === 1 ? "sm:rounded-tr-lg" : "",
              actionIdx === props.actions.length - 2 ? "sm:rounded-bl-lg" : "",
              !(actionIdx % 2) && actionIdx === props.actions.length - 1
                ? "rounded-bl-lg rounded-br-lg sm:rounded-bl-none hidden sm:block"
                : "",
              "relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-dania-500 flex flex-row"
            )}
          >
            <div>
              {action.icon ? (
                <span
                  className={classNames(
                    action.iconBackground,
                    action.iconForeground,
                    "rounded-lg inline-flex p-3 ring-4 ring-white"
                  )}
                >
                  <action.icon className="h-6 w-6" aria-hidden="true" />
                </span>
              ) : null}
            </div>
            <div className="ml-5">
              {action.title ? (
                <h3 className="text-lg font-medium">{action.title}</h3>
              ) : null}
              {action.text ? (
                <p className="mt-2 text-sm text-gray-500">{action.text}</p>
              ) : null}
              {action.onClick ? (
                <div className="flex flex-row items-center mt-2 hover:text-dania-700">
                  <button
                    className="text-dania-500 text-sm font-medium hover:text-dania-700 "
                    onClick={() => action.onClick()}
                  >
                    {action.linkText ? action.linkText : action.title}
                    <ArrowSmRightIcon className="inline w-5 h-5 ml-2" />
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        ))}
      </div>
    </Transition>
  );
}
