import { Transition } from "@headlessui/react";

export default function AlertBar(props) {

    // Default settings (props.type == "success")
    var settings = {
        icon: "M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z",
        bgcolor: "rounded-md bg-green-50 p-4",
        iconColor: "h-5 w-5 text-green-400",
        textColor: "text-sm font-medium text-green-800"
    }

    if (props.type === "error") {
        settings = {
            icon: "M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z",
            bgcolor: "rounded-md bg-red-50 p-4",
            iconColor: "h-5 w-5 text-red-400",
            textColor: "text-sm font-medium text-red-800"
        }
    }

    if (props.type === "warning") {
        settings = {
            icon: "M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z",
            bgcolor: "rounded-md bg-yellow-50 p-4",
            iconColor: "h-5 w-5 text-yellow-400",
            textColor: "text-sm font-medium text-yellow-800"
        }
    }

    if (props.type === "information") {
        settings = {
            icon: "M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z",
            bgcolor: "rounded-md bg-blue-50 p-4",
            iconColor: "h-5 w-5 text-blue-400",
            textColor: "text-sm font-medium text-blue-700"
        }
    }

    if (props.type === "dania") {
        settings = {
            icon: "M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z",
            bgcolor: "rounded-md bg-dania-100 p-4",
            iconColor: "h-5 w-5 text-dania-400",
            textColor: "text-sm font-medium text-dania-700"
        }
    }

    const AlertBody = () => <div className={settings.bgcolor}>
        <div className="flex">
            <div className="flex-shrink-0">
                {/* Heroicon name: solid/check-circle */}
                <svg className={settings.iconColor} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d={settings.icon} clipRule="evenodd" />
                </svg>
            </div>
            <div className="ml-3">
                <p className={settings.textColor}>
                    {props.header}
                </p>
            </div>
        </div>
    </div>

    return (
        props.disableTransition ? <AlertBody /> :
            <Transition
                appear={true}
                show={true}
                enter="transition-opacity duration-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <AlertBody />
            </Transition>
    );
}