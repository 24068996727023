import { RadioGroup } from "@headlessui/react";
import Chip from "../Components/Chip"

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

export default function RadioButtons(props) {
	let settings = props.settings;

	var selected = settings.find((x) => x.selected);
	const onChange = (e) => {
		props.onChange(e);
	};

	return (
		<div className="relative">
			<div className={`${props.disabled ? "" : "hidden"} bg-gray-100 opacity-70 z-50 absolute min-w-full  h-full rounded-md`}></div>
			<RadioGroup disabled={props.disabled} value={selected} onChange={(e) => onChange(e)}>
				<RadioGroup.Label className="sr-only">Privacy setting</RadioGroup.Label>
				<div className="bg-white rounded-md -space-y-px">
					{settings.map((setting, settingIdx) => (
						<RadioGroup.Option
							key={setting.name}
							value={setting}
							className={({ checked }) =>
								classNames(
									settingIdx === 0 ? "rounded-tl-md rounded-tr-md" : "",
									settingIdx === settings.length - 1 ? "rounded-bl-md rounded-br-md" : "",
									checked ? "bg-dania-50 border-dania-200 z-10" : "border-gray-200",
									"relative border p-4 flex cursor-pointer focus:outline-none"
								)
							}
						>
							{({ active, checked }) => (
								<>
									<span
										className={classNames(
											checked ? "bg-dania-600 border-transparent" : "bg-white border-gray-300",
											active ? "ring-2 ring-offset-2 ring-dania-500" : "",
											"h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center"
										)}
										aria-hidden="true"
									>
										<span className="rounded-full bg-white w-1.5 h-1.5" />
									</span>
									<div className="ml-3 flex flex-col">
										<RadioGroup.Label as="span" className={classNames(checked ? "text-dania-900" : "text-gray-900", "block text-sm font-medium")}>
											{setting.name} {setting.proFeature ? <Chip text="Pro" color="dania" /> : null}
										</RadioGroup.Label>
										<RadioGroup.Description as="span" className={classNames(checked ? "text-dania-700" : "text-gray-500", "block text-sm")}>
											{setting.description}
										</RadioGroup.Description>
									</div>
								</>
							)}
						</RadioGroup.Option>
					))}
				</div>
			</RadioGroup>
		</div>
	);
}
