import { Transition } from "@headlessui/react";
import { HashtagIcon } from '@heroicons/react/solid';
import { useState } from 'react';
import { ChromePicker } from 'react-color';

export default function ColorPicker(props) {
    const [show, setShow] = useState(false)
    const [color, setColor] = useState(props.color);
    const GetStyle = () => {
        return props.readOnly
            ? "mt-1 focus:ring-dania-500 focus:border-dania-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md bg-gray-200"
            : "mt-1 pl-10 focus:ring-dania-500 focus:border-dania-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md inline-block text-gray-500 text-sm";
    };
    const handleChangeComplete = (color) => {
        if (color.hex) {
            props.onChange(color.hex)
            setColor(color.hex);
        } else {
            props.onChange("#" + color)
            setColor("#" + color);
        }
    };

    const handleChange = (color) => {
        setColor(color.hex);
    };

    function onBlur(color) {
        props.onChange("#" + color.padEnd(6, "0"));
        setColor("#" + color.padEnd(6, "0"));
    }

    return (
        <div className="mt-4" onMouseLeave={() => setShow(false)}>
            <label htmlFor={props.fieldName} className="block text-sm font-medium text-gray-700">
                {props.label}
            </label>
            <div className="flex" >
                <div className="flex-auto" >
                    <div>
                        <div className="mt-1 relative rounded-md shadow-sm" onFocus={() => setShow(true)}>
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center cursor-pointer">
                                <HashtagIcon className="h-3 w-3 text-gray-500" aria-hidden="true" />
                            </div>
                            <input
                                readOnly={props.readOnly}
                                required
                                type="text"
                                name={props.fieldName}
                                id={props.fieldName}
                                autoComplete="off"
                                className={GetStyle()}
                                value={props.color ? props.color.replace('#', '') : ""}
                                onChange={(e) => {
                                    if (props.onChange) {
                                        handleChangeComplete(e.target.value);
                                    }
                                }}
                                onBlur={(e) => {
                                    if (props.onChange) {
                                        onBlur(e.target.value);
                                    }
                                }}
                            />
                        </div>
                    </div>
                    {
                        <Transition
                            appear={true}
                            show={show}
                            enter="transition-opacity duration-500"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="mt-2 mb-3">
                                <ChromePicker disableAlpha={true} color={color.padEnd(7, "0")} onChangeComplete={handleChangeComplete} onChange={handleChange} />
                            </div></Transition>
                    }
                </div>


                <div className="flex-none w-10 h-10 pt-4">
                    <div style={{ backgroundColor: props.color }} className="ml-4 w-6 h-6 rounded-full inline-block"></div>
                </div>
            </div>

        </div >
    );
}
