import CheckBox from "../../Input/CheckBox";
import { LockClosedIcon } from "@heroicons/react/outline";
import { useState } from "react";

export default function SelectionBox({ field, onSelectionChange, limitList }) {
    const [selections, setSelections] = useState(limitList);
    const [inputValue, setInputValue] = useState("");

    const removeSelection = (e, index) => {
        var temp = [...selections];
        temp.splice(index, 1);
        setSelections(temp);
        if (onSelectionChange)
            onSelectionChange(temp);
    };

    const addSelection = (inputValue) => {
        if (inputValue) {
            var temp = [...selections];
            temp.push(inputValue);
            setSelections(temp);
            setInputValue("");
            if (onSelectionChange)
                onSelectionChange(temp);
        }
    };
    return (
        <div {...field}>
            <div className="text-sm font-medium text-gray-500 mt-3"> {field.name} </div>
            <div className="mt-1 ml-1 mr-1 flex rounded-md shadow-sm">
                <div className="relative flex items-stretch flex-grow focus-within:z-10">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <LockClosedIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                    <input
                        value={inputValue}
                        type="text"
                        onChange={(e) => setInputValue(e.target.value)}
                        autoComplete="off"
                        name="limitValue"
                        id="limitValue"
                        className="focus:ring-dania-500 focus:border-dania-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300"
                        placeholder="Limit value"
                    />
                </div>
                <button
                    type="button"
                    onClick={() => {
                        addSelection(inputValue);
                    }}
                    className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-dania-500 bg-dania-100 hover:bg-dania-200 focus:outline-none focus:ring-1 focus:ring-dania-500 focus:border-dania-500"
                >
                    <span>Add</span>
                </button>
            </div>
            <div className="flex flex-wrap mt-2">
                {selections.map((x, key) => (
                    <div className="mr-2" key={key}>
                        <CheckBox key={key} label={x} checked={true} onChange={(e) => removeSelection(e, key)}></CheckBox>
                    </div>
                ))}
            </div>
        </div>
    );
}