export default function Chip({ text, color, marginRightTailWind }) {

    let tailwindClassMarginRight = marginRightTailWind ? marginRightTailWind : "";

    const classes = "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-" + color + "-100 text-" + color + "-500 " + tailwindClassMarginRight;
    return (
        <span className={classes} >
            {text}
        </span >
    );
}