import PageHeader from "../../../Components/PageHeader";
import SignatureCard from "../../../Components/SignatureCard";
import SignatureCardLoading from "../../../Components/SignatureCardLoading";
import { PlusIcon } from "@heroicons/react/outline";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { DuplicateIcon } from "@heroicons/react/outline";
import { Transition } from "@headlessui/react";
import React, { useEffect, useState, Fragment } from "react";
import { DeleteSignature, GetSignatures, ChangeSignatureStatus, GetLicense, AddSignature } from "../../../ApiClients/OutlookSignatureCloudClient";
import ModalDialog from "../../../Components/ModalDialog";
import TextBox from "../../../Components/Input/TextBox";
import SignatureBuilderModal from "../../../Components/SignatureBuilder/SignatureBuilderModal";

export default function CreateAndManage() {
	const [dialog, setDialog] = useState({ show: false });
	const [builder, setBuilder] = useState({
		show: false,
		id: "",
	});
	const [notificationBar, setNotificationBar] = useState({ text: "", color: "", show: false, icon: CheckCircleIcon, placement: "" });

	const [loading, setloading] = useState(true);
	const [signatures, setSignatures] = useState([]);
	const [isProductOwner, setisProductOwner] = useState(true);
	const [isAdConnected, setIsAdConnected] = useState(false);

	useEffect(() => {
		GetSignatures()
			.then((data) => {
				setSignatures(data);
				setloading(false);
			})
			.catch((error) => {
				setloading(false);
			});

		GetLicense().then((license) => setIsAdConnected(license.adConnected));
	}, []);

	function executeDeleteSignature(id) {
		closeDialog();
		DeleteSignature(id).then(() => {
			GetSignatures()
				.then((data) => {
					setSignatures(data);
				})
				.catch((error) => {});
			notficationUpdate("Success! The signature has been deleted", "green", CheckCircleIcon, "bottom");
		});
	}

	function notficationUpdate(text, color, icon, placement) {
		setNotificationBar({ text: text, color: color, show: true, icon: icon, placement: placement });
		new Promise((x) => setTimeout(x, 5000)).then(() => setNotificationBar((prevState) => ({ ...prevState, show: false })));
	}
	function replaceColor(color, classes) {
		return color ? classes.replaceAll("dania", color) : classes;
	}

	function toggleSignaturStatus(id) {
		ChangeSignatureStatus(id).then((x) => {
			GetSignatures()
				.then((data) => {
					setSignatures(data);
				})
				.catch((error) => {});
		});
	}

	function showCreateSignature(id) {
		setBuilder({ show: true, id: id });
	}

	function showCopyDialog(id) {
		var copy = { ...signatures.filter((x) => x.id == id)[0] };
		var name = copy.name;
		copy.id = "00000000-0000-0000-0000-000000000000";
		copy.name = "";
		setDialog({
			iconColor: "dania",
			icon: DuplicateIcon,
			title: "Duplicate " + name,
			text: "",
			actionButtonAction: () => {
				if (copy.name.trim().length === 0) {
					copy.name = "Copy of " + name;
				}
				doCopySignature(copy);
			},
			actionButtonColor: "dania",
			actionButtonText: "Create",
			show: true,
			children: (
				<div className="bg-white px-4 sm:px-6 sm:pb-6">
					<div className="sm:flex sm:items-start">
						<div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10"></div>
						<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-9/12">
							<TextBox
								label="Name"
								fieldName="DuplicateName"
								onChange={(x) => {
									copy.name = x.target.value;
								}}
								readOnly={false}
							/>
						</div>
					</div>
				</div>
			),
		});
	}

	function doCopySignature(copy) {
		AddSignature(copy).then((x) => {
			closeDialog();
			GetSignatures()
				.then((data) => {
					setSignatures(data);
				})
				.catch((error) => {});
		});
	}

	function showDeleteSignatureDialog(id) {
		setDialog({
			iconColor: "red",
			title: "Delete signature",
			text: "Are you sure you want to delete the signature? The Active Directoy Group that the signature was assigned to, will now default to your standard signature.",
			actionButtonAction: () => {
				executeDeleteSignature(id);
			},
			actionButtonColor: "red",
			actionButtonText: "Delete signature",
			show: true,
		});
	}

	function closeDialog() {
		setDialog((prevState) => ({ ...prevState, show: false, icon: null, children: null }));
	}

	function closeBuilder() {
		setBuilder((prevState) => ({ ...prevState, show: false, id: "" }));
	}

	function SuccessCreateOrEdit() {
		GetSignatures()
			.then((data) => {
				setSignatures(data);
			})
			.catch((error) => {});
		notficationUpdate("Success! You have created and activated a new signature", "green", CheckCircleIcon, "top");

		//If in create mode close the builder else keep it open
		if (builder.id === "") {
			closeBuilder();
		}
	}

	function signaturesLoadingPlaceholder() {
		return (
			<Fragment>
				<div className="pb-3 mb-5">
					<div className="animate-pulse w-64 h-2 bg-gray-400 rounded" />
				</div>

				<div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
					<SignatureCardLoading />
				</div>
			</Fragment>
		);
	}

	return (
		<div className="w-full">
			<Transition appear={true} show={true} enter="transition-opacity duration-500" enterFrom="opacity-0" enterTo="opacity-100" leave="transition-opacity duration-150" leaveFrom="opacity-100" leaveTo="opacity-0">
				{builder.show ? <SignatureBuilderModal show={builder.show} closeAction={closeBuilder} successAction={SuccessCreateOrEdit} id={builder.id} isAdConnected={isAdConnected} allSignatures={signatures} /> : null}
				<ModalDialog
					icon={dialog.icon}
					children={dialog.children}
					iconColor={dialog.iconColor}
					title={dialog.title}
					text={dialog.text}
					actionButtonAction={dialog.actionButtonAction}
					actionButtonColor={dialog.actionButtonColor}
					actionButtonText={dialog.actionButtonText}
					show={dialog.show}
					close={() => {
						closeDialog();
					}}
				/>
				<PageHeader headLine="Create and manage email signatures"></PageHeader>
				<div>
					<Transition
						show={notificationBar.show && notificationBar.placement === "top"}
						as={Fragment}
						enter="transition ease-out duration-500"
						enterFrom="transform opacity-0 scale-100"
						enterTo="transform opacity-100 scale-100"
						leave="transition ease-in duration-500"
						leaveFrom="transform opacity-100 scale-100"
						leaveTo="transform opacity-0 scale-100"
					>
						<div className={replaceColor(notificationBar.color, "rounded-md bg-dania-50 mb-6 p-4 w-full")}>
							<div className="flex">
								<div className="flex-shrink-0">
									<notificationBar.icon className={replaceColor(notificationBar.color, "h-5 w-5 text-dania-400")} aria-hidden="true" />
								</div>
								<div className="ml-3 flex-1 md:flex md:justify-between">
									<p className={replaceColor(notificationBar.color, "text-sm font-medium text-dania-700")}>{notificationBar.text}</p>
								</div>
							</div>
						</div>
					</Transition>
				</div>
				<div className="pb-3 mb-5">
					<div className="text-gray-900 text-lg font-semibold">Create new email signatures</div>
				</div>
				<div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 border-b border-gray-200 mb-5">
					<div className="mb-5 mt-5">
						<div
							className="border-dashed border-gray-400 border-2 text-center py-10 px-5 rounded-lg cursor-pointer"
							onClick={() => {
								showCreateSignature("");
							}}
						>
							<div className="rounded bg-dania-100 text-dania-700 w-10 h-10 text-center align-middle m-auto">
								<PlusIcon className="m-auto text-dania-500 w-8 pt-1" />
							</div>
							<div className="mb-5 mt-5">
								<div className="text-gray-900 text-lg font-semibold">Create new signature</div>
							</div>
							<div className="text-gray-500">
								<span className="text-base">Start from scratch or use one of our premade templates.</span>
							</div>
						</div>
					</div>
				</div>

				{loading ? (
					signaturesLoadingPlaceholder()
				) : (
					<Fragment>
						<Transition appear={true} show={signatures.length > 0} enter="transition-opacity duration-500" enterFrom="opacity-0" enterTo="opacity-100" leave="transition-opacity duration-150" leaveFrom="opacity-100" leaveTo="opacity-0">
							<div className={"pb-3 mb-5" + (signatures.length > 0 ? "" : " hidden")}>
								<div className="text-gray-900 text-lg font-semibold">Manage existing signatures</div>
							</div>

							{/* Bottom notification bar */}
							<div>
								<Transition
									show={notificationBar.show && notificationBar.placement === "bottom"}
									as={Fragment}
									enter="transition ease-out duration-500"
									enterFrom="transform opacity-0 scale-100"
									enterTo="transform opacity-100 scale-100"
									leave="transition ease-in duration-500"
									leaveFrom="transform opacity-100 scale-100"
									leaveTo="transform opacity-0 scale-100"
								>
									<div className={replaceColor(notificationBar.color, "rounded-md bg-dania-50 mb-6 p-4 w-full")}>
										<div className="flex">
											<div className="flex-shrink-0">
												<notificationBar.icon className={replaceColor(notificationBar.color, "h-5 w-5 text-dania-400")} aria-hidden="true" />
											</div>
											<div className="ml-3 flex-1 md:flex md:justify-between">
												<p className={replaceColor(notificationBar.color, "text-sm font-medium text-dania-700")}>{notificationBar.text}</p>
											</div>
										</div>
									</div>
								</Transition>
							</div>
							{/* Aktive */}
							<div className={"grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4" + (signatures.filter((x) => !x.deactivated).length > 0 ? " mb-5" : "")}>
								{signatures
									.filter((x) => !x.deactivated)
									.map((x) => {
										return (
											<SignatureCard
												duplicateAction={() => {
													showCopyDialog(x.id);
												}}
												active={!x.deactivated}
												showButtons={isProductOwner}
												department={x.limitToGroup}
												date={x.date}
												description={x.description}
												signatureType={x.signatureType}
												title={x.name}
												deleteAction={() => {
													showDeleteSignatureDialog(x.id);
												}}
												id={x.id}
												key={x.id}
												editAction={() => {
													showCreateSignature(x.id);
												}}
												toggleAction={() => {
													toggleSignaturStatus(x.id);
												}}
											/>
										);
									})}
							</div>
							{/* Inaktive */}
							<div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
								{signatures
									.filter((x) => x.deactivated)
									.map((x) => {
										return (
											<SignatureCard
												duplicateAction={() => {
													showCopyDialog(x.id);
												}}
												active={!x.deactivated}
												showButtons={isProductOwner}
												department={x.limitToGroup}
												date={x.date}
												description={x.description}
												signatureType={x.signatureType}
												title={x.name}
												deleteAction={() => {
													showDeleteSignatureDialog(x.id);
												}}
												id={x.id}
												key={x.id}
												editAction={() => {
													showCreateSignature(x.id);
												}}
												toggleAction={() => {
													toggleSignaturStatus(x.id);
												}}
											/>
										);
									})}
							</div>
						</Transition>
					</Fragment>
				)}
			</Transition>
		</div>
	);
}
