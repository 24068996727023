/* This example requires Tailwind CSS v2.0+ */
import { CheckCircleIcon } from '@heroicons/react/solid'

export default function AccountInfoMenu(props) {

  const isActive = (step) => {
    if (step.contentComponent?.type === props.activeComponent.type)
    {
      currentFound = true;
      return true;
    }
	};

  var currentFound = false;
  
  function CurrentIcon() {
    return (
      <span className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center" aria-hidden="true">
        <span className="absolute h-4 w-4 rounded-full bg-dania-200" />
        <span className="relative block w-2 h-2 bg-dania-600 rounded-full" />
      </span>
    );
  }

  function CompleteIcon() {
    return (
      <span className="flex-shrink-0 relative h-5 w-5 flex items-center justify-center">
        <CheckCircleIcon className="h-full w-full text-dania-600" aria-hidden="true" />
      </span>
    );
  }

  function UpcomingIcon() {
    return (
      <div className="flex-shrink-0 h-5 w-5 relative flex items-center justify-center" aria-hidden="true">
        <div className="h-2 w-2 bg-gray-300 rounded-full" />
      </div>
    );
  }

  return (
    <div className="py-3 px-4 sm:px-6">
      <nav className="flex-auto my-2" aria-label="Progress">
        <ol className="sm:space-x-6 flex flex-row sm:justify-between">

          {props.steps.map((step) => (
            <li key={step.name}>

              {isActive(step) ? (
                <div className="flex items-start" aria-current="step">
                  <CurrentIcon />
                  <span className="ml-3 text-sm font-normal text-dania-600">{step.name}</span>
                </div>
              ) : !currentFound ? (
                <div className="hidden sm:block">
                  <span className="flex items-start">
                    <CompleteIcon />
                    <span className="ml-3 text-sm font-normal text-gray-500">
                      {step.name}
                    </span>
                  </span>
                </div>
              ) : (
                <div>
                  <div className="hidden sm:flex items-start">
                    <UpcomingIcon />
                    <p className="ml-3 text-sm font-normal text-gray-500">
                      {step.name}
                    </p>
                  </div>
                </div>
              )}
            </li>
          ))}

        </ol>
      </nav>
    </div>
  )
}