import { ArrowRightIcon } from "@heroicons/react/outline";

import { Fragment, useState } from "react";
import Card from "../Card";

export default function ProductInformationList(props) {
  const [startIndex, setStartIndex] = useState(0);

  const nextSlice = () => {
    setStartIndex(startIndex + getPageSize());
  };

  const getPageSize = () => {
    if (props.pageSize) {
      return props.pageSize;
    } else {
      return 10;
    }
  };
  const showNextSliceButton = () => {
    try {
      return startIndex + getPageSize() < props.productInformation.length;
    } catch {
      return false;
    }
  };

  function Primary(props) {
    let productInformation = props.productInformation;
    return (
      <div className="block w-full">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="w-full flex-1 flex px-4 items-center">
            <div className="flex-1">
              <span className="text-sm mr-2">{productInformation.content}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="pb-8 w-full">
      {props.productInformation.length < 1 ? (
        <Fragment>
          <Card />
        </Fragment>
      ) : (
        props.productInformation.slice(0, startIndex + getPageSize()).map((product, index) => {
          return <Card key={product.groupHeadLine + index} headLine={product.groupHeadLine} content={props.productInformation !== null ? <Primary productInformation={product} /> : null} />;
        })
      )}

      {showNextSliceButton() ? (
        <div className="grid  place-content-center m-5">
          <button
            onClick={() => nextSlice()}
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-dania-500 bg-dania-100 hover:text-white  hover:bg-dania-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dania-500"
          >
            View older release notes
            <ArrowRightIcon className="h-4 w-4 ml-2" />
          </button>
        </div>
      ) : null}
    </div>
  );
}
