/* This example requires Tailwind CSS v2.0+ */
import InfoHeader from './InfoHeader';
import Button from '../Buttons/Button';
import ProductLinker from '../Products/ProductLinker';
import { Fragment } from 'react';

export default function TrialProduct({product, loading}) {

    function CreateButton({onClickAction}) {
        return (
            <Button
                        onNavigation={() => {
                            onClickAction();
                        }}
                        text="Start trial"
                        textColor="text-white"
                        bgColor="bg-dania-500"
                        hoverColor="bg-dania-600"
                    />
        )
    }

    function LoadingPlaceholder() {
        return (
            <div data-testid="rootContainer" className="flex flex-col place-content-between h-full animate-pulse">
                {/* Top content */}
                <div className="flex flex-col items-center">
                    {/* Icon circle (without icon) */}
                    <div data-testid="iconPlaceholder" className="mx-auto flex-shrink-0 flex items-center justify-center h-14 w-14 rounded-full bg-gray-100 sm:mx-0 sm:my-5" />
                    {/* Text placeholder blocks */}
                    <div className="text-center">
                        <div data-testid="textPlaceholder1" className="my-2 w-32 h-2 bg-gray-400 rounded"></div>
                        <div data-testid="textPlaceholder2" className="my-2 w-32 h-2 bg-gray-400 rounded"></div>
                    </div>
                </div>
            
                {/* Bottom content */}
                <div className="flex flex-col items-center sm:mt-3 sm:mb-5">
                    {/* Button placeholder block */}
                    <div data-testid="buttonPlaceholder" className="my-2 w-24 h-4 bg-gray-200 rounded"></div>
                </div>
            </div>
        )
    }

    return (
        <Fragment>
            {loading ?
                <LoadingPlaceholder />
            :
            <div data-testid="rootContainer" className="flex flex-col place-content-between h-full">
                {/* Top content */}
                <div className="flex flex-col items-center">
                    {/* Icon circle */}
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-14 w-14 rounded-full bg-dania-100 sm:mx-0 sm:my-5">
                        { product.teaserIcon ? <product.teaserIcon className="h-8 w-8 text-dania-500" aria-hidden="true" /> : null } 
                    </div>
                    {/* Text */}
                    <div className="text-center">
                        <InfoHeader header={product.teaserName + " trial"} text={product.teaserDescription} />
                    </div>
                </div>

                {/* Top content */}
                <div className="text-center sm:mt-3 sm:mb-5">
                    <ProductLinker product={product} Button={CreateButton} />
                </div>
            </div>
            }
        </Fragment>
    )
}

