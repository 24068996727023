import SubmenuLayout from "../../Components/SubmenuLayout";
import TeamInformation from "./Information";
import TeamMembers from "./TeamMembers";
import TeamBilling from "./Billing";
import { useState, useEffect } from "react";
import authService from "../../api-authorization/AuthorizeService";

export default function TeamMain() {
    const [isAccountOwner, setIsAccountOwner] = useState(false);

    const navigation = [
        {
            name: "Settings",
            items: [
                { name: "Information", contentComponent: <TeamInformation onNavigation={(e) => onNavigation(e)} /> },
                { name: "Team members", contentComponent: <TeamMembers onNavigation={(e) => onNavigation(e)} /> },
                { name: "Billing", contentComponent: <TeamBilling onNavigation={(e) => onNavigation(e)} /> },
            ],
        },
    ];

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        if (params.has("tab")) {
            var tab = params.get("tab");
            switch (tab) {
                case "infomation":
                    setActiveComponent(navigation[0].items[0].contentComponent);
                    break;
                case "members":
                    setActiveComponent(navigation[0].items[1].contentComponent);
                    break;
                case "billing":
                    setActiveComponent(navigation[0].items[2].contentComponent);
                    break;
                default:
                    setActiveComponent(navigation[0].items[0].contentComponent);
                    break;
            }
        }
        authService.isAccountOwner().then((result) => {
            setIsAccountOwner(result);
        });
    }, []);

    const [activeComponent, setActiveComponent] = useState(<TeamInformation onNavigation={(e) => onNavigation(e)} />);

    function onNavigation(activeComponent) {
        setActiveComponent(activeComponent);
    }

    return <SubmenuLayout navigation={navigation} contentComponent={activeComponent} product="Team" backUrl="/" onNavigation={(e) => onNavigation(e)} />;
}
